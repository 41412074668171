import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faWeightHanging } from "@fortawesome/free-solid-svg-icons";
import SearchBar from "../SearchBar/SearchBar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function MatchesDisplay(props) {
  const Refereeref = useRef();

  //   const [showScore, setShowScore] = useState([]);
  const [gameDetails, setgameDetails] = useState([]);
  const [matchdetails, setmatchdetails] = useState([]);
  const [referee, setReferee] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  console.log(new Date());
  const [copyDetails, setCopyDetails] = useState({});

  const [showMatcheMenu, setshowMatcheMenu] = useState(true);
  const [showMatcheMenuId, setshowMatcheMenuId] = useState(true);
  const matchMenuRef = useRef(null);

  function handleClickOutsideMatchMenu(event) {
    if (matchMenuRef.current && !matchMenuRef.current.contains(event.target)) {
      console.log("clicked outside the matchMenu.....!!!");
      handleMatchmenuToggle(true, "000");
    }
  }

  useEffect(() => {
    if (showMatcheMenu) {
      window.addEventListener("click", handleClickOutsideMatchMenu);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsideMatchMenu);
    };
  }, [showMatcheMenu]);

  const handleMatchmenuToggle = (value, Id) => {
    setshowMatcheMenu(!value);
    setshowMatcheMenuId(Id);
  };

  const copySubmitHandler = (e) => {
    e.preventDefault();
  };

  const copyChangeHandler = (e) => {
    // setuserData(userInfo)
    setCopyDetails({ ...copyDetails, [e.target.name]: e.target.value });
    // userData.length>0 && alert(userData[0].FirstName);
    // alert("length"+userData.length);
  };
  const dateChangeHandler = (e) => {
    // setuserData(userInfo)
    setmatchdetails({ ...matchdetails, [e.target.name]: e.target.value });
    // userData.length>0 && alert(userData[0].FirstName);
    // alert("length"+userData.length);
  };

  useEffect(() => console.log(copyDetails), [copyDetails]);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(gameDetails);
    console.log(matchdetails.Date);
    fetch(process.env.REACT_APP_API + "match/schedulematch", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        MatchID: matchdetails.MatchID,
        Date: startDate,
      }),
    })
      .then((res) => res.json())
      .then((result) => alert(result))
      .catch((err) => console.error(err))
      .then(setshowScheduleModal(false));

    // alert(result)
    // window.location.href="/login"
  };

  const copyHandler = (e) => {
    e.preventDefault();
    console.log(gameDetails);
    console.log(matchdetails);
    console.log(copyDetails);
    console.log({
      ...copyDetails,
      NoOfSets: matchdetails.NoOfSets,
      GameType: matchdetails.GameType,///////////// winpoint and golden points are not from matches table, create an api including the goldenpoint and winpoint then use it in "getgameforcopy" and "getgameforedit"
      Winpoint: matchdetails.Winpoint,/////////////////winpoint and golden points are not from matches table
      Goldenpoint: matchdetails.Goldenpoint,
      Master: sessionStorage.getItem("UserID"),
      CreatedBy: sessionStorage.getItem("UserID"),
      ModifiedBy: sessionStorage.getItem("UserID"),
    });
    fetch(process.env.REACT_APP_API + "match/postmultiplematches", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(copyDetails) ,
      body: JSON.stringify({
        ...copyDetails,
        NoOfSets: matchdetails.NoOfSets,
        GameType: matchdetails.GameType,
        Winpoint: matchdetails.Winpoint,
        Goldenpoint: matchdetails.Goldenpoint,
        Master: sessionStorage.getItem("UserID"),
        CreatedBy: sessionStorage.getItem("UserID"),
        ModifiedBy: sessionStorage.getItem("UserID"),
      }),
    })
      .then((res) => res.json())
      .then((result) => alert(result))
      .catch((err) => console.error(err))
      .then(setshowcopyModal(false));
  };

  const getgameforedit = (e) => {
    fetch(process.env.REACT_APP_API + "match/" + e, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0]+ 'thats it')
        setmatchdetails(data[0]);
      })
      .then(setshowScheduleModal(true));

    console.log(gameDetails);
  };

  const [showcopyModal, setshowcopyModal] = useState(false);

  const getgameforcopy = (e) => {
    fetch(process.env.REACT_APP_API + "match/" + e, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0]+ 'thats it')
        setmatchdetails(data[0]);
        setshowcopyModal(true)
      })
      // .then(setshowcopyModal(true));

    console.log(gameDetails);
  };

  const [userList, setuserList] = useState([]);
  const getMatchDetailsforReferee = (e) => {
    fetch(process.env.REACT_APP_API + "users/readuserslist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0]+ 'thats it')
        setuserList(data);
      });

    fetch(process.env.REACT_APP_API + "match/" + e, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0]+ 'thats it')
        setmatchdetails(data[0]);
        setReferee(data[0].Referee);
        setshowrefereeModal(true);
      })
      //.then(setshowrefereeModal(true));
      .catch((error) => {
        // Handle errors, if any, from the API request
        console.error("Error fetching data:", error);
      });

    console.log(matchdetails);
  };

  const saveReferee = (e) => {
    //e.preventDefault();
    // console.log("referee" + Refereeref.current.value);
    fetch(process.env.REACT_APP_API + "match/updatematchreferee", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Referee: referee,
        MatchID: currentMatchID,
        ModifiedBy: sessionStorage.getItem("UserID"),
      }),
    })
      .then((res) => res.json())
      .then((result) => alert(result))
      .catch((err) => console.error(err))
      .then(setshowrefereeModal(false));
  };

  const [currentMatchID, setcurrentMatchID] = React.useState(0);
  const [showScheduleModal, setshowScheduleModal] = React.useState(false);
  const [showrefereeModal, setshowrefereeModal] = React.useState(false);
  const [refereeId, setRefereeId] = React.useState();
  console.log(props.matchList.MatchID);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const SubmitHandlerreferee = (e) => {
    e.preventDefault();
    saveReferee(currentMatchID);
  };

  const infoChangeHandler = (datalabel, datavalue) => {
    // setInfo({...info,[e.target.name] : e.target.value});
    // console.log(e);
    setReferee(datavalue);
    console.log(datavalue);
  };


  
  return (
    <div className="bg-gray-50">
      {showScheduleModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h6 className=" text-lg text-gray-900 font-thin text-center">
                    Schedule Match
                  </h6>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setshowScheduleModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <form onSubmit={submitHandler}>
                  <div className="relative p-6 flex-auto">
                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                      {/* {currentMatchID} is equel to
                    {matchdetails.MatchID} */}
                    </p>

                    {/* <div className="input-container">
                      <label>Match ID</label>
                      {matchdetails.MatchID}
                       
                    </div> */}

                    <h2 className="text-base ">Match ID</h2>
                    <h2 className="text-lg ">{currentMatchID}</h2>

                    {/* <div className="input-container">
                      <label>Golden Point</label>
                      <input
                        type="text"
                        placeholder="Enter Golden Point"
                        onChange={changeHandler}
                        name="Goldenpoint"
                        value={matchdetails.Goldenpoint}
                        required
                      />
                    </div>
                    <div className="input-container">
                      <label>Win Point</label>
                      <input
                        type="text"
                        placeholder="Enter Win Point"
                        onChange={changeHandler}
                        name="Winpoint"
                        value={matchdetails.Winpoint}
                        required
                      />
                    </div> */}

                    <div className="input-container">
                      <label>Current Date</label>
                      {matchdetails.Date}
                      <label>New Date</label>
                      {/* <input
                        type="datetime-local"
                        placeholder="Enter Date of Match"
                        onChange={dateChangeHandler}
                        name="Date"
                        defaultvalue={matchdetails.Date}
                      /> */}
                      <DatePicker
                        className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy hh:mm aa"
                        minDate={new Date()}
                        showTimeInput
                        timeInputLabel="Time:"
                        showIcon
                      />
                    </div>

                    <div className="button-container py-4 gap-x-2">
                      <button
                        type="submit"
                        className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"

                        // onClick={() => setshowScheduleModal(false)}
                      >
                        Submit
                      </button>
                      <button
                        className="inline-block bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          setshowScheduleModal(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </form>
                {/*footer*/}
                {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setshowScheduleModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setshowScheduleModal(false)}
                  >
                    Save Changes
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showrefereeModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h6 className=" text-lg text-gray-900 font-thin text-center">
                    Set Referee
                  </h6>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setshowrefereeModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <form onSubmit={SubmitHandlerreferee}>
                  <div className="relative px-6 flex-auto">
                    {/* <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    {currentMatchID} is {gameDetails.GameID}
                  </p> */}

                    <h2 className="text-base ">Match ID</h2>
                    <h2 className="text-lg ">{currentMatchID}</h2>

                    {/* <input
              className="p-4 rounded-lg m-3"
              type="text"
              placeholder="Enter Name"
              name="uname"
              required
              list="PlayerName"
              ref={Refereeref}
            /> */}
                    {/* <select name="uname" ref={Refereeref}>
                      {userList.map((e, i) => (
                        <option className="text-lg" key={i} value={e.UserID}>
                          {e.FirstName} {e.LastName}, Email : {e.Email}
                        </option>
                      ))}
                    </select> */}
                    <label>Referee </label>
                    <SearchBar
                      //  SessionLabel={"team1p1"}
                      ArrayLabel={""}
                      inputDefaultValue={
                        matchdetails.RefereeFirstName +
                        " " +
                        matchdetails.RefereeLastName
                      } //inputDefaultValue={matchdetails.Referee}
                      handler={infoChangeHandler}
                    />
                  </div>

                  <div className="button-container py-4 gap-x-2">
                    <button
                      type="submit"
                      className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"

                      // onClick={() => setshowScheduleModal(false)}
                    >
                      Submit
                    </button>
                    <button
                      className="inline-block bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        setshowrefereeModal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </form>

                {/*footer*/}
                {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setshowrefereeModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setshowrefereeModal(false)}
                  >
                    Save Changes
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showcopyModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h6 className=" text-lg text-gray-900 font-thin text-center">
                    Copy Game
                  </h6>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setshowcopyModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <form onSubmit={copyHandler}>
                  {/* <div className="text-xl">
                        Select &nbsp; 
                          <input
                            type="checkbox"
                            id="topping"  // change name and id accordingly
                            name="topping"   // change name and id accordingly
                          //  value={e.GameID}
                          />
                          
                        </div> */}

                  <div className="relative p-6 flex-auto">
                    <h2 className="text-base ">Match ID</h2>
                    <h2 className="text-lg ">{currentMatchID}</h2>

                    <div>
                      <div className="text-sm py-2">Team A</div>
                      <div className="flex justify-between px-1">
                        <label>{matchdetails.T1P1FirstName}</label>
                        <input
                          className="p-1"
                          type="checkbox"
                          value={matchdetails.T1P1}
                          name="t1p1"
                          onChange={copyChangeHandler}
                        />{" "}
                      </div>
                      <div className="flex justify-between px-1">
                        <label>{matchdetails.T1P2FirstName}</label>
                        <input
                          type="checkbox"
                          value={matchdetails.T1P2}
                          name="t1p2"
                          onChange={copyChangeHandler}
                        />
                      </div>
                      <div className="text-sm py-2">Team B</div>
                      <div className="flex justify-between px-1">
                        <label>{matchdetails.T2P1FirstName}</label>
                        <input
                          type="checkbox"
                          value={matchdetails.T2P1}
                          name="t2p1"
                          onChange={copyChangeHandler}
                        />{" "}
                      </div>
                      <div className="flex justify-between px-1">
                        <label>{matchdetails.T2P2FirstName}</label>
                        <input
                          type="checkbox"
                          value={matchdetails.T2P2}
                          name="t2p2"
                          onChange={copyChangeHandler}
                        />
                      </div>

                      <div className="text-sm py-2">Date</div>
                      <div className="flex justify-between px-1">
                        <label>{matchdetails.Date}</label>
                        <input
                          type="checkbox"
                          placeholder="Enter Win Point"
                          onChange={copyChangeHandler}
                          name="date"
                          value={matchdetails.Date}
                          required
                        />{" "}
                      </div>

                      <div className="text-sm py-2">Number of Copies</div>
                      <div className="flex justify-between px-1">
                        <input
                          className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                          type="number"
                          placeholder="Enter Number of copies"
                          onChange={copyChangeHandler}
                          name="CopyCount"
                          min="1"
                          required
                        />{" "}
                      </div>
                    </div>
                    {/* <div className="input-container">
                      <label>Golden Point</label>
                      <input
                        type="checkbox"
                        placeholder="Enter Golden Point"
                        onChange={copyChangeHandler}
                        name="Goldenpoint"
                        value={gameDetails.Goldenpoint}
                        required
                      />
                       <label>{gameDetails.Goldenpoint}</label>
                    </div>
                    <div className="input-container">
                      <label>Win Point</label>
                      <input
                        type="checkbox"
                        placeholder="Enter Win Point"
                        onChange={copyChangeHandler}
                        name="Winpoint"
                        value={gameDetails.Winpoint}
                        required
                      />
                           <label>{gameDetails.Winpoint}</label>
                    </div> */}

                    {/*                     
                    <div className="text-sm py-2">
                      Date
                      <label>{matchdetails.Date}</label>
                      <input
                        type="checkbox"
                        //placeholder=""
                        onChange={copyChangeHandler}
                        name="date"
                        value={matchdetails.Date}
                        required
                      />
                    </div>
                    <div className="input-container">
                      <label>Number of Copies</label>
                      <input
                        type="number"
                        placeholder="No. of copies"
                        onChange={copyChangeHandler}
                        name="CopyCount"
                        min="1"
                        required
                      />
                    </div> */}



                    <div className="button-container py-4 gap-x-2">
                      <button
                        type="submit"
                        className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"

                        // onClick={() => setshowScheduleModal(false)}
                      >
                        Submit
                      </button>
                      <button
                        className="inline-block bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          setshowcopyModal(false);
                        }}
                      >
                        Close
                      </button>
                    </div>

                  </div>
                </form>
                {/*footer*/}
                {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setshowScheduleModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setshowScheduleModal(false)}
                  >
                    Save Changes
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <div
        key={props.matchList.MatchID}
        className="bg-secondary justify-center items-center text-center rounded-lg shadow-lg w-72 m-4 p-2 relative shrink-0 border-t-2"
      >
        <div className="flex justify-end px-3 pt-1">
          {props.matchList.TournamentID == null
            ? " "
            : "TournamentID : " + props.matchList.TournamentID + "  "}
          <div
            onClick={() =>
              handleMatchmenuToggle(showMatcheMenu, props.matchList.MatchID)
            }
            className="px-3"
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </div>
        </div>
        <div className="px-3 w-14 ">
          <div className="flex items-center">
            <img src="./Images/badminton shuttle.svg" />
          </div>
          <div className="flex items-center">
            <p className="text-xs "> ID: </p>
            <p className="text-xs "> {props.matchList.MatchID}</p>
          </div>
        </div>
        <div className="px-3 pt-3">
          <div className="flex">
            <p className="text-left">
              {props.matchList.T1P1FirstName}{" "}
              {props.matchList.GameType != "Single"
                ? "/ " + props.matchList.T1P2FirstName
                : ""}
            </p>
          </div>

          <h3 className="text-left text-gray-700 text-sm">v/s</h3>

          <div className="flex">
            <p className="text-left">
              {props.matchList.T2P1FirstName}{" "}
              {props.matchList.GameType != "Single"
                ? "/ " + props.matchList.T2P2FirstName
                : ""}
            </p>
          </div>
        </div>
        <div className="flex justify-start px-3 items-center">
          <FontAwesomeIcon icon={faWeightHanging} />
          <span className="text-left pl-2">{props.matchList.GameType}</span>
        </div>
        <div className="flex justify-start px-3 items-center">
          <FontAwesomeIcon icon={faWeightHanging} />
          <span className="text-left pl-2">{props.matchList.Date ? props.matchList.Date : "Not Scheduled"}</span>
        </div>

        {/* match dropdown */}

        <div
          className={`z-10 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 origin-top-right absolute  mt-2 w-48 right-1 top-5 ${
            showMatcheMenu && showMatcheMenuId === props.matchList.MatchID
              ? "block"
              : "hidden"
          }`}
          // onBlur={() => handleUsermenuToggle(true)}
          // onBlur={() => console.log("blur worked on usermenu")}
          ref={matchMenuRef}
          id="user-dropdown"
        >
          <ul className="py-2 text-left" aria-labelledby="user-menu-button">
            <li>
              <a
                onClick={() => {
                  sessionStorage.setItem("MatchID", props.matchList.MatchID);
                  props.matchList.GameType === "Single"
                    ? (window.location.href = "/updateplayerssingle")
                    : (window.location.href = "/updateplayers");
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >
                Start
              </a>
            </li>

            <li>
              <a
                onClick={() => {
                  // sessionStorage.setItem("gameID",props.matchList.GameID);
                  // window.location.href="/assignreferee";

                  setcurrentMatchID(props.matchList.MatchID);
                  getMatchDetailsforReferee(props.matchList.MatchID);
                  // setshowrefereeModal(true);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >
                Assign Referee
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  // sessionStorage.setItem("gameID",props.matchList.GameID);
                  // window.location.href="/assignreferee";

                  setcurrentMatchID(props.matchList.MatchID);
                  getgameforedit(props.matchList.MatchID);
                  // setshowScheduleModal(true);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >
                Schedule
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  // sessionStorage.setItem("gameID",props.matchList.GameID);
                  // window.location.href="/assignreferee";

                  setcurrentMatchID(props.matchList.MatchID);
                  getgameforcopy(props.matchList.MatchID);
                  // setshowScheduleModal(true);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >
                Copy
              </a>
            </li>
          </ul>
        </div>

        {/* match dropdown ends */}
      </div>
    </div>
  );
}

export default MatchesDisplay;
