import React from "react";
import "./Layout.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import NavigationBar from "./NavigationBar";

function Layout() {
  const Redux_FirstName = useSelector((state) => state.userinfo.FirstName);
  const Redux_LastName = useSelector((state) => state.userinfo.LastName);
  const Redux_UserID = useSelector((state) => state.userinfo.UserID);
  const Redux_UserType = useSelector((state) => state.userinfo.UserType);
  const Redux_UserRole = useSelector((state) => state.userinfo.UserRole);

  const [userData, setuserData] = useState([]);
  const [nouser, setnouser] = useState(false);

  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedUser, setisloadedUser] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedUser) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedUser]); //add all the induvidual loading variables here

  useEffect(() => {
    //  if (sessionStorage.getItem("UserID")!= null && sessionStorage.getItem("UserID")!= ""){
    if (Redux_UserID != null && Redux_UserID != "") {
      console.log("user not null");
      console.log(sessionStorage.getItem("UserID"));

      try {
        fetch(process.env.REACT_APP_API + "users/" + "readusers", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            UserID: Redux_UserID, //sessionStorage.getItem("UserID"),
          }),
        })
          .then((Response) => {
            if (!Response.ok) {
              throw Error("Could not fetch data.");
            }
            return Response.json();
          })
          .then((data) => {
            console.log(data);
            setuserData(data[0]);
          })
          .then(() => {
            setisloadedUser(true);
          })
          .catch((err) => {
            console.error(err);
            setisloaded(true);
            setIsError(true);
          });
      } catch (error) {
        setisloaded(true);
        setIsError(true);
        console.error(error);
      }
    } else {
      setnouser(true);
      setisloaded(true);
      console.log("no user");
    }
  }, []);

  //loading message
  if (!isloaded)
    return (
      <div className="flex justify-center items-center h-screen ">
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
        </div>
      </div>
    );
  //error message
  if (IsError)
    return (
      <div>
<NavigationBar IsError={IsError}/>
      </div>
    );

  if (nouser) {
    return (
      <div>
<NavigationBar nouser={nouser}/>
      </div>
    );
  }

  //default component
  if (isloaded && !IsError) {
    return (
      <div>
<NavigationBar userData={userData} />
      </div>
    );
  }

  return (
    <div>
       <NavigationBar/>
    </div>
  );
}

export default Layout;
