import React, { useState } from "react";
import Home_video_comp from "../Home/Home_video_comp";
import Android_Display from "./Android_Display";
import DownloadFromStore from "./DownloadFromStore";
import FeaturesDisplay from "./FeaturesDisplay";
import "./Home.css";

function Home() {
  return (
    <div className="">
      <div className="w-full bg-emerald-200">
        {/* <div className="w-full h-full relative flex items-center justify-center py-24">
          <img
            src="./Images/Smashstats_bg.jpg"
            className="absolute top-0 left-0 w-full h-full object-cover opacity-75 z-0"
          />

          <div className="max-w-4xl relative text-center">
            <h1 className="text-xl sm:text-3xl px-2 md:text-4xl font-black text-secondary leading-tight mb-2 customShadow">
             Don't just PLAY Badminton, make it worth by SCORING it!
            </h1>
            <h2 className="text-md sm:text-lg md:text-2xl font-condensed text-secondary leading-tight mb-8 customShadow">
              Pretium quam vulputate dignissim suspendisse in est ante
            </h2>
            <a
              href="/signup"
              className="inline-block bg-secondary font-condensed text-primary py-2 px-5 text-base rounded-full hover:shadow-lg hover:bg-primary hover:text-secondary"
            >
              Join Now
            </a>
            <h2 className="text-lg md:text-2xl font-medium text-secondary leading-tight py-4 customShadow">
              Download the app
            </h2>
            <DownloadFromStore/>
          </div>
          
        </div> */}

        <div className="container mx-auto flex flex-col sm:flex-row items-center justify-center py-8 md:py-24">
          <div className="w-1/3 mb-4">
            <img
              src="./Images/Smashstats_logo.png"
              className=" w-full h-full object-cover z-0"
            />
          </div>
          <div className="w-2/3">
            <div className="max-w-4xl relative text-center px-2">
              <h1 className="text-xl sm:text-4xl md:text-6xl px-2 font-black text-gray-700 leading-tight mb-2 ">
                Track, Play, Conquer with SmashStats!
              </h1>
              <h2 className="text-md sm:text-lg md:text-xl font-condensed text-gray-700 leading-tight mb-8 ">
                Welcome to SmashStats - your ultimate destination for badminton
                scoring and game analysis! We are passionate badminton
                enthusiasts who believe that every player, from beginners to
                pros, deserves a powerful tool to elevate their game. With
                SmashStats, we bring cutting-edge technology and intuitive
                design to enhance your badminton experience like never before.
              </h2>
              <a
                href="/signup"
                className="inline-block bg-secondary font-condensed text-primary py-2 px-5 text-base rounded-full hover:shadow-lg hover:bg-primary hover:text-secondary"
              >
                Join Now
              </a>
              <h2 className="text-lg md:text-2xl font-medium text-gray-700 leading-tight py-4 ">
                Download the app
              </h2>
              <DownloadFromStore />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-secondary p-8 sm:py-10">
        <div className="max-w-2xl mx-auto text-center mb-2 md:mb-12">
          <h2 className="font-black leading-tight text-xl md:text-3xl mb-4">
            Our Mission
          </h2>
          <p className="text-sm md:text-base font-condensed max-w-lg mx-auto">
            Etiam non quam lacus suspendisse faucibus interdum posuere lorem
            ipsum. Massa eget egestas purus viverra accumsan in.
          </p>
        </div>

        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center items-center">




            <div className="w-full lg:flex-1 max-w-2xl text-sm lg:pl-12 text-gray_dark_3">
              <h3 className="text-xl mb-3">
                Blandit volutpat maecenas volutpat blandit aliquam etiam
              </h3>
              <p className="mb-3">
                Ac auctor augue mauris augue neque gravida in fermentum et. Nunc
                id cursus metus aliquam eleifend mi in nulla posuere. Erat velit
                scelerisque in dictum non. Ut enim blandit volutpat maecenas
                volutpat. Blandit massa enim nec dui nunc mattis enim. Cursus
                risus at ultrices mi tempus imperdiet.
              </p>

              <a
                href="#"
                className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
              >
                Learn More
              </a>
            </div>

            <div className="mb-4 md:mb-12 lg:mb-0">
              <Home_video_comp />
            </div>
          </div>
        </div>
      </div> */}

      <div className="bg-gray_light_1 p-8 sm:py-10">
        {/* <div className="max-w-2xl mx-auto text-center mb-2 md:mb-12">
          <h2 className="font-black leading-tight text-xl md:text-3xl mb-4">
            About US
          </h2>
          <p className="text-sm md:text-base font-condensed max-w-lg mx-auto">
            Etiam non quam lacus suspendisse faucibus interdum posuere lorem
            ipsum. Massa eget egestas purus viverra accumsan in.
          </p>
        </div> */}

        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center items-center">
            <div className="w-full lg:flex-1 max-w-2xl text-sm lg:pr-12 text-gray_dark_3">
              <h3 className="font-black leading-tight text-xl md:text-3xl mb-4">Our Mission</h3>
              <p className="mb-3">
                At SmashStats, our mission is to empower badminton players
                worldwide with accurate and real-time scoring solutions that
                revolutionize the way matches are played and analyzed. We strive
                to provide a user-friendly platform that simplies the scoring
                process, allowing players to focus solely on their game, while
                we take care of the rest.
              </p>

              {/* <a
                href="#"
                className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
              >
                Learn More
              </a> */}
            </div>

            <div className="mb-2 md:mb-12 lg:mb-0 w-full lg:w-1/3 text-right">
              <div className="bg-secondary  rounded-lg shadow-lg w-full">
                <img
                  src="./Images/Our_Mission.jpeg"
                  className="w-full h-auto object-cover rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------------------------------------------------------------ */}

      <FeaturesDisplay />

      {/* ------------------------------------------------------------------------------------------------------------ */}

      <div className="bg-gray_light_1 p-8 sm:py-10">
        <div className="max-w-2xl mx-auto text-center mb-2 md:mb-12">
          <h2 className="font-black leading-tight text-xl md:text-3xl mb-4">
          Why Choose SmashStats?
          </h2>
          {/* <p className="text-sm md:text-base font-condensed max-w-lg mx-auto">
            Etiam non quam lacus suspendisse faucibus interdum posuere lorem
            ipsum. Massa eget egestas purus viverra accumsan in.
          </p> */}
        </div>

        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center items-center">
            <div className="w-full lg:flex-1 max-w-2xl text-sm lg:pr-12 text-gray_dark_3">
              <h3 className="text-xl mb-1">
              User-friendly Interface:
              </h3>
              <p className="mb-4">
              Our app is designed with simplicity in mind, ensuring that players 
of all levels can easily navigate and make the most of its features.
              </p>
              <h3 className="text-xl mb-1">
              Reliable and Accurate:
              </h3>
              <p className="mb-4">
              We take pride in delivering accurate scoring and analysis, so you 
can trust SmashStats to provide reliable data for your games.
              </p>
              <h3 className="text-xl mb-1">
              Constant Improvements:
              </h3>
              <p className="mb-4">
              We are committed to continuous development, listening to user 
feedback, and constantly improving SmashStats to meet your 
evolving needs.
              </p>
              <h3 className="text-xl mb-1">
              For Players, By Players:
              </h3>
              <p className="mb-4">
              As avid badminton players ourselves, we understand the demands 
of the game. SmashStats is tailored to cater to the specific requirements of badminton enthusiasts.
              </p>

              {/* <a
                href="#"
                className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
              >
                Learn More
              </a> */}
            </div>

            <div className="mb-2 md:mb-12 lg:mb-0 w-full lg:w-1/2 text-right">
              <div className="bg-secondary rounded-lg shadow-lg w-full">
                <img
                  src="./Images/why_choose.jpeg"
                  className="w-full h-auto object-cover rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray_dark_3 p-8 sm:py-10">
        <div className="max-w-2xl mx-auto text-center mb-12 ">
          <h2 className="font-black leading-tight text-xl md:text-3xl mb-4 text-gray_light_1">
          Join the SmashStats Community:
          </h2>
          <p className="text-sm md:text-base font-condensed max-w-lg mx-auto text-gray_light_1 px-2">
          Get ready to take your badminton game to new heights! Whether you play for leisure, in local tournaments, or dream of becoming a professional, SmashStats is here to support you every step of the way. Download the app now and experience the power of data-driven improvement in 
your badminton journey.
          </p>
        </div>
        <h2 className="font-black leading-tight text-xl md:text-3xl mb-4 text-gray_light_1">
        OUR JOURNEY
          </h2>
        <div className="mx-auto max-w-5xl flex flex-wrap justify-center items-center text-center ">
          
          <div className="w-full md:w-1/3  no-underline">
            <h4 className="mb-2 uppercase text-gray_light_1 text-lg font-bold tracking-widest">
              Games Scored
            </h4>
            <p className="text-4xl md:text-4xl font-serif text-gray_light_3">
              1500+
            </p>
          </div>

          <div className="w-full md:w-1/3 py-6 md:py-12  md:border-l-4 md:border-r-4 md:border-gray_dark_2 no-underline">
            <h4 className="mb-2 uppercase text-gray_light_1 text-lg font-bold tracking-widest">
              Users
            </h4>
            <p className="text-4xl md:text-4xl font-serif text-gray_light_3">
              2000+
            </p>
          </div>

          <div className="w-full md:w-1/3  no-underline">
            <h4 className="mb-2 uppercase text-gray_light_1 text-lg font-bold tracking-widest">
              Tournaments
            </h4>
            <p className="text-4xl md:text-4xl font-serif text-gray_light_3">
              250+
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray_light_0 md:p-12 py-8 md:py-10">
        {/* <h1 className="lg:text-5xl md:text-4xl text-2xl font-semibold px-4 leading-10 text-gray_dark_3 pt-6 text-center">
          What our client says
        </h1> */}
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="font-black leading-tight text-xl md:text-3xl mb-4">
            What our client says
          </h2>
          <p className="text-sm md:text-base font-condensed max-w-lg mx-auto px-2">
            Etiam non quam lacus suspendisse faucibus interdum posuere lorem
            ipsum. Massa eget egestas purus viverra accumsan in.
          </p>
        </div>
        <div className="container mx-auto grid sm:grid-cols-1  xl:px-20 px-10 py-8 gap-6">
          {" "}
          {/* md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 */}
          <div className="flex flex-col justify-center items-center">
            <div className="xl:w-96 group w-full bg-secondary relative flex flex-col justify-center items-center shadow-md md:p-12 p-6">
              <div className="text-gray_dark_1 flex flex-col items-center">
                <svg
                  width={26}
                  height={27}
                  viewBox="0 0 26 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M25.2578 14.3309H19.2969C19.3988 9.55819 20.6309 9.01642 22.1785 8.86178L22.7753 8.78051V3.53242L22.0874 3.57292C20.0666 3.69783 17.8323 4.09805 16.3417 6.11965C15.035 7.89183 14.459 10.7871 14.459 15.2316V23.4673H25.2578V14.3309Z"
                      fill="currentColor"
                    />
                    <path
                      d="M11.48 23.4673V14.3309H5.59859C5.70049 9.55819 6.89283 9.01642 8.44042 8.86178L8.99749 8.78051V3.53242L8.34931 3.57292C6.32844 3.69783 4.07421 4.09805 2.5836 6.11965C1.27707 7.89183 0.681147 10.7871 0.681147 15.2316V23.4673H11.48Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect
                        width="24.5767"
                        height={27}
                        fill="white"
                        transform="translate(25.2578 27) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <p className="xl:w-80 text-base leading-normal text-center mt-4">
                  Etiam dignissim diam quis enim lobortis scelerisque fermentum
                  dui. Gravida rutrum quisque non tellus orci ac auctor augue.
                </p>
              </div>
              <div className="text-secondary  absolute bottom-0 -mb-6">
                <svg
                  width={34}
                  height={28}
                  viewBox="0 0 34 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_dd)">
                    <path
                      d="M17 19L28.2583 3.25H5.74167L17 19Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_dd"
                      x="0.741699"
                      y="0.25"
                      width="32.5167"
                      height="27.75"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feMorphology
                        radius={1}
                        operator="erode"
                        in="SourceAlpha"
                        result="effect1_dropShadow"
                      />
                      <feOffset dy={4} />
                      <feGaussianBlur stdDeviation={3} />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy={2} />
                      <feGaussianBlur stdDeviation="2.5" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-10">
              <img
                src="https://i.ibb.co/ZgF5Zzz/avatar-1.png"
                alt="profile pictre"
                className="w-12 h-12"
              />
              <p className="text-base font-semibold leading-4 my-2 text-gray_dark_3">
                Tom Koch
              </p>
              <p className="text-base leading-4 text-center text-gray_dark_1">
                Developer
              </p>
            </div>
          </div>
          {/* <div>
            <div className="group w-full bg-secondary relative flex flex-col items-center   shadow-md md:p-12 p-6">
              <div className="text-gray_dark_1  flex flex-col items-center">
                <svg
                  width={26}
                  height={27}
                  viewBox="0 0 26 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M25.2578 14.3309H19.2969C19.3988 9.55819 20.6309 9.01642 22.1785 8.86178L22.7753 8.78051V3.53242L22.0874 3.57292C20.0666 3.69783 17.8323 4.09805 16.3417 6.11965C15.035 7.89183 14.459 10.7871 14.459 15.2316V23.4673H25.2578V14.3309Z"
                      fill="currentColor"
                    />
                    <path
                      d="M11.48 23.4673V14.3309H5.59859C5.70049 9.55819 6.89283 9.01642 8.44042 8.86178L8.99749 8.78051V3.53242L8.34931 3.57292C6.32844 3.69783 4.07421 4.09805 2.5836 6.11965C1.27707 7.89183 0.681147 10.7871 0.681147 15.2316V23.4673H11.48Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect
                        width="24.5767"
                        height={27}
                        fill="white"
                        transform="translate(25.2578 27) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <p className="xl:w-80 text-base leading-normal text-center mt-4">
                  Etiam dignissim diam quis enim lobortis scelerisque fermentum
                  dui. Gravida rutrum quisque non tellus orci ac auctor augue.
                </p>
              </div>
              <div className="text-secondary absolute bottom-0 -mb-6">
                <svg
                  width={34}
                  height={28}
                  viewBox="0 0 34 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_dd)">
                    <path
                      d="M17 19L28.2583 3.25H5.74167L17 19Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_dd"
                      x="0.741699"
                      y="0.25"
                      width="32.5167"
                      height="27.75"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feMorphology
                        radius={1}
                        operator="erode"
                        in="SourceAlpha"
                        result="effect1_dropShadow"
                      />
                      <feOffset dy={4} />
                      <feGaussianBlur stdDeviation={3} />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy={2} />
                      <feGaussianBlur stdDeviation="2.5" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-10">
              <img
                src="https://i.ibb.co/8BLjmqz/avatar-2.png"
                alt="profile pictre"
                className="w-12 h-12"
              />
              <p className="text-base font-semibold leading-4 my-2 text-gray_dark_3">
                Alan Max
              </p>
              <p className="text-base leading-4 text-center text-gray_dark_1">
                Designer
              </p>
            </div>
          </div>
          <div>
            <div className="group w-full bg-secondary relative flex flex-col items-center shadow-md md:p-12 p-6">
              <div className="text-gray_dark_1  flex flex-col items-center">
                <svg
                  width={26}
                  height={27}
                  viewBox="0 0 26 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M25.2578 14.3309H19.2969C19.3988 9.55819 20.6309 9.01642 22.1785 8.86178L22.7753 8.78051V3.53242L22.0874 3.57292C20.0666 3.69783 17.8323 4.09805 16.3417 6.11965C15.035 7.89183 14.459 10.7871 14.459 15.2316V23.4673H25.2578V14.3309Z"
                      fill="currentColor"
                    />
                    <path
                      d="M11.48 23.4673V14.3309H5.59859C5.70049 9.55819 6.89283 9.01642 8.44042 8.86178L8.99749 8.78051V3.53242L8.34931 3.57292C6.32844 3.69783 4.07421 4.09805 2.5836 6.11965C1.27707 7.89183 0.681147 10.7871 0.681147 15.2316V23.4673H11.48Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect
                        width="24.5767"
                        height={27}
                        fill="white"
                        transform="translate(25.2578 27) rotate(-180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <p className="xl:w-80 text-base leading-normal text-center mt-4">
                  Etiam dignissim diam quis enim lobortis scelerisque fermentum
                  dui. Gravida rutrum quisque non tellus orci ac auctor augue.
                </p>
              </div>
              <div className="text-secondary  absolute bottom-0 -mb-6">
                <svg
                  width={34}
                  height={28}
                  viewBox="0 0 34 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_dd)">
                    <path
                      d="M17 19L28.2583 3.25H5.74167L17 19Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_dd"
                      x="0.741699"
                      y="0.25"
                      width="32.5167"
                      height="27.75"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feMorphology
                        radius={1}
                        operator="erode"
                        in="SourceAlpha"
                        result="effect1_dropShadow"
                      />
                      <feOffset dy={4} />
                      <feGaussianBlur stdDeviation={3} />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy={2} />
                      <feGaussianBlur stdDeviation="2.5" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-10">
              <img
                src="https://i.ibb.co/y0KCX7p/avatar-3.png"
                alt="profile pictre"
                className="w-12 h-12"
              />
              <p className="text-base font-semibold leading-4 my-2 text-gray_dark_3">
                Kera Joo
              </p>
              <p className="text-base leading-4 text-center text-gray_dark_1">
                Support
              </p>
            </div>
          </div> */}
        </div>
      </div>

      <div className="bg-primary text-secondary p-4 md:p-12">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between items-center">
            <div className="text-center lg:text-left w-full mb-6 lg:mb-0 lg:w-auto">
              <strong className="font-black text-lg md:text-3xl">
                We value your feedback..
              </strong>
            </div>
            <div className="text-center lg:text-right w-full lg:w-auto">
              <a
                href="/contact"
                className="inline-block bg-secondary text-primary py-2 px-5 text-base rounded-full hover:shadow-lg"
              >
                Contact US
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
