import { Result } from "postcss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { omit } from "lodash";

function Signup() {
  const [errors, setErrors] = useState({});
  const [isStrongPassword, setisStrongPassword] = useState(false);

  const validate = (e) => {
    //A function to validate each input values

    switch (e.target.name) {
      case "FirstName":
        if (e.target.value.length <= 1) {
          setErrors({
            ...errors,
            FirstName: "First Name should atleast have 2 letters",
          });
          console.log("firstname errors");
        } else {
          let newObj = omit(errors, "FirstName");
          setErrors(newObj);
        }
        break;
      case "LastName":
        if (e.target.value.length < 1) {
          setErrors({
            ...errors,
            LastName: "Last Name should atleast have 1 letters",
          });
          console.log("lastname errors");
        } else {
          let newObj = omit(errors, "LastName");
          setErrors(newObj);
        }
        break;
      case "Email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(e.target.value)
        ) {
          setErrors({
            ...errors,
            Email: "Enter a valid email address",
          });
          console.log("email errors");
        } else {
          let newObj = omit(errors, "Email");
          setErrors(newObj);
        }

        break;
      case "Mobile":
        if (e.target.value.length < 10) {
          setErrors({
            ...errors,
            Mobile: "Mobile atleast have 10 digits",
          });
          console.log("Mobile errors");
        } else {
          let newObj = omit(errors, "Mobile");
          setErrors(newObj);
        }
        break;
      case "DOB":
        console.log(Date());
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let currentDate = year + "-" + month + "-" + date;
        if (e.target.value > currentDate) {
          setErrors({
            ...errors,
            DOB: "DOB is invalid",
          });
          console.log("DOB errors");
        } else {
          let newObj = omit(errors, "DOB");
          setErrors(newObj);
        }
        break;
      case "Password":
        if (
          !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/).test(
            e.target.value
          )
        ) {
          setErrors({
            ...errors,
            Password:
              "Password should contain 8 - 20 characters, an uppercase letter, a lowercase letter and a number",
          });
          setisStrongPassword(false);
          console.log("password errors");
        } else {
          let newObj = omit(errors, "Password");
          setErrors(newObj);
          setisStrongPassword(true);
          console.log("set isstrong paswd");
        }
      default:
        //  console.log("validation ends")
        break;
    }
  };

  const [userInfo, setuserInfo] = useState({
    UserID: "",
    FirstName: "",
    LastName: "",
    Gender: "Male",
    DOB: "",
    Mobile: "",
    Email: "",
      UserType: "2",
      UserRole: "4",
    PhotoFileName: "",
    Password: "",
  });

  const changeHandler = (e) => {
    setuserInfo({ ...userInfo, [e.target.name]: e.target.value });
    validate(e);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (Object.keys(errors).length===0) {

      fetch(process.env.REACT_APP_API + "users", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          FirstName: userInfo.FirstName,
          LastName: userInfo.LastName,
          DOB: userInfo.DOB,
          Gender: userInfo.Gender,
          Mobile: userInfo.Mobile,
            Email: userInfo.Email,
            UserRole: userInfo.UserType==='2'?'3':'4',
            UserType: userInfo.UserType,
          PhotoFileName: userInfo.PhotoFileName,
          //  "UserID": null, // this value is autogenerated by DB server
          Password: userInfo.Password,
        }),
      })
        .then((res) => res.json())
        .then(
          async (Result) => {
            console.log(Result[0]);
            if (Result[0].status=='created'){
              await new Promise((resolve) => {
                sessionStorage.setItem("UserID", Result[0].UserID);
                sessionStorage.setItem("UserData", JSON.stringify(Result[0]));
                console.log(Result[0].UserID);
                resolve();
              });
            window.location.href = "/dashboard/profile";
            }
            else if(Result[0].status=='exists'){
                alert("An account is already registered with your email address '"+Result[0].email)
            }
          },
          (error) => {
            alert("Failed");
          }
        );
    } else {
      alert("Enter Valid Details");
    }
  };

  return (
    <div>
      <div className="signupcontainer">
        <h2 className="heading">Sign Up</h2>
        <p>Already Registered?<span className="cursor-pointer text-blue-700" onClick={()=>{window.location.href="/login"}}>Login</span></p>
        <div className="form">
          <form onSubmit={submitHandler}>
            <div className="input-container">
              <label htmlFor="Gender">Register As </label>

              <select
                className="genderselect"
                onChange={changeHandler}
                name="UserType"
                defaultValue="3"
                required
              >
                <option value="2">Admin</option>
                <option value="3">Player</option>
              </select>
            </div>
            <div className="input-container">
              <label>First Name </label>
              <input
                type="text"
                placeholder="Enter First Name"
                onChange={changeHandler}
                name="FirstName"
                required
              />
              {errors.FirstName && (
                <h3 className="text-red-500">{errors.FirstName}</h3>
              )}
            </div>
            <div className="input-container">
              <label>Last Name </label>
              <input
                type="text"
                placeholder="Enter Last Name"
                onChange={changeHandler}
                name="LastName"
                required
              />
              {errors.LastName && (
                <h3 className="text-red-500">{errors.LastName}</h3>
              )}
            </div>

            <div className="input-container">
              <label>DOB</label>
              <input
                type="date"
                placeholder="Enter Date of Birth"
                onChange={changeHandler}
                name="DOB"
                required
              />
              {errors.DOB && <h3 className="text-red-500">{errors.DOB}</h3>}
            </div>
            {/* <div className="input-container">
              <label>Gender </label>
              <input
                type="text"
                placeholder="Enter Gender"
                onChange={changeHandler}
                name="Gender"
                required
              />
            </div> */}
            <div className="input-container">
              <label htmlFor="Gender">Gender </label>

              <select
                className="genderselect"
                onChange={changeHandler}
                name="Gender"
                defaultValue="Male"
                required
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="input-container">
              <label>Mobile </label>
              <input
                type="text"
                placeholder="Enter Mobile Number"
                onChange={changeHandler}
                name="Mobile"
                required
              />
              {errors.Mobile && (
                <h3 className="text-red-500">{errors.Mobile}</h3>
              )}
            </div>

            <div className="input-container pt-12">
              <label>Email </label>
              <input
                type="text"
                placeholder="Enter Email ID"
                onChange={changeHandler}
                name="Email"
                required
              />
              {errors.Email && <h3 className="text-red-500">{errors.Email}</h3>}
            </div>

            <div className="input-container">
              <label>Password </label>
              <input
                type="password"
                placeholder="Enter Password"
                onChange={changeHandler}
                name="Password"
                required
              />
              {errors.Password && (
                <h3 className="text-red-500">{errors.Password}</h3>
              )}
              {isStrongPassword && (
                <h3 className="text-green-500">Strong Password</h3>
              )}
            </div>

            <div className="button-container py-4">
              <button className="loginButton" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
