import React, { useLayoutEffect, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import MainRouter from "./components/MainRouter";
import Sidebar from "./components/Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { userInfoActions } from "./store";
import Footer from "./components/Layout/Footer";


function App() {
  const Redux_FirstName = useSelector((state) => state.userinfo.FirstName);
  const Redux_LastName = useSelector((state) => state.userinfo.LastName);
  const Redux_UserID = useSelector((state) => state.userinfo.UserID);
  const Redux_UserType = useSelector((state) => state.userinfo.UserType);
  const Redux_UserRole = useSelector((state) => state.userinfo.UserRole);
  const Redux_Authorized = useSelector((state) => state.userinfo.Authorized);

  const dispatch = useDispatch();

  const [userData, setuserData]= useState([]);
  const [nouser, setnouser] = useState(false);
  //const [auth, setauth] = useState(false);
   


  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedUser, setisloadedUser] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedUser) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedUser]); //add all the induvidual loading variables here

  useEffect(() => {
  
    if (Redux_UserID!= null && Redux_UserID!= ""){       
      console.log("user not null");
      console.log(sessionStorage.getItem("UserID")  );

    try {
      fetch(process.env.REACT_APP_API + "users/" + "readusers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserID: Redux_UserID,  //sessionStorage.getItem("UserID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setuserData(data[0]);
          dispatch(userInfoActions.updateUserInfo(data[0]));  //redux store action
          // if(data[0].UserID===Redux_UserID){
          //   setauth(true)
          // }
        })
        .then(() => {
          setisloadedUser(true);
          
         // console.log(auth);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
      console.error(error);
    }
  }
  else{
    setnouser(true);
    setisloaded(true);
    console.log("no user")
  }
  }, []);


  //function for determining the user is authorized
  if (Redux_UserID) {
    //auth= true;

  }

    //loading message
    if (!isloaded)
    return (
      <>
      
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
        </div>
      </div>
      </>
    );

      //error message
  if (IsError)
  return (
    <>
    
    <div className="flex justify-center items-center h-screen text-xl">
      <div className="flex justify-center items-center">
        <div className="">
          <h2>Oops! Something Went Wrong... Try refreshing the page.</h2>
        </div>
      </div>
    </div>
    </>
  );

  if (isloaded && !IsError) {
console.log("isloaded && !IsError");
//console.log(auth);
  return (
    <div className="App min-h-screen" id="outer-container">
      {/* {Redux_UserID && (
        <Sidebar
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      )} */}
      <div id="page-wrap">
        <BrowserRouter>
          <Layout/>
          <MainRouter Authorized={Redux_Authorized} />
          <Footer/>
        </BrowserRouter>
      </div>
    </div>
  );
      }
}

export default App;
