import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function CreateTournament() {
  const [matchList, setmatchList] = useState([]);
  const [matchIds, setmatchIds] = useState([]);
  const [TournamentID, setTournamentID] = useState("");

  const [TournamentDetails, setTournamentDetails] = useState([]);

  let condition = "";
  if (matchIds.length > 0) {
    matchIds.map((id, index) => {
      let string =
        index + 1 == matchIds.length
          ? `MatchID = ${id}`
          : `MatchID = ${id} OR `;
      condition += string;
    });
    console.log(condition);
  }

  const changeHandler = (e) => {
    setTournamentDetails({
      ...TournamentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // setuserList(sessionStorage.getItem("users"));
    // setuserList((e1) => e1.concat(...userInfo));
    // setuserList((e1) => e1.concat({ ...userInfo}));
    // setuserList((e1) => e1.concat(...userInfo, e1));
    //  sessionStorage.setItem("users", JSON.stringify(userList));
    //sessionStorage.setItem("users",JSON.stringify(users));

    fetch(process.env.REACT_APP_API + "tournament", {
      //    change from here to full function
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        TournamentName: TournamentDetails.TournamentName,
        ModifiedBy: sessionStorage.getItem("UserID"),
        CreatedBy: sessionStorage.getItem("UserID"),
        Description: TournamentDetails.Description,
        Date: TournamentDetails.Date,
      }),
    })
      .then((res) => res.json())
      .then(
        (Result) => {
          setTournamentID(Result[0].Column1);
          // sessionStorage.setItem("UserID",Result[0].Column1);
          // window.location.href="/userdash"

          setshowCreateModal(false);
        },
        (error) => {
          alert("Failed to Create Tournament");
        }
      )
      .then(console.log(TournamentID));

    // window.location.href="/login"
  };

  useEffect(() => {
    console.log(TournamentID);
    if (TournamentID) {
      console.log(TournamentID);
      fetch(process.env.REACT_APP_API + "match/updatetournamentidmatches", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          TournamentID: TournamentID,
          Condition: condition.length > 0 ? condition : "MatchID=" + null,
        }),
      })
        .then((res) => res.json())
        .then((result) => alert(result))
        .catch((err) => console.error(err));
    }
  }, [TournamentID]);

  useEffect(() => {
    //get game list
    fetch(process.env.REACT_APP_API + "match/getmatchesofmaster", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Master: sessionStorage.getItem("UserID"), //change this to userid of the user
      }),
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0].TeamTag+ 'thats it')
        setmatchList(data);
      });
    console.log("game list loaded");
  }, []);

  const selectedMatchIds = (id) => {
    if (matchIds.includes(id)) {
      setmatchIds(matchIds.filter((x) => x != id));
    } else {
      setmatchIds((e) => [...e, id]);
    }
  };

  useEffect(() => console.log(matchIds), [matchIds]);

  const [showCreateModal, setshowCreateModal] = useState(false);

  return (
    <div className="bg-gray-50 min-h-screen">
      {showCreateModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Create Tournament</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setshowCreateModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    {""}
                  </p>

                  <form onSubmit={submitHandler}>
                    <div className="input-container">
                      <label>Tournament Name</label>
                      <input
                        type="text"
                        placeholder="Enter Tournament Name"
                        onChange={changeHandler}
                        name="TournamentName"
                        required
                      />
                    </div>
                    <div className="input-container">
                      <label>Description</label>
                      <input
                        type="text"
                        placeholder="Enter Description"
                        onChange={changeHandler}
                        name="Description"
                      />
                    </div>
                    <div className="input-container">
                      <label>Schedule</label>
                      <input
                        type="datetime-local"
                        placeholder="Enter date"
                        onChange={changeHandler}
                        name="Date"
                      />
                    </div>

                    <div className="input-container text-2xl">
                      <div>{matchIds.length} Matches Selected</div>
                      {/* {
                       matchIds.map((e)=>(
                         <div>
                        
                         <div className="text-2xl"> {e}</div>
                         </div>
                       ))
                     } */}
                    </div>

                    <div className="button-container py-4">
                      <button
                        className="loginButton mr-3"
                        onClick={() => setshowCreateModal(false)}
                      >
                        Close
                      </button>

                      <button
                        className="loginButton"
                        type="submit"
                        //onClick={() => setshowCreateModal(false)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                {/*footer*/}
                {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setshowCreateModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setshowCreateModal(false)}
                  >
                    Save Changes
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* {sessionStorage.getItem("score") && showScore && (   // comment it */}
      <div>
        <div className="p-4">
          <div className="bg-white p-4 rounded-md">
            <div>
              <h2 className="mb-4 text-xl font-bold text-gray-700">
                Create A Tournament
              </h2>

              <div>
                <h2 className="text-xl font-normal text-gray-700"></h2>

                <div className="homeLayout">
                  {/* <h2 className="heading pt-3">Yet to be played</h2> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="bg-white p-4 rounded-md">
            <div className="p-5 flex grow-0">
              <Link to="/dashboard/tournaments">
                {/* <button className="loginButton">Login</button> */}
                <div
                  className={`${"bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg"}`}
                >
                  Back to Tournaments
                </div>
              </Link>
            </div>
            <h2 className="text-xl font-normal text-gray-700"></h2>

            <div className="homeLayout">
              <div className="flex justify-between mb-3">
                <h2 className="mb-4 text-xl font-bold text-gray-700 text-left">
                  Select From Created Matches
                </h2>

                <button
                  className="loginButton "
                  type="submit"
                  onClick={() => setshowCreateModal(true)}
                >
                  Create Tournament
                </button>
              </div>

              <Link to="/gametype">
                {" "}
                <button className="loginButton mb-3" type="submit">
                  Create A New Match
                </button>
              </Link>

              {/* <h2 className="heading pt-3">Yet to be played</h2> */}
              <div className="grid grid-cols-3">
                {matchList.map((e, i) => (
                  <div key={e.MatchID} className="scorecard mx-2">
                    <div className="text-xl">
                      Select &nbsp;
                      <input
                        type="checkbox"
                        id="topping" // change name and id accordingly
                        name="topping" // change name and id accordingly
                        onChange={(e) => selectedMatchIds(e.target.value)}
                        value={e.MatchID}
                      />
                    </div>
                    <h6 className="date">{e.Date}</h6>
                    <h4 className="grid grid-flow-col-2 text-xl m-4"></h4>
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex   ">
                        <p>
                          {e.T1P1} {e.GameType != "Single" ? "/ " + e.T1P2 : ""}
                        </p>
                        <p className="ml-8 font-bold "> v/s </p>
                        <p className="ml-8">
                          {e.T2P1} {e.GameType != "Single" ? "/ " + e.T2P2 : ""}
                        </p>
                      </div>
                    </div>
                    <h4 className="grid grid-flow-col-2 text-xl m-4">
                      {e.MatchID}
                    </h4>
                    <p className="mt-4"> </p>
                    {/* <button
                          className="px-7"
                          onClick={() => {
                            sessionStorage.setItem("gameID", e.GameID);
                            window.location.href = "/updateplayers";
                          }}
                        >
                          Start
                        </button> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTournament;
