import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function GameType() {

  return (
    <>
      <div className="bg-gray-100">
        <div className="min-h-screen w-full p-6 flex justify-center items-center">
          <div className="w-full max-w-lg">
            <div className="bg-white border p-8 shadow rounded w-full mb-6">
              <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
                Select Type Of Game
              </h1>

              <div>
                <div className="grid grid-cols-3 m-5 gap-x-2">
                  <button
                    type="button"
                    className="py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px rounded-md border font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400"
                    onClick={() => {
                      sessionStorage.setItem("GameType", "Single");
                      window.location.href = "/selectplayerssingle";
                    }}
                  >
                    <h2> Singles</h2>
                  </button>
                  <button
                    type="button"
                    className="py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px rounded-md border font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400"
                    onClick={() => {
                      sessionStorage.setItem("GameType", "Double");
                      window.location.href = "/selectplayers";
                    }}
                  >
                    <h2> Doubles</h2>
                  </button>
                  <button
                    type="button"
                    className="py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px rounded-md border font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400"
                    onClick={() => {
                      sessionStorage.setItem("GameType", "Mixed");
                      window.location.href = "/selectplayers";
                    }}
                  >
                    <h2> Mixed Doubles</h2>
                  </button>
                </div>
              </div>

              <div className="button-container text-center ">
                <button
                  onClick={() => {
                    window.location.href = "/dashboard/matches";
                  }}
                  className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GameType;
