import React, { useEffect } from 'react'
import { useState } from 'react';

function Displayserver(props) {



   // let team1p1 = sessionStorage.getItem("team1p1");
  //  let team1p2 = sessionStorage.getItem("team1p2");
  //  let team2p1 = sessionStorage.getItem("team2p1");
  //  let team2p2 = sessionStorage.getItem("team2p2");

    const [ServerName, setServerName] = useState("Loading...");

    const [ReceiverName, setReceiverName] = useState("Loading...");

useEffect (()=>{
  if (props.displayserver === "T1P1") {
    setServerName(props.team1p1Name);
  } else if (props.displayserver === "T1P2") {
    setServerName(props.team1p2Name);
  } else if (props.displayserver === "T2P1") {
    setServerName(props.team2p1Name);
  } else if (props.displayserver === "T2P2") {
    setServerName(props.team2p2Name);
  };
  if (props.displayReceiver === "T1P1") {
    setReceiverName(props.team1p1Name);
  } else if (props.displayReceiver === "T1P2") {
    setReceiverName(props.team1p2Name);
  } else if (props.displayReceiver === "T2P1") {
    setReceiverName(props.team2p1Name);
  } else if (props.displayReceiver === "T2P2") {
    setReceiverName(props.team2p2Name);
  };

},[props.displayserver, props.displayReceiver,props.team1p1Name,props.team1p2Name,props.team2p1Name,props.team2p2Name])
  

  return (
    <div >
    <div>{ServerName} serves to {ReceiverName}</div> 
    
    </div>
  )
}

export default Displayserver