import React, { useState, useEffect } from "react";



function T1P1(props) {

 // let PlayerName=(sessionStorage.getItem("team1p1"));

  if (props.server=== "T1P1") {     // change to props and take the value
    return (
      <div>
        <div className="text-red-700">{props.playername}</div>
        <div className="absolute left-[30%]">Serving</div>
      </div>
    );
  } else if (props.receiver=== "T1P1") {   // change to props and take the value
    return (
      <div>
        <div className="text-black">{props.playername}</div>
        <div className="absolute left-[30%]">Receiving</div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="text-white">{props.playername}</div>
      </div>
    );
  }
}

export default T1P1;
