import React, { useState, useEffect } from "react";
import "../InitialSteps/Toss.css";
import GoBackButton from "../Layout/GoBackButton";

function TossSingle() {
  let team1p1 = sessionStorage.getItem("team1p1");
  let team1p2 = sessionStorage.getItem("team1p2");
  let team2p1 = sessionStorage.getItem("team2p1");
  let team2p2 = sessionStorage.getItem("team2p2");

  const [team1p1name, setteam1p1name] = useState("Guest1");
  const [team1p2name, setteam1p2name] = useState("Guest2");
  const [team2p1name, setteam2p1name] = useState("Guest3");
  const [team2p2name, setteam2p2name] = useState("Guest4");

  const [gameDetails, setgameDetails] = useState([]);
  const [matchDetails, setmatchDetails] = useState([]);
  const [isloadedplayers, setisloadedplayers] = useState(false);

  const [PlayersDetails, setPlayersDetails] = useState({});

  useEffect(() => {
    // if (sessionStorage.getItem("users")) {
    //   setUsersList(JSON.parse(sessionStorage.getItem("users")));
    // }

    try {
      fetch(process.env.REACT_APP_API + "match/" + "getmatchdetails", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"), //"1000001145"
        }),
      })
        .then((Response) => Response.json())
        .then((data) => {
          console.log(data);
          setmatchDetails(data);
        });
    } catch (error) {
      alert("error loading players details");
    }

    // fetch(process.env.REACT_APP_API + "game/" + (sessionStorage.getItem("gameID"))) // change the value of gameid
    // .then((Response) => Response.json())
    // .then((data) => {
    //   setgameDetails(data[0]);
    //   // alert(data[0])
    // });

    try {
      fetch(process.env.REACT_APP_API + "match/" + "getplayers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"), //"1000001145"
        }),
      })
        .then((Response) => Response.json())
        .then((data) => {
          console.log(data);
          setPlayersDetails(data[0]);
          setisloadedplayers(true);
        });
    } catch (error) {
      alert("error loading players details");
    }
  }, []);

  useEffect(() => {
    if (isloadedplayers === true) {
      setteam1p1name(PlayersDetails.T1P1Name);
      setteam1p2name(PlayersDetails.T1P2Name);
      setteam2p1name(PlayersDetails.T2P1Name);
      setteam2p2name(PlayersDetails.T2P2Name);
    }
  }, [isloadedplayers]);

  return (
    <div>
      {/* <div className="">
        <div className="text-2xl text-center">Who Won The Toss</div>
        <div className="grid grid-cols-2 m-5">
          <div onClick={() => sessionStorage.setItem("tosswinner", "A")}>
            <Link
              to="/switchcourtsingle"
              className="text-3xl flex justify-center items-center card bg-slate-200 mx-2 rounded-3xl "
            >
              <h2>{team1p1name} </h2>
            </Link>
          </div>

          <div onClick={() => sessionStorage.setItem("tosswinner", "B")}>
            <Link
              to="/switchcourtsingle"
              className="text-3xl flex justify-center items-center card bg-slate-200 mx-2 rounded-3xl"
            >
              <h2>{team2p1name} </h2>
            </Link>
          </div>
        </div>
        <div className="button-container text-center ">
          <Link to="/custompointssingle">
            {" "}
            <button className="loginButton" type="submit">
              Back
            </button>
          </Link>
        </div>
      </div> */}

      <div className="bg-gray-100">
        <div className="min-h-screen w-full p-6 flex justify-center items-center">
          <div className="w-full max-w-xl">
            <div className="bg-white border p-8 shadow rounded w-full mb-6">
              <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
                Who Won The Toss
              </h1>

              <div>
                <div className="grid grid-cols-1 md:grid-cols-2 m-5 gap-2">
                  <div
                    className=" justify-center items-center bg-green-200 mx-2 rounded-md cursor-pointer"
                    onClick={() => {
                      sessionStorage.setItem("tosswinner", "A");
                      window.location.href = "/switchcourtsingle";
                    }}
                  >
                    <h2 className="text-md font-normal pt-3">Player I</h2>
                    <div className="block sm:flex md:block justify-evenly items-center p-3">
                      <h2 className="font-light">
                        {team1p1name} <br />
                      </h2>
                    </div>
                  </div>
                  <div
                    className=" justify-center items-center bg-green-200 mx-2 rounded-md cursor-pointer"
                    onClick={() => {
                      sessionStorage.setItem("tosswinner", "B");
                      window.location.href = "/switchcourtsingle";
                    }}
                  >
                    <h2 className="text-md font-normal pt-3">Player II</h2>
                    <div className="block sm:flex md:block justify-evenly items-center p-3">
                      <h2 className="font-light">
                        {team2p1name} <br />
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center gap-2 text-center ">
                {/* <button
                  onClick={() => {
                    window.location.href = "/custompointssingle";
                  }}
                  className="bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                >
                  Back
                </button> */}
                <GoBackButton
                  Cssclass="bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                  btnTxt="Back"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TossSingle;
