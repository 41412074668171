import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";


// const initialState = { counter: 0, showCounter: true, UserID:null, FirstName:"demo name", LastName:"", UserType:""};

let initialState = { UserID: sessionStorage.getItem('UserData') && JSON.parse(sessionStorage.getItem('UserData')).UserID ,
                    FirstName: sessionStorage.getItem('UserData') && JSON.parse(sessionStorage.getItem('UserData')).FirstName,
                    LastName: sessionStorage.getItem('UserData') && JSON.parse(sessionStorage.getItem('UserData')).LastName, 
                    UserType: sessionStorage.getItem('UserData') && JSON.parse(sessionStorage.getItem('UserData')).UserType,
                    UserRole: sessionStorage.getItem('UserData') && JSON.parse(sessionStorage.getItem('UserData')).UserRole,
                    Authorized: false
                    };





const userInfoSlice = createSlice({
  name: "userinfo",
  initialState,
  reducers: {
    // increment(state) {
    //   state.counter++;
    //   console.log(state.counter)
    // },
    // decrement(state) {
    //   state.counter--;
    // },
    // increase(state, action) {
    //   state.counter = state.counter + action.payload;
    //   console.log(action.payload);
    // },
    // toggle(state) {
    //   state.showCounter = !state.showCounter;
    //   console.log(state.showCounter);
    // },

    updateUserInfo(state, action) {
      if (state.UserID==action.payload.UserID){    //checking for authorisation here as of now
        state.Authorized = true;
        console.log("Authorized set true");
      };
      state.UserID = action.payload.UserID;
      state.FirstName = action.payload.FirstName;
      state.LastName = action.payload.LastName;
      state.UserType = action.payload.UserType;
      state.UserRole = action.payload.UserRole;

      
      console.log(action.payload);
     },

    //test reducer
    printName(state) {
        state.UserType = state.UserType+'a';
        state.UserRole = state.UserRole+'a';
        console.log(state.FirstName);
        console.log(initialState.UserType);
        
      },
  },
});



const store = configureStore({
    reducer: {userinfo: userInfoSlice.reducer }
});

export const userInfoActions= userInfoSlice.actions;

export default store;

