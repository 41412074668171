import React from "react";
import FeaturesContent from "./FeaturesContent";

function TextCarousel(props) {
  // const Features = [
  //   {
  //     id: "1",
  //     content: "Seamless Scoring",
  //     Description:
  //       "Say goodbye to cumbersome scorekeeping. With SmashStats, you can effortlessly keep track of every point, game, and set with just a few taps on your smartphone.",
  //   },
  //   {
  //     id: "2",
  //     content: "Live Match Analysis",
  //     Description:
  //       "Get real-time insights into your game with our live match analysis feature. Visualize your strengths and areas for improvement, giving you a competitive edge on the court.",
  //   },
  //   {
  //     id: "3",
  //     content: "Organize Tournament digitaly",
  //     Description:
  //       " Take your love for badminton to the next level by organizing tournaments seamlessly through SmashStats. From creating brackets and managing schedules to tracking scores, we provide all the tools you need to host successful events that bring the badminton community together.",
  //   },
  //   {
  //     id: "4",
  //     content: "Match History",
  //     Description:
  //       "Review your previous games and monitor your progress over time. SmashStats provides you with a comprehensive match history to help you gauge your performance and set new milestones.",
  //   },
  //   {
  //     id: "5",
  //     content: "Share and Connect",
  //     Description:
  //       "Share your achievements and match results with your friends and fellow badminton enthusiasts. Connect with a vibrant community of players, exchange tips, and challenge each other to friendly matches.",
  //   },
  //   {
  //     id: "6",
  //     content: "Seamless Scoring",
  //     Description:
  //       "Dive deep into your game with personalized statistics. Understand your playing patterns, shot accuracy, and win ratios to develop a winning strategy that suits your style.",
  //   },
  //   {
  //     id: "7",
  //     content: "Running Academy",
  //     Description:
  //       "If you're passionate about teaching badminton, SmashStats offers a dedicated platform for running your own academy. Manage classes, track students' progress, and provide personalized feedback to nurture the next generation of badminton stars.",
  //   },
  //   {
  //     id: "8",
  //     content: "Personalized Statistics",
  //     Description:
  //       "Dive deep into your game with personalized statistics. Understand your playing patterns, shot accuracy, and win ratios to develop a winning strategy that suits your style.",
  //   },
  //   {
  //     id: "9",
  //     content: "At tellus at urna condimentum.",
  //     Description:
  //       "Etiam dignissim diam quis enim lobortis scelerisquefermentum dui. Gravida rutrum quisque non tellus orci acauctor augue.",
  //   },
  //   {
  //     id: "10",
  //     content: "At tellus at urna condimentum.",
  //     Description:
  //       "Etiam dignissim diam quis enim lobortis scelerisquefermentum dui. Gravida rutrum quisque non tellus orci acauctor augue.",
  //   },
  // ];

  return (
    <div>
      <div className="flex justify-center">
        <div className="p-1 sm:p-2 ">
          <button
            className="p-1 sm:p-2 rounded-full 
          bg-primary hover:bg-primary_1 border-none"
            onMouseOver={() => props.setindex_fn(1)}
          ></button>
        </div>
        <div className="p-1 sm:p-2">
          <button
            className="p-1 sm:p-2 rounded-full 
          bg-primary hover:bg-primary_1 border-none"
            onMouseOver={() => props.setindex_fn(2)}
          ></button>
        </div>
        <div className="p-1 sm:p-2">
          <button
            className="p-1 sm:p-2 rounded-full 
          bg-primary hover:bg-primary_1 border-none"
            onMouseOver={() => props.setindex_fn(3)}
          ></button>
        </div>
        <div className="p-1 sm:p-2">
          <button
            className="p-1 sm:p-2 rounded-full 
          bg-primary hover:bg-primary_1 border-none"
            onMouseOver={() => props.setindex_fn(4)}
          ></button>
        </div>
        <div className="p-1 sm:p-2">
          <button
            className="p-1 sm:p-2 rounded-full 
          bg-primary hover:bg-primary_1 border-none"
            onMouseOver={() => props.setindex_fn(5)}
          ></button>
        </div>
        <div className="p-1 sm:p-2">
          <button
            className="p-1 sm:p-2 rounded-full 
          bg-primary hover:bg-primary_1 border-none"
            onMouseOver={() => props.setindex_fn(6)}
          ></button>
        </div>
        <div className="p-1 sm:p-2">
          <button
            className="p-1 sm:p-2 rounded-full 
          bg-primary hover:bg-primary_1 border-none"
            onMouseOver={() => props.setindex_fn(7)}
          ></button>
        </div>
        <div className="p-1 sm:p-2">
          <button
            className="p-1 sm:p-2 rounded-full 
          bg-primary hover:bg-primary_1 border-none"
            onMouseOver={() => props.setindex_fn(8)}
          ></button>
        </div>
        <div className="p-1 sm:p-2">
          <button
            className="p-1 sm:p-2 rounded-full 
          bg-primary hover:bg-primary_1 border-none"
            onMouseOver={() => props.setindex_fn(9)}
          ></button>
        </div>
        <div className="p-1 sm:p-2">
          <button
            className="p-1 sm:p-2 rounded-full 
          bg-primary hover:bg-primary_1 border-none"
            onMouseOver={() => props.setindex_fn(10)}
          ></button>
        </div>
      </div>

      <div className="flex justify-center items-center">
        {FeaturesContent.filter((ftrs) => ftrs.id == props.ftr_index).map((e) => (
          <div key={e.id}>
            <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2 text-center">
              {e.content}
            </h4>
            <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
              {e.Description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TextCarousel;

