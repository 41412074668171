import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../InitialSteps/Toss.css";

function SwitchCourtSingle() {
  let team1p1 = sessionStorage.getItem("team1p1");
  let team1p2 = sessionStorage.getItem("team1p2");
  let team2p1 = sessionStorage.getItem("team2p1");
  let team2p2 = sessionStorage.getItem("team2p2");

  const [team1p1name, setteam1p1name] = useState("Guest1");
  //   const [team1p2name, setteam1p2name] = useState("Guest2");
  const [team2p1name, setteam2p1name] = useState("Guest3");
  //   const [team2p2name, setteam2p2name] = useState("Guest4");

  const [gameDetails, setgameDetails] = useState([]);
  const [matchDetails, setmatchDetails] = useState([]);
  const [isloadedplayers, setisloadedplayers] = useState(false);

  const [PlayersDetails, setPlayersDetails] = useState({});
  useEffect(() => {
    // if (sessionStorage.getItem("users")) {
    //   setUsersList(JSON.parse(sessionStorage.getItem("users")));
    // }

    try {
      fetch(process.env.REACT_APP_API + "match/" + "getmatchdetails", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"), //"1000001145"
        }),
      })
        .then((Response) => Response.json())
        .then((data) => {
          console.log(data);
          setmatchDetails(data);
        });
    } catch (error) {
      alert("error loading match details");
    }

    // fetch(process.env.REACT_APP_API + "game/" + (sessionStorage.getItem("gameID"))) // change the value of gameid
    // .then((Response) => Response.json())
    // .then((data) => {
    //   setgameDetails(data[0]);
    //   // alert(data[0])
    // });

    try {
      fetch(process.env.REACT_APP_API + "match/" + "getplayers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"), //"1000001145"
        }),
      })
        .then((Response) => Response.json())
        .then((data) => {
          console.log(data);
          setPlayersDetails(data[0]);
          setisloadedplayers(true);
        });
    } catch (error) {
      alert("error loading players details");
    }
  }, []);

  useEffect(() => {
    if (isloadedplayers === true) {
      setteam1p1name(PlayersDetails.T1P1Name);
      //  setteam1p2name(PlayersDetails.T1P2Name);
      setteam2p1name(PlayersDetails.T2P1Name);
      //  setteam2p2name(PlayersDetails.T2P2Name);
    }
  }, [isloadedplayers]);

  useEffect(() => {
    if (isloadedplayers === true) {
      setteam1p1name(PlayersDetails.T1P1Name);
      //   setteam1p2name(PlayersDetails.T1P2Name);
      setteam2p1name(PlayersDetails.T2P1Name);
      //   setteam2p2name(PlayersDetails.T2P2Name);
    }
  }, [PlayersDetails]);

  const clickHandler = (toLocation) => {
    fetch(process.env.REACT_APP_API + "game/updategameandmatchdtls", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        TournamentID: "",
        GroupID: "",
        Round: "",
        TossWinner:
          sessionStorage.getItem("tosswinner") == "A"
            ? "T" + PlayersDetails.T1P1ID + "T" + PlayersDetails.T1P2ID + "T"
            : "T" + PlayersDetails.T2P1ID + "T" + PlayersDetails.T2P2ID + "T",
        //change date here
        T1P1: sessionStorage.getItem("team1p1"),
        //  "T1P2": (sessionStorage.getItem("team1p2")),
        T2P1: sessionStorage.getItem("team2p1"),
        //  "T2P2": (sessionStorage.getItem("team2p2")),
        Shuttles: "",
        Master: sessionStorage.getItem("UserID"),
        ChoosetoServe: sessionStorage.getItem("ChoosetoServe"),
        MatchID: sessionStorage.getItem("MatchID"),
      }),
    })
      .then((res) => res.json())
      .then(
        (Result) => {
          // sessionStorage.setItem("gameID",Result[0].Column1);
          window.location.href = "/" + toLocation;
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const switchthecourt = () => {
    sessionStorage.setItem("team1p1", PlayersDetails.T2P1ID); // change the gameDetails.T2P1 to T1P1Name (check)
    //sessionStorage.setItem("team1p2", PlayersDetails.T2P2ID);
    sessionStorage.setItem("team2p1", PlayersDetails.T1P1ID);
   // sessionStorage.setItem("team2p2", PlayersDetails.T1P2ID);
    setPlayersDetails({
      T1P1ID: PlayersDetails.T2P1ID,
    //  T1P2ID: PlayersDetails.T2P2ID,
      T2P1ID: PlayersDetails.T1P1ID,
    //  T2P2ID: PlayersDetails.T1P2ID,
      T1P1Name: PlayersDetails.T2P1Name,
    //  T1P2Name: PlayersDetails.T2P2Name,
      T2P1Name: PlayersDetails.T1P1Name,
     // T2P2Name: PlayersDetails.T1P2Name,
    });

    sessionStorage.setItem(
      "tosswinner",
      sessionStorage.getItem("tosswinner") == "A" ? "B" : "A"
    );
    // alert("switch clicked");
  };

  return (
    <div>
      {/* <div className="">
        <div className="text-2xl text-center">Place the Team</div>
        <div className="grid grid-cols-3 m-5">
          <h2 className="text-2xl">LEFT</h2>
          <h2> </h2>
          <h2 className="text-2xl">RIGHT</h2>
        </div>
        <div className="grid grid-cols-3 m-5">
          <div className="text-3xl flex justify-center items-center card bg-slate-200 mx-2 rounded-3xl ">
            <h2>
              {team1p1name} <br />
            </h2>
          </div>

          <div
            className="text-3xl flex justify-center items-center card2 bg-slate-200 m-8 rounded-3xl cursor-pointer"
            onClick={() => {
              sessionStorage.setItem("team1p1", PlayersDetails.T2P1ID); // change the gameDetails.T2P1 to T1P1Name (check)
              //   sessionStorage.setItem("team1p2", PlayersDetails.T2P2ID);
              sessionStorage.setItem("team2p1", PlayersDetails.T1P1ID);
              //   sessionStorage.setItem("team2p2", PlayersDetails.T1P2ID);
              setPlayersDetails({
                T1P1ID: PlayersDetails.T2P1ID,
                //  T1P2ID: PlayersDetails.T2P2ID,
                T2P1ID: PlayersDetails.T1P1ID,
                //   T2P2ID: PlayersDetails.T1P2ID,
                T1P1Name: PlayersDetails.T2P1Name,
                //  T1P2Name: PlayersDetails.T2P2Name,
                T2P1Name: PlayersDetails.T1P1Name,
                //  T2P2Name: PlayersDetails.T1P2Name,
              });

              sessionStorage.setItem(
                "tosswinner",
                sessionStorage.getItem("tosswinner") == "A" ? "B" : "A"
              );
              alert("switch clicked");
            }}
          >
            <h2 className="text-lg ">Switch</h2>
          </div>

          <div className="text-3xl flex justify-center items-center card bg-slate-200 mx-2 rounded-3xl">
            <h2>
              {team2p1name} <br />
            </h2>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="button-container text-center mx-2">
            <Link to="/tosssingle">
              {" "}
              <button className="loginButton">Back</button>
            </Link>
          </div>
          <div className="button-container text-center mx-2">
          {" "}
            <button
              className="loginButton"
              onClick={() => clickHandler("whoservesingle")}
            >
              Next
            </button>
            
          </div>
        </div>
      </div> */}

      <div className="bg-gray-100">
        <div className="min-h-screen w-full p-6 flex justify-center items-center">
          <div className="w-full max-w-xl">
            <div className="bg-white border p-8 shadow rounded w-full mb-6">
              <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
                Place The Team
              </h1>

              <div>
                <div className="grid grid-cols-1 md:grid-cols-swichcourtgrid m-5">
                  <div>
                    <h2 className="text-md font-normal pt-3">LEFT</h2>
                    <div className=" justify-center items-center bg-green-200 mx-2 rounded-md ">
                      <div className="block sm:flex md:block justify-evenly items-center p-3">
                        <h2 className="font-light">
                          {team1p1name} <br />
                          
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-end text-center ">
                    <button
                      onClick={() => {
                        switchthecourt();
                      }}
                      className=" bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Switch
                    </button>
                  </div>
                  <div>
                    <h2 className="text-md font-normal pt-3">RIGHT</h2>
                    <div className=" justify-center items-center bg-green-200 mx-2 rounded-md ">
                      <div className="block sm:flex md:block justify-evenly items-center p-3">
                        <h2 className="font-light">
                          {team2p1name} <br />
                          
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center gap-2 text-center ">
                <button
                  onClick={() => clickHandler("whoservesingle")}
                  className=" bg-primary text-secondary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                >
                  Next
                </button>
                <button
                  onClick={() => {
                    window.location.href = "/tosssingle";
                  }}
                  className=" bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwitchCourtSingle;
