import React, {useEffect, useState} from 'react'


function UFEPercentage() {
    const [UFECount,setUFECount]=useState(0);
    const [TotalPoints,setTotalPoints]=useState(0);

      //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedcounts, setisloadedcounts] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedcounts) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedcounts]); //add all the induvidual loading variables here
    

    useEffect(()=>{

      try {
        fetch(process.env.REACT_APP_API + "scoreboard/getufeprecentage", {
          method: "POST",
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body:JSON.stringify({
            
            "UID": (sessionStorage.getItem("UserID"))  //change this to userid of the user
          })
        })
          .then((Response) => Response.json())
          .then((data) => {
           // alert(data[0].TeamTag+ 'thats it')
           setUFECount(data[0].UFECount);
           setTotalPoints(data[0].TotalPoints);
           setisloadedcounts(true);
           console.log(data);
        })
        .then(
         
        )
        ;
      } catch (error) {
        setIsError(true);
      }
    

    },[]);
     //loading message
  if (!isloaded)
  return (
    <div className="spinner-border animate-spin inline-block w-5 h-5 border-t-2 rounded-t-full"></div>
  );
//error message
if (IsError)
  return (
    <div className="">
      <h2>Error...</h2>
    </div>
  );
//default component
if (isloaded && !IsError)
  return (
    <div>
         <p className="">{ (Number (UFECount/TotalPoints*100)).toFixed(2)}%</p>
    </div>
  )
}

export default UFEPercentage