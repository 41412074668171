import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NavigationBar = (props) => {

  const navigate = useNavigate();

  const Redux_FirstName = useSelector((state) => state.userinfo.FirstName);
  const Redux_LastName = useSelector((state) => state.userinfo.LastName);
  const Redux_UserID = useSelector((state) => state.userinfo.UserID);
  const Redux_UserType = useSelector((state) => state.userinfo.UserType);
  const Redux_UserRole = useSelector((state) => state.userinfo.UserRole);

  const location=useLocation();
  
  const   activePage="block py-2 pl-3 pr-4  text-black rounded  md:hover:bg-transparent  md:p-0 dark:text-secondary md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700";
  const inactivePage="block py-2 pl-3 pr-4 text-gray_dark_1 rounded hover:text-black  md:hover:bg-transparent  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700";

    const [showUserMenu, setshowUserMenu] = useState(false);
    const [showMobileMenu, setshowMobileMenu] = useState(false);

    const userMenuRef = useRef(null);
    const mobileMenuRef = useRef(null);

    function handleClickOutsideUserMenu(event) {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
       console.log("clicked outside the usermenu.....!!!");
       handleUsermenuToggle(true);
      }
    }

    useEffect(()=>{
      if (showUserMenu) {
        window.addEventListener('click', handleClickOutsideUserMenu);
      }
  
      return () => {
        window.removeEventListener('click', handleClickOutsideUserMenu);
      };
    },[showUserMenu])

    function handleClickOutsideMobilemenu(event) {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        console.log("clicked outside mobilemenu");
        handleMobileMenuToggle(true);
      }
    }

    useEffect(()=>{
      if (showMobileMenu) {
        window.addEventListener('click', handleClickOutsideMobilemenu);
      }
  
      return () => {
        window.removeEventListener('click', handleClickOutsideMobilemenu);
      };
    },[showMobileMenu])






    const handleUsermenuToggle = (value) => {
        setshowUserMenu(!value);
    };
    const handleMobileMenuToggle = (value) => {
        setshowMobileMenu(!value);
      };


      useEffect(()=>{console.log(props.userData);},[props.userData])
      console.log(props.userData)
  return (
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 dark:bg-gray-900 ">
      <div className="container flex flex-nowrap items-center justify-between mx-auto relative">
        <a href="/" className="flex items-center order-1 hover:bg-secondary dark:hover:bg-gray-900">
          <img
            src="./Images/Smashstats_logo.png"
            className="h-9"
            alt="Smashstats Logo"
          />
          {/* <span className="self-center text-xl font-black whitespace-nowrap dark:text-white">Smashstats</span> */}
          <span className=" h-full p-2 flex items-center font-semibold text-sm sm:text-xl lg:text-2xl dark:text-secondary">
            {/* <img src="http://placehold.it/280x160" className="h-full w-auto" /> */}
            SMASHSTATS
          </span>
        </a>
        <div className="flex items-center order-2">
          <div className="flex items-center md:order-3 md:p-1">
            <div className="flex items-center  relative">
              {Redux_UserID ? (
                <button
                  type="button"
                  onClick={() => handleUsermenuToggle(showUserMenu)}
                  
                  className="flex sm:mr-3 text-sm bg-transparent p-0 border-0 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                  id="user-menu-button"
                  aria-expanded="false"
                  data-dropdown-toggle="user-dropdown"
                  data-dropdown-placement="bottom"
                >
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="w-6 h-6 sm:w-8 sm:h-8 rounded-full"
                    src="./Images/profile_picture.svg"
                    alt="user photo"
                  />
                </button>
              ) : (
                <div className="md:px-6">
                  {/* {`${((sessionStorage.getItem("UserID").length)>0)? (""):(<Link to="/login"><button className="loginButton">Login</button></Link>)}`} */}
                  {!window.location.href.includes("/login") && (
                    <Link to="/login">
                      {/* <button className="loginButton">Login</button> */}
                      <div
                        className={`${"bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg"}`}
                      >
                        Login
                      </div>
                    </Link>
                  )}
                </div>
              )}

              {/* <!-- Dropdown menu --> */}
              <div
                className={`z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 origin-top-right absolute  mt-2 w-48 right-1 top-8 ${
                  showUserMenu ? "block" : "hidden"
                }`}
                // onBlur={() => handleUsermenuToggle(true)}
                // onBlur={() => console.log("blur worked on usermenu")}
                ref={userMenuRef}
                id="user-dropdown"
              >
                <div className="px-4 py-3">
                  <span className="block text-sm text-gray-900 dark:text-white">
                    {props.userData ? props.userData.FirstName : ""}
                  </span>
                  <span className="block text-sm font-medium text-gray-500 truncate dark:text-gray-400">
                    {props.userData ? props.userData.Email : ""}
                  </span>
                </div>
                <ul className="py-2" aria-labelledby="user-menu-button">
                  
                  <li>
                    <a
                      
                      href="/dashboard/profile"
                      // onClick={()=>navigate(`/dashboard/profile`)}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Profile
                    </a>
                  </li>
                  
                  <li>
                    <a
                      href="/dashboard/notification"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Notification
                    </a>
                  </li>
                  <li>
                    <a
                      href="/dashboard/account"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Account
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={async () => {
                        await new Promise((resolve) => {
                          sessionStorage.removeItem("UserID");
                          sessionStorage.removeItem("UserData");
                          resolve();
                        });
                        window.location.href = "/login";
                      }}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <button
              type="button"
              onClick={() => handleMobileMenuToggle(showMobileMenu)}
              // onBlur={() => handleMobileMenuToggle(true)}
              data-collapse-toggle="mobile-menu-2"
              className="inline-flex items-center p-0 sm:p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div
            className={`items-center justify-between z-30  w-full md:flex md:w-auto md:order-2 absolute md:static top-8 right-0  ${
              showMobileMenu ? "block" : "hidden"
            }`}
            ref={mobileMenuRef}
            id="mobile-menu-2"
          >
            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">

              <li>
                <a
                  href="#"
                  // className={`${(!window.location.href.includes("/live") && !window.location.href.includes("/contact"))?activePage:inactivePage} `}
                  className={`${(location.pathname==="/")?activePage:inactivePage} `}
                 
                  aria-current="page"
                >
                  Home
                </a>
              </li>

              <li>
                <a
                  href="/live"
                  className={`${(location.pathname==="/live")?activePage:inactivePage}`}
                  aria-current="page"
                >
                  Live
                </a>
              </li>

              <li>
                <a
                  href="/contact"
                  className={`${(location.pathname==="/contact")?activePage:inactivePage} `}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
