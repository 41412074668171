import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function MyTournaments() {


const [Tournamentslist, setTournamentslist]=useState([]);
    useEffect(()=>{
    fetch(process.env.REACT_APP_API + "tournament/gettournaments", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body:JSON.stringify({
          
          "CreatedBy": (sessionStorage.getItem("UserID"))  //change this to userid of the user
        })
      })
        .then((Response) => Response.json())
        .then((data) => {
         // alert(data[0].TeamTag+ 'thats it')
         setTournamentslist(data);
      });
      console.log("Tournaments loaded");
        }, []);
  return (
    <div className="bg-gray-50 min-h-screen">

<div className="p-5 flex grow-0">
<Link to="/createtournament">
                      {/* <button className="loginButton">Login</button> */}
                      <div
                        className={`${"bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg"}`}
                      >
                        Create A Tournament
                      </div>
                    </Link>
                    </div>



        <div className="p-4">
          <div className="bg-white p-4 rounded-md">
            <div>
              <h2 className="mb-4 text-xl font-bold text-gray-700">
                Created Tournaments
              </h2>
              <div>
                <h2 className="text-xl font-normal text-gray-700"></h2>

                <div className="homeLayout">
                  
                  <div className="grid grid-cols-3">
                    {Tournamentslist.map((e, i) => (
                      <div key={e.TournamentID} className="scorecard mx-2">
                        
                        <h4 className="grid grid-flow-col-2 text-xl m-4"></h4>
                        <div className="flex flex-col justify-center items-center">
                          <div className="flex   ">
                            <p>
                              {e.TournamentName}
                            </p>
                            
                          </div>
                        </div>
                        <h4 className="grid grid-flow-col-2 text-xl m-4">
                          {e.TournamentID}
                        </h4>
                        <p className="mt-4"> </p>
                        <button
                          className="px-7 mx-2"
                          onClick={() => {
                            sessionStorage.setItem("TournamentID", e.TournamentID);
                            window.location.href = "/viewtournament";
                          }}
                        >
                          View
                        </button>

                     
                       
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default MyTournaments