class Logger {
    constructor(){
       // this.MajorNumber=maninNumber*subNumber
    }

    mathOperationSum(fnum, snum){
        let result=fnum+snum;
        console.log(result);
    }

    mathOperationSubstraction(fnum, snum){
        let result=fnum-snum;
        console.log(result);
    }

    mathOperationMultiplication(fnum, snum){
        let result=fnum*snum;
        console.log(result);
    }

    mathOperationDivision(fnum, snum){
        let result=fnum/snum;
        console.log(result);
    }
}
export default Logger;