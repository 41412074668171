import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { faWeightHanging  } from '@fortawesome/free-solid-svg-icons'
import MatchesDisplay from "../Matches/MatchesDisplay";
import Homepagetestcomp1 from './Homepagetestcomp1'
import Homepagetestcomp2 from './Homepagetestcomp2'
import Homepagetestcomp3 from './Homepagetestcomp3'
import Videotest from '../Home/Home_video_comp'
import Cascadetest from '../Home/Android_Display'
import Carousel from './Carousel'
import Linetest from './Linetest'
import ExampleComponent from './ExampleComponent'
import NavigationBar from '../Layout/NavigationBar'
function HomepageUItest() {


  const getDeviceType = () => {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return "iOS device";
    } else if (navigator.userAgent.match(/Android/i)) {
      return "Android";
    } else if (navigator.userAgent.match(/Windows Phone|Tablet|Mobile/i)) {
      return "Windows Mobile";
    } else {
      return "Desktop";
    }
  };

  const isMobileOrTablet = () => {
    const mediaQuery = window.matchMedia("(max-width: 768px)"); // Adjust the breakpoint as needed
    console.log(mediaQuery.matches);
    return mediaQuery.matches;
  };
    // State to track whether the device is in landscape mode and screen width
    const [showInterface, setshowInterface] = useState(((getDeviceType() !== "Desktop")) ?
      window.matchMedia("(orientation: landscape)").matches :
      window.innerWidth >= 1080 // Check screen width
    );

  useEffect(() => {
    // Create a media query for landscape orientation
    const mediaQuery = window.matchMedia("(orientation: landscape)");

    // Function to handle changes in orientation and screen width
    const handleOrientationChange = (event) => {
      setshowInterface(event.matches && window.innerWidth >= 768); // Update state based on conditions
    };

    // Attach the event listener for orientation changes
    mediaQuery.addEventListener("change", handleOrientationChange);

    // Attach the event listener for window resizing
    const handleResize = () => {
      setshowInterface(mediaQuery.matches && window.innerWidth >= 768); // Update state based on conditions
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listeners when the component unmounts
    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs only on mount and unmount

  const getErrorMsg = () => {
    if (isMobileOrTablet() && getDeviceType() !== "Desktop") {
      return (
        <div>Change the orientation of your {getDeviceType()} to landscape mode to capture the score.</div>
      );
    }
    if (window.innerWidth <= 1080) {
      return (
        <div>Increase the size of the window to capture the score.
          <p className="text-slate-400">Device type: {getDeviceType()}</p>
        </div>
      );
    }
    return (
      <div>Unknown Error Occured.</div>
    );
  };

  return (
    <div className="text-slate-400">
      
      {showInterface ? (
        <div className="text-slate-400 dark:text-red-500 h-screen">
          
        </div>
      ) : (
        <div className="text-slate-400 dark:text-red-500 h-screen flex justify-center items-center text-center">
          {getErrorMsg()}
        </div>
      )}
    </div>
  );
};

export default HomepageUItest;
