import React, { useEffect, useState } from "react";

function Elapsed(props) {
  const [elapsedTime, setelapsedTime] = React.useState(0);
  let seconds = Math.floor(elapsedTime % 60);
  let secondsAsString = seconds < 10 ? "0" + seconds : seconds;
  let minutes = Math.floor(elapsedTime / 60);
  //let minutes = minutesvalue % 60;
  let minutesAsString = minutes < 10 ? "0" + minutes : minutes;

  


  React.useEffect(() => {
    if(props.Pause==false){
    const timer = setInterval(() => setelapsedTime(elapsedTime + 1), 1000);

    return () => clearInterval(timer);
    }
    
  }, [elapsedTime, props.Pause]);

  useEffect(()=>{
    fetch(process.env.REACT_APP_API + "game/updateduration", {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "Duration": elapsedTime,
        "GameID": (sessionStorage.getItem("gameID"))
      }) ,
    })
      .then((res) => res.json())
      .then(result => console.log(result))
      .catch(err=>console.error(err))

  },[props.DurationTrigger])

  return (
      <div>
    <div className=" text-center text-red-700 items-center font-bold text-lg  font-mono">
       Duration <br/>
      
    </div>
    <div className=" text-center text-red-700 items-center font-bold text-2xl  font-mono">
       
      {minutesAsString}:{secondsAsString}
    </div>
    
    </div>
  );
}

export default Elapsed;
