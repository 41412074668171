import React from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';


function GoBackButton(props) {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // This will navigate back to the previous page
      };

  return (
        <button onClick={handleGoBack} className={props.Cssclass}>
      {props.btnTxt}
    </button>
  )
}

export default GoBackButton