import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function WhoReceiving() {

  let team1p1=(sessionStorage.getItem("team1p1"));
  let team1p2=(sessionStorage.getItem("team1p2"));
  let team2p1=(sessionStorage.getItem("team2p1"));
  let team2p2=(sessionStorage.getItem("team2p2"));

  const [team1p1name,setteam1p1name]=useState("Guest1");
  const [team1p2name,setteam1p2name]=useState("Guest2");
  const [team2p1name,setteam2p1name]=useState("Guest3");
  const [team2p2name,setteam2p2name]=useState("Guest4");


const [gameDetails, setgameDetails]=useState([]);
const [matchDetails, setmatchDetails]=useState([]);
const [isloadedplayers, setisloadedplayers]=useState(false);

const [PlayersDetails, setPlayersDetails]=useState({});


useEffect(() => {
  // if (sessionStorage.getItem("users")) {
  //   setUsersList(JSON.parse(sessionStorage.getItem("users")));
  // }

  try{
    
     
    fetch(process.env.REACT_APP_API + "match/"+"getmatchdetails", {
     method: "POST",
     headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json'
     },
     body:JSON.stringify({
       
       "MatchID": (sessionStorage.getItem("MatchID")) //"1000001145" 
     })
   })
     .then((Response) => Response.json())
     .then((data) => {
   
     console.log(data);
     setmatchDetails(data);
    
   });
  
 
   } catch (error){
    alert("error loading players details");
    
     
   }


    // fetch(process.env.REACT_APP_API + "game/" + (sessionStorage.getItem("gameID"))) // change the value of gameid
    // .then((Response) => Response.json())
    // .then((data) => {
    //   setgameDetails(data[0]);
    //   // alert(data[0])
    // });

    try{
    
     
      fetch(process.env.REACT_APP_API + "match/"+"getplayers", {
       method: "POST",
       headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json'
       },
       body:JSON.stringify({
         
         "MatchID": (sessionStorage.getItem("MatchID")) //"1000001145" 
       })
     })
       .then((Response) => Response.json())
       .then((data) => {
     
       console.log(data);
       setPlayersDetails(data[0]);
       setisloadedplayers(true);
     });
    
   
     } catch (error){
      alert("error loading players details");
      
       
     }


}, []);


useEffect(()=>{
  if (isloadedplayers===true){
    setteam1p1name(PlayersDetails.T1P1Name);
    setteam1p2name(PlayersDetails.T1P2Name);
    setteam2p1name(PlayersDetails.T2P1Name);
    setteam2p2name(PlayersDetails.T2P2Name);
  }

},[isloadedplayers]);




  const SaveGameDetails = (toLocation)=>{
    
    fetch(process.env.REACT_APP_API + "game/updategameandmatchdtls", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        
        "TournamentID": "",
        "GroupID":"",
        "Round": "",
        "TossWinner": ((sessionStorage.getItem("tosswinner"))=="A")? "T"+PlayersDetails.T1P1ID+"T"+PlayersDetails.T1P2ID+"T" : "T"+PlayersDetails.T2P1ID+"T"+PlayersDetails.T2P2ID+"T",
        "FirstServer": (sessionStorage.getItem("server")),
        "FirstReceiver": (sessionStorage.getItem("receiver")),
                                        //change date here
        "T1P1": (sessionStorage.getItem("team1p1")),
        "T1P2": (sessionStorage.getItem("team1p2")),
        "T2P1": (sessionStorage.getItem("team2p1")),
        "T2P2": (sessionStorage.getItem("team2p2")),
        "Shuttles":"",
        "Winpoint": (sessionStorage.getItem("winpoint")),
        "Goldenpoint": (sessionStorage.getItem("goldenpoint")),
        "Master": (sessionStorage.getItem("UserID")),
        "ChoosetoServe": (sessionStorage.getItem("ChoosetoServe")),
        "MatchID":(sessionStorage.getItem("MatchID"))
      }),
    })
      .then((res) => res.json())
      .then(
        (Result) => {
         // sessionStorage.setItem("gameID",Result[0].Column1);
          alert(Result);
          window.location.href = "/"+toLocation
        },
        (error) => {
          alert("Failed");
        }
      );

  };

  return (
    <div>
      {/* <div className="">
        <div className="text-2xl text-center">Which Player is Receiving</div>
        <div className="grid grid-cols-2 m-5">
          <div
            className="text-3xl flex justify-center items-center card bg-slate-200 mx-2 rounded-3xl cursor-pointer"
            onClick={() => {
              sessionStorage.setItem("receiver", (sessionStorage.getItem("tosswinner"))=="A" ? ((sessionStorage.getItem("ChoosetoServe"))=='true'? 'T2P1' : 'T1P1') : ((sessionStorage.getItem("ChoosetoServe"))=='false'? 'T2P1' : 'T1P1'));
              SaveGameDetails("timer");
            }}
          >
           
            <h2> {`${(sessionStorage.getItem("tosswinner"))=="A" ? ((sessionStorage.getItem("ChoosetoServe"))=='true'? team2p1name : team1p1name) : ((sessionStorage.getItem("ChoosetoServe"))=='false'? team2p1name : team1p1name)}`}</h2>
          
          </div>
          <div
            className="text-3xl flex justify-center items-center card bg-slate-200 mx-2 rounded-3xl cursor-pointer"
            onClick={() => {
              sessionStorage.setItem("receiver",(sessionStorage.getItem("tosswinner"))=="A" ? ((sessionStorage.getItem("ChoosetoServe"))=='true'? 'T2P2':'T1P2') : ((sessionStorage.getItem("ChoosetoServe"))=='false'? 'T2P2':'T1P2'));
              SaveGameDetails("timer");
             // window.location.href = "/custompoints";
            }}
          >
          
           <h2> {`${(sessionStorage.getItem("tosswinner"))=="A" ? ((sessionStorage.getItem("ChoosetoServe"))=='true'? team2p2name:team1p2name) : ((sessionStorage.getItem("ChoosetoServe"))=='false'? team2p2name:team1p2name)}`}</h2>
        
          </div>
        </div>
        <div className="button-container text-center ">
          <Link to="/whoserving">
            {" "}
            <button className="loginButton" type="submit">
              Back
            </button>
          </Link>
        </div>
      </div> */}


      <div className="bg-gray-100">
        <div className="min-h-screen w-full p-6 flex justify-center items-center">
          <div className="w-full max-w-xl">
            <div className="bg-white border p-8 shadow rounded w-full mb-6">
              <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
              Which Player is Receiving
              </h1>

              <div>
                <div className="grid grid-cols-1 md:grid-cols-2 m-5 gap-2">
                  <div
                    className=" justify-center items-center bg-green-200 mx-2 rounded-md cursor-pointer"
                    onClick={() => {
                      sessionStorage.setItem("receiver", (sessionStorage.getItem("tosswinner"))=="A" ? ((sessionStorage.getItem("ChoosetoServe"))=='true'? 'T2P1' : 'T1P1') : ((sessionStorage.getItem("ChoosetoServe"))=='false'? 'T2P1' : 'T1P1'));
                      SaveGameDetails("timer");
                    }}
                  >
                     <h2 className="text-base font-normal p-3"> {`${(sessionStorage.getItem("tosswinner"))=="A" ? ((sessionStorage.getItem("ChoosetoServe"))=='true'? team2p1name : team1p1name) : ((sessionStorage.getItem("ChoosetoServe"))=='false'? team2p1name : team1p1name)}`}</h2>
                  </div>
                  <div
                    className=" justify-center items-center bg-green-200 mx-2 rounded-md cursor-pointer"
                    onClick={() => {
                      sessionStorage.setItem("receiver",(sessionStorage.getItem("tosswinner"))=="A" ? ((sessionStorage.getItem("ChoosetoServe"))=='true'? 'T2P2':'T1P2') : ((sessionStorage.getItem("ChoosetoServe"))=='false'? 'T2P2':'T1P2'));
                      SaveGameDetails("timer");
                    }}
                  >
                    <h2 className="text-base font-normal p-3"> {`${(sessionStorage.getItem("tosswinner"))=="A" ? ((sessionStorage.getItem("ChoosetoServe"))=='true'? team2p2name:team1p2name) : ((sessionStorage.getItem("ChoosetoServe"))=='false'? team2p2name:team1p2name)}`}</h2>
       
                  </div>
                </div>
              </div>

              <div className="flex justify-center gap-2 text-center ">
                <button
                  onClick={() => {
                    window.location.href = "/whoserving";
                  }}
                  className=" bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhoReceiving;
