import React, { useState,useEffect, useRef } from 'react'
import PlayerProfile from '../Stats/PlayerProfile';
import MyTournaments from '../Tournament/MyTournaments';
import Accout from '../User/Accout';
import AsReferee from '../User/AsReferee';
import CreatedGames from '../User/CreatedGames';
import './Dashboard.css'
import NotificationFeed from './NotificationFeed';
import Profile from './Profile';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";



function Dashboard({ children }) {

  const Redux_FirstName = useSelector((state) => state.userinfo.FirstName);
  const Redux_LastName = useSelector((state) => state.userinfo.LastName);
  const Redux_UserID = useSelector((state) => state.userinfo.UserID);
  const Redux_UserType = useSelector((state) => state.userinfo.UserType);
  const Redux_UserRole = useSelector((state) => state.userinfo.UserRole);

  const [UnreadNotifCount, setUnreadNotifCount]=useState(0);


  const navigate = useNavigate();

  const {tabname}=useParams();

  useEffect(() => {
    console.log(tabname);
    
   
  }, [tabname])
  
  

  const [active_tab, setactive_tab]=useState(tabname);
  const [sideMenuDisaply, setsideMenuDisaply]=useState();

  const sideMenuRef = useRef(null);

  function handleClickOutside(event) {
    if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
      console.log("clicked outside the sidebar");
     // handleViewportWidthChange();
    
      setsideMenuDisaply("hidden");
     
    }
  }

  useEffect(()=>{
    if (sideMenuDisaply==="absolute") {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  },[sideMenuDisaply])

  const activeTabHandler=(tabName)=>{

    setactive_tab(tabName);
    if (sideMenuDisaply==="absolute"){
      setsideMenuDisaply("hidden");
     }
    
     navigate(`/dashboard/${tabName}`);
  };

  const mobileSideMenuHandler=(e)=>{   //block, hidden, and absolute are the three values to be given
    console.log("Viewport width changed to 767px or less"+ (e?"true":"false"));
    setsideMenuDisaply(e);
  };

  const handleViewportWidthChange = () => {
    
    if (window.innerWidth <= 767) {
      mobileSideMenuHandler("hidden");
    }
    else{
      mobileSideMenuHandler("block");
    }
  };

  useEffect(() => {
    // call the function when the viewport width changes to 640px or less
  handleViewportWidthChange();

    window.addEventListener('resize', handleViewportWidthChange);

    return () => {
      window.removeEventListener('resize', handleViewportWidthChange);
    };
  }, []);

const getnotificationscount=()=>{
    
  try {
    fetch(process.env.REACT_APP_API + "notifications/" + "getnotificationscount", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ForUserID: Redux_UserID,
      }),
    })
      .then((Response) => {
        if (!Response.ok) {
          throw Error("Could not update data");
        }
        return Response.json();
      })
      .then((data) => {
        console.log(data);
        setUnreadNotifCount(data[0].NoitfCount);
      })
  
      .catch((err) => {
        console.error(err);
  
      });
  } catch (error) {
    console.error(error);
  }
};

  useEffect(()=>{
    getnotificationscount();

  },[])

  return (
<div>


<div
      className="flex  bg-gray-50 dark:bg-gray-900 DashboardMain" 
      // :className="{ 'overflow-hidden': isSideMenuOpen}"
      
    >
      {/* <!-- Desktop sidebar --> */}
      <aside
        className={`z-20 flex-shrink-0   w-64 overflow-y-auto bg-white dark:bg-gray-800 DashboardMain  ${sideMenuDisaply}`}
        ref={sideMenuRef}
      >
        <div className="py-4 text-gray-500 dark:text-gray-400">
          {/* <a
            className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
            href="#"
          >
            Windmill
          </a> */}
          <ul >
            <li className="relative px-6 py-3" onClick={()=>activeTabHandler("profile")}>
           {active_tab==="profile" && <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  ></path>
                </svg>
                <span className="ml-4">Profile</span>
              </div>
            </li>
            <li className="relative px-6 py-3" onClick={()=>activeTabHandler("notification")}>
            {active_tab==="notification" && <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  ></path>
                </svg>
                <span className="ml-4">Notifications</span>
                {UnreadNotifCount>0 && (<span className="flex-grow text-right">
                        <button type="button" className="w-auto h-auto text-xs p-0  rounded-full text-white bg-red-500">
                            <span className="p-2">
                               {UnreadNotifCount}
                            </span>
                        </button>
                    </span>)}
              </div>
            </li>
            <li className="relative px-6 py-3" onClick={()=>activeTabHandler("matches")}>
            {active_tab==="matches" &&  <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  ></path>
                </svg>
                <span className="ml-4">Matches</span>
              </div>
            </li>
            <li className="relative px-6 py-3" onClick={()=>activeTabHandler("tournaments")}>
            {active_tab==="tournaments" &&  <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
               
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  ></path>
                </svg>
                <span className="ml-4">Tournaments</span>
              </div>
            </li>



            <li className="relative px-6 py-3" onClick={()=>activeTabHandler("stats")}>
           {active_tab==="stats" &&   <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                  ></path>
                  <path d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
                </svg>
                <span className="ml-4">Stats</span>
              </div>
            </li>

            <li className="relative px-6 py-3" onClick={()=>activeTabHandler("referee")}>
           {active_tab==="referee" &&   <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                  ></path>
                </svg>
                <span className="ml-4">Referee</span>
              </div>
            </li>

            <li className="relative px-6 py-3" onClick={()=>activeTabHandler("account")}>
            {active_tab==="account" &&  <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
                </svg>
                <span className="ml-4">Account</span>
              </div>
            </li>
          
          </ul>

        </div>
      </aside>

      {/*mobile side menu*/}
      <aside
        className={`z-10 flex-shrink-0   w-14 overflow-y-auto bg-white dark:bg-gray-800 md:hidden`}
      >
        <div className="py-4 text-gray-500 dark:text-gray-400" onClick={()=>mobileSideMenuHandler("absolute")}>
          {/* <a
            className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
            href="#"
          >
            Windmill
          </a> */}
          <ul >
            <li className="relative px-4 py-3" >
           {active_tab==="profile" && <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  ></path>
                </svg>
                
              </div>
            </li>
            <li className="relative px-4 py-3" >
            {active_tab==="notification" && <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  ></path>
                </svg>
                
              </div>
            </li>
            <li className="relative px-4 py-3" >
            {active_tab==="matches" &&  <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  ></path>
                </svg>
               
              </div>
            </li>
            <li className="relative px-4 py-3" >
            {active_tab==="tournaments" &&  <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
               
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  ></path>
                </svg>
                
              </div>
            </li>



            <li className="relative px-4 py-3" >
           {active_tab==="stats" &&   <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                  ></path>
                  <path d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
                </svg>
                
              </div>
            </li>

            <li className="relative px-4 py-3" >
           {active_tab==="referee" &&   <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                  ></path>
                </svg>
                
              </div>
            </li>

            <li className="relative px-4 py-3" >
            {active_tab==="account" &&  <span
                className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>}
              <div
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
                </svg>
                
              </div>
            </li>
          
          </ul>

        </div>
      </aside>
      
{/* main area */}
      <div className="flex flex-col flex-1">
        {/* <header className="z-10 py-4 bg-white shadow-md dark:bg-gray-800">
           <div></div>
        </header> */}
        <main className="h-full overflow-y-auto">
          <div className="container grid px-1 sm:px-6 mx-auto">
          {/* {children} */}
{active_tab==="profile" && <Profile/>}
{active_tab==="notification" && <NotificationFeed NoitfCountFn={getnotificationscount}/>}
{active_tab==="matches" && <CreatedGames/>}
{active_tab==="tournaments" && <MyTournaments/>}
{active_tab==="stats" && <PlayerProfile/>}
{active_tab==="referee" && <AsReferee/>}
{active_tab==="account" && <Accout/>}

        
                

          </div>
        </main>
      </div>
    </div>


</div>
  )
}

export default Dashboard