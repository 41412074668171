import React from 'react'

function PageNotFound() {
  return (
    <div className="flex justify-center items-center h-screen">
    <div className="flex justify-center items-center">
      <div className="">
        <h2 className='text-4xl text-center text-secondary'>404 Error</h2>
        <h3 className='text-2xl text-center text-secondary_1'>Page Not Found</h3>
        <h3 className=' text-center text-secondary_1'>The requested page does not exists..!</h3>
      </div>
    </div>
  </div>
  )
}

export default PageNotFound