import { useEffect, useState } from 'react';
// import useSignalR from './useSignalR';

const SignalRTest = () => {
  const [messages, setMessages] = useState([]);
//   const connection = useSignalR('/signalrhub');

//   useEffect(() => {
//     if (connection) {
//       connection.start().then(() => {
//         console.log('SignalR connected!');
//       });

//       connection.on('ReceiveMessage', (message) => {
//         setMessages((prevMessages) => [...prevMessages, message]);
//       });
//     }
//   }, [connection]);

  return (
    <div>
      {messages.map((message, index) => (
        <div key={index}>{message}</div>
      ))}
    </div>
  );
};

export default SignalRTest;
