import React, { useState, useRef } from "react";
import { useEffect } from "react";
import "./SearchBar.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function SearchBar(props) {
  // const [filteredData, setFilteredData] = useState([]);
  const [UsersList, setUsersList] = useState([]);
  const [searchWord, setsearchWord] = useState("");
  const [enteredText, setenteredText] = useState(props.inputDefaultValue);
  const [showResults, setshowResults] = useState(false);

  const resultContainer = useRef(null);

  const handleFilter = (e) => {
    //   const searchWord = event.target.value;

    setenteredText(e.target.value);
    setsearchWord(e.target.value);
   // props.handler(props.ArrayLabel, e.target.value);
    props.handler(props.ArrayLabel, null);
    // const newFilter = data.filter((value) => {

    // return value.title.toLowerCase().includes(searchWord.toLowerCase());
  };

  


  useEffect(() => {
    if (searchWord) {
      try {
        fetch(process.env.REACT_APP_API + "users/" + "readspecificusers", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            SearchQuery: searchWord,
          }),
        })
          .then((Response) => Response.json())
          .then((data) => {
            console.log(data);
            console.log(searchWord);
            setUsersList(data);
            setshowResults(true);
          });
      } catch (error) {
        alert("error loading players");
      }
    } else {
      setUsersList([]);
      resetSearchComplete();
    }
  }, [searchWord]);

  const clearInput = () => {
    setUsersList([]);
    setenteredText("");
    resetSearchComplete();
    props.handler(props.ArrayLabel, null);
  };

  const resetSearchComplete = () =>{
    setfocusIndex(-1);
    setshowResults(false);
  };


  const HandleSelect = (index) => {
console.log(focusIndex)
    const selectedItem = UsersList[index];
    if(!selectedItem) return resetSearchComplete();
    console.log("UserID is " + selectedItem.UserID + " and FirstName is " + selectedItem.FirstName);
    props.SessionLabel && sessionStorage.setItem(props.SessionLabel, selectedItem.UserID);
    setenteredText(selectedItem.FirstName + " " + selectedItem.LastName);
    resetSearchComplete();
    props.handler(props.ArrayLabel, selectedItem.UserID);
  };

  const [focusIndex, setfocusIndex]=useState(-1);
const handleKeyDown=(e)=>{
  let nextIndexCount = 0;
  console.log(e.key)
  

  if (e.key==="ArrowDown"){
    console.log("e.key is arrow down")
    nextIndexCount= (focusIndex + 1) % UsersList?.length
  }
  if (e.key==="ArrowUp"){
    console.log("e.key is arrow up")
    nextIndexCount= (focusIndex + UsersList?.length - 1) % UsersList?.length
  }
  if (e.key==="Escape"){
    console.log("e.key is  escape")
  }
  if (e.key==="Enter"){
    console.log("e.key is enter")
    HandleSelect(focusIndex);
  }
  setfocusIndex(nextIndexCount);
};
 

useEffect(()=>{
  console.log(focusIndex);
  if (!resultContainer.current) return
  resultContainer.current.scrollIntoView({block : "center"});
},[focusIndex]);




  return (
    <div className="flex">
    <div className="search">
      <div className="searchInputs">
        <div className="inputDiv">
          <input
            type="text"
            placeholder="Search Player"
            value={enteredText}
            onChange={handleFilter}
            onBlur={()=>resetSearchComplete()}
            onKeyDown={handleKeyDown}
            required
            className="p-2 m-2 border-2 rounded-sm"
          />

          <div className="searchIcon">
            {enteredText?.length != 0 && (
              <p className="text-xs" id="clearBtn" onMouseDown={clearInput}>
                X
              </p>
              
            )}
          </div>
        </div>
      </div>
      {showResults && (
        <div className="dataResult">
          {UsersList.map((e, index) => {
            return (
              <ul
                className="dataItem border-b-2 px-2 py-4  text-black text-xl hover:bg-black hover:bg-opacity-10"
                key={e.UserID}
                onMouseDown={() => HandleSelect(index)}
                ref={index==focusIndex ? resultContainer : null}
                style={{backgroundColor : (index=== focusIndex)? "rgba(0,0,0,0.1)" : ""}} 
              >
                <li >
                  <div className="text-black text-sm">
                   {e.FirstName} {e.LastName}
                  </div>
                  <div className="text-slate-600 text-xs">{e.Email}</div>
                </li>
              </ul>
            );
          })}
        </div>
      )}
    </div>
    </div>
  );
}

export default SearchBar;
