import React, { useRef } from "react";


function CustomPointsSingle() {
  const point = useRef();
  const goldenpoint = useRef();
  const sets = useRef();

  const scheduleMatch = () => {
    return new Promise((resolve, reject) => {
      if (
        parseInt(point.current.value) > 0 &&
        parseInt(goldenpoint.current.value) > 0 &&
        parseInt(point.current.value) <= parseInt(goldenpoint.current.value)
      ) {
        // Store data in session storage
        sessionStorage.setItem("WinPoint", parseInt(point.current.value));
        sessionStorage.setItem(
          "GoldenPoint",
          parseInt(goldenpoint.current.value)
        );
        sessionStorage.setItem("NoOfSets", parseInt(sets.current.value));

        resolve(); // Data stored successfully
      } else if (
        parseInt(point.current.value) > 0 &&
        parseInt(goldenpoint.current.value) > 0 &&
        parseInt(point.current.value) >= parseInt(goldenpoint.current.value)
      ) {
        reject("'Golden Point' should be greater than 'Points to win'");
      } else {
        reject("Enter Valid Points");
      }
    })
      .then(() => {
        // Redirect to the schedule page
        window.location.href = "/ScheduleSingle";
      })
      .catch((error) => {
        alert(error);
      });
  };

  const SubmitHandler = (event) => {
    event.preventDefault();
    SaveGameDetails("tosssingle");
  };

  const SaveGameDetails = (toLocation) => {

    if (
      parseInt(point.current.value) > 0 &&
      parseInt(goldenpoint.current.value) > 0 &&
      parseInt(point.current.value) <= parseInt(goldenpoint.current.value)
    ) {
      fetch(process.env.REACT_APP_API + "game", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          TournamentID: "",
          GroupID: "",
          Round: "",
          SetNo: "",
          TossWinner: "", //(sessionStorage.getItem("tosswinner")),
          FirstServer: "", // (sessionStorage.getItem("server")),
          FirstReceiver: "", // (sessionStorage.getItem("receiver")),
          Date: null, //change date here
          T1P1: sessionStorage.getItem("team1p1"),
          T1P2: "",
          T2P1: sessionStorage.getItem("team2p1"),
          T2P2: "",
          Shuttles: "",
          Winpoint: parseInt(point.current.value),
          Goldenpoint: parseInt(goldenpoint.current.value),
          Master: sessionStorage.getItem("UserID"),
          NoOfSets: parseInt(sets.current.value),
          GameType: sessionStorage.getItem("GameType"),
          CreatedBy: sessionStorage.getItem("UserID"),
        }),
      })
        .then((res) => res.json())
        .then(
          (Result) => {
            sessionStorage.setItem("MatchID", Result[0].Column1);
            //  sessionStorage.setItem("gameID",Result[0].Column1);
            window.location.href = "/" + toLocation;
          },
          (error) => {
            alert("Failed");
          }
        );
    } else if (
      parseInt(point.current.value) > 0 &&
      parseInt(goldenpoint.current.value) > 0 &&
      parseInt(point.current.value) >= parseInt(goldenpoint.current.value)
    ) {
      alert("'Golden Point' should be greater than or equel to 'Points to win'");
    } else {
      alert("Enter Valid Points");
    }
  };

  return (
    <div>
      {/* <div className="">
        <div className="text-2xl text-center">Choose The Points</div>
        <div className="grid grid-cols-1 m-5">
          <div className=" justify-center items-center bg-slate-200 m-2 rounded-3xl ">
            <h2 className="text-xl p-5">Points to win</h2>
            <div className="text-center">
              <label></label>
              <input
                className="p-4 rounded-lg"
                type="number"
                placeholder="Enter Points"
                name="Winpoint"
                required
                ref={point}
              />
            </div>{" "}
            <h2 className="text-xl p-5">Golden Point</h2>
            <div className="text-center pb-5">
              <label></label>
              <input
                className="p-4 rounded-lg"
                type="number"
                placeholder="Enter golden Point"
                name="Goldenpoint"
                required
                ref={goldenpoint}
              />
            </div>
         
            <h2 className="text-xl p-5">Number of Sets</h2>
            <div className="text-center pb-5">
              <label></label>
              <select
                className="p-4 rounded-lg"
                type="number"
                name="uname2"
                required
                ref={sets}
              >
                <option value="3">3</option>
                <option value="1">1</option>
              </select>
            </div>
            <div
              className="text-3xl  flex justify-center items-center card2 bg-blue-700 hover:bg-blue-600 text-white m-8 rounded-3xl cursor-pointer"
              onClick={() => {
                // sessionStorage.setItem("winpoint", point.current.value);
                // sessionStorage.setItem("goldenpoint", goldenpoint.current.value);
                // sessionStorage.setItem("sets", sets.current.value);
                SaveGameDetails("tosssingle");

                // window.location.href = "/timer";
              }}
            >
              <h2>Go</h2>
            </div>
            <div
              className="text-3xl  flex justify-center items-center card2 bg-blue-700 hover:bg-blue-600 text-white m-8 rounded-3xl cursor-pointer"
              onClick={() => {
                SaveGameDetails("dashboard/matches");
                // window.location.href = "/userdash";
              }}
            >
              <h2>Save Game Details</h2>
            </div>
            <div
              className="text-3xl  flex justify-center items-center card2 bg-blue-700 hover:bg-blue-600 text-white m-8 rounded-3xl cursor-pointer"
              onClick={() => {
                // SaveGameDetails("schedulesingle");
                scheduleMatch();
                // window.location.href = "/userdash";
              }}
            >
              <h2>Schedule Game</h2>
            </div>
          </div>
        </div>

        <div className="button-container text-center ">
          <Link to="/selectplayerssingle">
            {" "}
            <button className="loginButton" type="submit">
              Back
            </button>
          </Link>
        </div>
      </div> */}














      <>
        <div className="bg-gray-100">
          <div className="min-h-screen w-full p-6 flex justify-center items-center">
            <div className="w-full max-w-md">
              <form onSubmit={SubmitHandler}>
                <div className="bg-white border p-8 shadow rounded w-full mb-6">
                  <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
                    Choose The Points
                  </h1>

                  <div>
                    <fieldset className="mb-4">
                      <label className="block text-sm text-gray-900 mb-2">
                        Points to win
                      </label>
                      <input
                        id="Winpoint"
                        type="number"
                        className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                        name="Winpoint"
                        placeholder="Enter Points"
                        required
                        ref={point}
                        autoFocus
                      />
                    </fieldset>

                    <fieldset className="mb-4">
                      <div className="w-full flex justify-between items-center">
                        <label
                          htmlFor="Golden Point"
                          className="block text-sm text-gray-900 mb-2"
                        >
                          Golden Point
                        </label>
                      </div>
                      <input
                        id="Golden Point"
                        className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                        type="number"
                        placeholder="Enter golden Point"
                        name="Goldenpoint"
                        required
                        ref={goldenpoint}
                      />
                    </fieldset>
                    <fieldset className="mb-4">
                      <div className="w-full flex justify-between items-center">
                        <label
                          htmlFor="Golden Point"
                          className="block text-sm text-gray-900 mb-2"
                        >
                          Number of Sets
                        </label>
                      </div>
                      <select
                        className="block rounded-sm border bg-white py-2 px-3 text-sm"
                        type="number"
                        name="sets"
                        required
                        ref={sets}
                      >
                        <option value="3">3</option>
                        <option value="1">1</option>
                      </select>
                    </fieldset>
                  </div>

                  <div className="flex flex-wrap items-center text-center gap-x-2">
                    <button
                      type="submit"
                      className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Next
                    </button>
                    <button
                    type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        SaveGameDetails("dashboard/matches");
                      }}
                      className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        scheduleMatch();
                      }}
                      className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Schedule
                    </button>
                    <button
                    type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        window.location.href = "/selectplayerssingle";
                      }}
                      className="inline-block bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default CustomPointsSingle;
