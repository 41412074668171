import React, { useState, useEffect } from "react";

const Android_Display = (props) => {
  const [index, setIndex] = useState("1");
  const [opacity, setOpacity] = useState("0");
  const [display, setdisplay] = useState("none");

  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  useEffect(() => {

    setOpacity("0");
    setdisplay("block");
    const timer = setTimeout(() => {
      setIndex(props.img_Index);
      setOpacity("1");
    
    }, 200);
    return () => clearTimeout(timer);
  }, [props.img_Index]);


  const increaseIndex = () => {
    props.img_Index>=10?
    props.setindex_fn(1):props.setindex_fn(props.img_Index+1);
  };

  const decreseIndex = () => {
    props.img_Index<=1?
    props.setindex_fn(10):props.setindex_fn(props.img_Index-1);
  };

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }



  function handleTouchEnd() {
    if (touchStart - touchEnd > 30) {
      // do your stuff here for left swipe
      increaseIndex();
    }

    if (touchStart - touchEnd < -30) {
      // do your stuff here for right swipe
      decreseIndex();
    }
    console.log(touchStart+" "+touchEnd)
    setTouchStart(0);
    setTouchEnd(0);
  }


  return (
    <div className="relative h-full justify-center items-center">
      <div className="h-full hidden sm:block mx-auto " style={{ width: "27rem", height: "775px" }}>
        <div
          className="relative mx-auto"
          style={{ width: "17.15rem", top: "6.6rem" }}

        >
          <img
            src={
              "./Images/FeaturesImages/feature_Image (" +
              index +
              ").jpg"
            }
            style={{ opacity: opacity, display:display }}
            className={`transition-all duration-1200   ${
              opacity === 0 ? "" : ""
            }`}
          />
        </div>
        <img
          className="absolute mx-auto top-0"
          src="./Images/Home_display.png"
          onTouchStart={(touchStartEvent) =>
            handleTouchStart(touchStartEvent)
          }
          onTouchMove={(touchMoveEvent) =>
            handleTouchMove(touchMoveEvent)
          }
          onTouchEnd={() => handleTouchEnd()}
        />
      </div>

      {/* for devices below 640px */}
      <div className="h-full sm:hidden mx-auto w-52" style={{ height: "360px" }}>
        <div
          className="relative mx-auto"
          style={{ width: "8.26rem", top: "3.16rem" }}

        >
          <img
            src={
              "./Images/FeaturesImages/feature_Image (" +
              index +
              ").jpg"
            }
            style={{ opacity: opacity, display:display }}
            className={`transition-all duration-1200   ${
              opacity === 0 ? "" : ""
            }`}
          />
        </div>
        <img
          className="absolute mx-auto w-52 top-0"
          src="./Images/Home_display.png"
          onTouchStart={(touchStartEvent) =>
            handleTouchStart(touchStartEvent)
          }
          onTouchMove={(touchMoveEvent) =>
            handleTouchMove(touchMoveEvent)
          }
          onTouchEnd={() => handleTouchEnd()}
        /> 
      </div>

    </div>
  );
};

export default Android_Display;
