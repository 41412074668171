import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

function EditUserDetails() {
  //   const [userInfo, setuserInfo] = useState([]);
  const [userData, setuserData] = useState();
  //  useEffect(()=>{
  //      setuserData(userInfo);
  // },[userInfo]);

  useEffect(() => {
    // fetch(process.env.REACT_APP_API + "users/"+"1000001014")  // change the value of userid
    // .then((Response) => Response.json())
    // .then((data) => {
    //     setuserData(data[0]);
    // });

    //  alert("done");

    fetch(process.env.REACT_APP_API + "users/" + "readusers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserID: sessionStorage.getItem("UserID"),
      }),
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0]+ 'thats it')
        setuserData(data[0]);
      });

    // .then((data) => {
    //        setuserData(data[0]);
    //    })
    //    .catch(err=>console.error(err));

    //    alert("Done");
  }, []);

  //   useEffect(()=>{
  //     fetch(process.env.REACT_APP_API + "users/"+"1000001016")
  //     .then((Response) => Response.json())
  //     .then((data) => {
  //         setuserInfo(data);
  //     });

  //     alert(userInfo.data);
  //   },[])

  const changeHandler = (e) => {
    // setuserData(userInfo)
    setuserData({ ...userData, [e.target.name]: e.target.value });
    // userData.length>0 && alert(userData[0].FirstName);
    // alert("length"+userData.length);
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
    console.log(userData);
    fetch(process.env.REACT_APP_API + "users", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then((result) => alert(result))
      .catch((err) => console.error(err));

    // alert(result)
    // window.location.href="/login"
  };

  return (
    <div>
      <div>
        {userData && (
          <>
            {/* <div className="">
              <h2 className="heading ">Edit Profile</h2>

              <h2 className="text-left text-xl text-gray-500 pb-5 pl-3">
                {userData.FirstName}
              </h2>

             
              <div className="form">
                <form onSubmit={SubmitHandler}>
                  <div className="input-container">
                    <label>User ID</label>
                    <input
                      type="text"
                      placeholder="Enter User ID"
                      onChange={changeHandler}
                      name="UserID"
                      value={userData.UserID}
                      required
                      disabled
                    />
                  </div>
                  <div className="input-container">
                    <label>First Name </label>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      onChange={changeHandler}
                      name="FirstName"
                      value={userData.FirstName}
                      required
                    />
                  </div>
                  <div className="input-container">
                    <label>Last Name </label>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      onChange={changeHandler}
                      name="LastName"
                      value={userData.LastName}
                      required
                    />
                  </div>

                  <div className="input-container">
                    <label>DOB</label>
                    <input
                      type="text"
                      placeholder="Enter Date of Birth"
                      onChange={changeHandler}
                      name="DOB"
                      value={userData.DOB}
                      required
                    />
                  </div>
                  <div className="input-container">
                    <label htmlFor="Gender">Gender </label>

                    <select
                      className="genderselect"
                      onChange={changeHandler}
                      name="Gender"
                      value={userData.Gender}
                      required
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  <div className="input-container">
                    <label>Mobile </label>
                    <input
                      type="text"
                      placeholder="Enter Mobile Number"
                      onChange={changeHandler}
                      name="Mobile"
                      value={userData.Mobile}
                      required
                    />
                  </div>

                  <div className="input-container pt-12">
                    <label>Email </label>
                    <input
                      type="text"
                      placeholder="Enter Email ID"
                      onChange={changeHandler}
                      name="Email"
                      value={userData.Email}
                      required
                      disabled
                    />
                  </div>

               

                  <div className="button-container py-4">
                    <Link to="/dashboard/profile">
                      <button className="loginButton mr-3">Back</button>
                    </Link>

                    <button className="loginButton" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div> */}

            <>
              <div className="bg-gray-100">
                <div className="min-h-screen w-full p-6 flex justify-center items-center">
                  <div className="w-full max-w-md">
                    <form onSubmit={SubmitHandler}>
                      <div className="bg-white border p-8 shadow rounded w-full mb-6">
                        <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
                          Edit Profile
                        </h1>

                        <h2 className="text-left text-base text-gray-500 pb-5">
                          {userData.FirstName + " " + userData.LastName}
                        </h2>

                        <div>
                          <fieldset className="mb-4">
                            <label className="block text-sm text-gray-900 mb-2">
                              User ID
                            </label>
                            <input
                              id="Winpoint"
                              type="text"
                              className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                              name="UserID"
                              placeholder="Enter User ID"
                              onChange={changeHandler}
                              value={userData.UserID}
                              required
                              disabled
                              // ref={point}
                            />
                          </fieldset>

                          <fieldset className="mb-4">
                            <div className="w-full flex justify-between items-center">
                              <label
                                htmlFor="Golden Point"
                                className="block text-sm text-gray-900 mb-2"
                              >
                                First Name
                              </label>
                            </div>
                            <input
                              className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                              type="text"
                              placeholder="Enter First Name"
                              onChange={changeHandler}
                              name="FirstName"
                              value={userData.FirstName}
                              required
                              // ref={goldenpoint}
                            />
                          </fieldset>
                          <fieldset className="mb-4">
                            <div className="w-full flex justify-between items-center">
                              <label
                                htmlFor="Golden Point"
                                className="block text-sm text-gray-900 mb-2"
                              >
                                Last Name
                              </label>
                            </div>
                            <input
                              className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                              type="text"
                              placeholder="Enter Last Name"
                              onChange={changeHandler}
                              name="LastName"
                              value={userData.LastName}
                              required
                              // ref={goldenpoint}
                            />
                          </fieldset>
                          <fieldset className="mb-4">
                            <div className="w-full flex justify-between items-center">
                              <label
                                htmlFor="Golden Point"
                                className="block text-sm text-gray-900 mb-2"
                              >
                                DOB
                              </label>
                            </div>
                            <input
                              className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                              type="date"
                              placeholder="Enter Date of Birth"
                              onChange={changeHandler}
                              name="DOB"
                              value={userData.DOB}
                              required
                              // ref={goldenpoint}
                            />
                          </fieldset>
                          <fieldset className="mb-4">
                            <div className="w-full flex justify-between items-center">
                              <label
                                htmlFor="Golden Point"
                                className="block text-sm text-gray-900 mb-2"
                              >
                                Gender
                              </label>
                            </div>
                            <select
                              className="block rounded-sm border bg-white py-2 px-3 text-sm"
                              onChange={changeHandler}
                              name="Gender"
                              value={userData.Gender}
                              required
                              //  ref={sets}
                            >
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </fieldset>
                          <fieldset className="mb-4">
                            <div className="w-full flex justify-between items-center">
                              <label
                                htmlFor="Golden Point"
                                className="block text-sm text-gray-900 mb-2"
                              >
                                Mobile
                              </label>
                            </div>
                            <input
                              className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                              type="text"
                              placeholder="Enter Mobile Number"
                              onChange={changeHandler}
                              name="Mobile"
                              value={userData.Mobile}
                              required
                              // ref={goldenpoint}
                            />
                          </fieldset>
                          <fieldset className="mb-4">
                            <div className="w-full flex justify-between items-center">
                              <label
                                htmlFor="Golden Point"
                                className="block text-sm text-gray-900 mb-2"
                              >
                                Email
                              </label>
                            </div>
                            <input
                              className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                              type="text"
                              placeholder="Enter Email ID"
                              onChange={changeHandler}
                              name="Email"
                              value={userData.Email}
                              required
                              disabled
                              // ref={goldenpoint}
                            />
                          </fieldset>
                        </div>

                        <div className="flex flex-wrap items-center text-center gap-x-2">
                          <button
                            type="submit"
                            className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                          >
                            Save
                          </button>

                          <button
                            type="button"
                            onClick={(event) => {
                              event.preventDefault();
                              window.location.href = "/dashboard/profile";
                            }}
                            className="inline-block bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          </>
        )}
      </div>
    </div>
  );
}

export default EditUserDetails;
