import React, { useState, useEffect } from "react";

function SummarySingle() {
  const [showScore, setShowScore] = useState([]);
  const [gameDetails, setgameDetails] = useState([]);
  const [gamesDetails, setgamesDetails] = useState([]);
  const [matchDetails, setmatchDetails] = useState([]);
  // ({
  //   GameID: "1000000004",
  //   TournamentID: "1",
  //   Round: "1",
  //   SetNo: "1",
  //   TossWinner: "1",
  //   FirstServer: "1",
  //   FirstReceiver: "1",
  //   Date: "11-11-2011",
  //   T1P1: "1",
  //   T1P2: "1",
  //   T2P1: "1",
  //   T2P2: "1",
  //   Winpoint: "1",
  //   Goldenpoint: "1",
  //   Master: "1",
  //   Winner: null
  // });

  const [PlayersDetails, setPlayersDetails] = useState({});

  const [team1p1disname, setteam1p1disname] = useState([]);
  //  const [team1p2disname, setteam1p2disname]= useState([]);
  const [team2p1disname, setteam2p1disname] = useState([]);
  //  const [team2p2disname, setteam2p2disname]= useState([]);

  // checking loading starts-------------------------------------------------------------------------------------------

  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded

  const [isloadedplayers, setisloadedplayers] = useState(false);
  const [isloadedgames, setisloadedgames] = useState(false);
  const [isloadedmatch, setisloadedmatch] = useState(false);
  const [isloadedgamedetails, setisloadedgamedetails] = useState(false);
  const [isloadedscore, setisloadedscore] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content, setting isloaded true
  useEffect(() => {
    if (isloadedplayers && isloadedgamedetails && isloadedmatch && isloadedscore) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedplayers, isloadedgamedetails, isloadedscore, isloadedmatch]); //add all the induvidual loading variables here

  useEffect(() => {
    try {
      fetch(process.env.REACT_APP_API + "match/" + "getplayers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setPlayersDetails(data[0]);
        })
        .then(() => {
          setisloadedplayers(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }

    // getgamesofmatch_API();

    try {
      fetch(process.env.REACT_APP_API + "match/getmatchdetails", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setmatchDetails(data[0]);
        })
        .then(() => {
          setisloadedmatch(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }
  }, []);

  const getgamesofmatch_API = () => {
    try {
      fetch(process.env.REACT_APP_API + "game/getgamesofmatch", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setgamesDetails(data);
        })
        .then(() => {
          setisloadedgames(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }
  };

  // checking loading ends-------------------------------------------------------------------------------------------

  useEffect(() => {
    if (isloadedplayers === true) {
      setteam1p1disname(PlayersDetails.T1P1Name);
      //   setteam1p2disname(PlayersDetails.T1P2Name);
      setteam2p1disname(PlayersDetails.T2P1Name);
      //  setteam2p2disname(PlayersDetails.T2P2Name);
    }
  }, [isloadedplayers]);

  const undoSummary = () => {
    alert("undo clicked");
    sessionStorage.setItem("summaryUndo", true);
    window.location.href = "/maininterfacesingle";
  };

  const nextGame = () => {
    window.location.href = "/maininterfacesingle";
  };

  useEffect(() => {
    // if (sessionStorage.getItem("score")) {
    //   setShowScore(JSON.parse(sessionStorage.getItem("score")));
    // }

    //get game details

    try {
      fetch(process.env.REACT_APP_API + "game/" + sessionStorage.getItem("gameID"))
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setgameDetails(data[0]);
        })
        .then(() => {
          setisloadedgamedetails(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }

  }, []);

  useEffect(() => {
    
    try {
      //get scoreboard
fetch(process.env.REACT_APP_API + "scoreboard/getscore", {
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    GameID: sessionStorage.getItem("gameID"),
  }),
})
    .then((Response) => {
      if (!Response.ok) {
        throw Error("Could not fetch data.");
      }
      return Response.json();
    })
    .then((data) => {
      console.log(data);
      setShowScore(data);
    })
    .then(() => {
      setisloadedscore(true);
    })
    .catch((err) => {
      console.error(err);
      setisloaded(true);
      setIsError(true);
    });
} catch (error) {
  setisloaded(true);
  setIsError(true);
}

  }, [gameDetails]);



  const [t1, sett1] = useState(0);
  const [t2, sett2] = useState(0);

  const [Winner, setWinner] = useState("Loading...");
  const [gameWinner, setgameWinner] = useState("Loading...");
  const [isWinnerUpdated, setisWinnerUpdated] = useState(false);

  const Matchwinner = () => {
    if (gameDetails.SetNo == matchDetails.NoOfSets) {
      if (matchDetails.GameType != "Single") {
        const TeamAScore = gamesDetails.filter((e) =>
          e.Winner
            ? e.Winner.includes(matchDetails.T1P1)
            : e.GameWinner.includes(matchDetails.T1P1)
        ).length;
        const TeamBScore = gamesDetails.filter((e) =>
          e.Winner
            ? e.Winner.includes(matchDetails.T2P1)
            : e.GameWinner.includes(matchDetails.T2P1)
        ).length;
        // const TeamAScore = gamesDetails.filter((e) => e.GameWinner.includes(matchDetails.T1P1) || e.GameWinner.includes(matchDetails.T1P1)).length;
        // const TeamBScore = gamesDetails.filter((e) => e.GameWinner.includes(matchDetails.T2P1) || e.Winner.includes(matchDetails.T2P1)).length;

        if (TeamAScore > TeamBScore) {
          return "W" + gameDetails.T1P1 + "W" + gameDetails.T1P2 + "W";
        } else {
          return "W" + gameDetails.T2P1 + "W" + gameDetails.T2P2 + "W";
        }
      }
      if (matchDetails.GameType == "Single") {
        const TeamAScore = gamesDetails.filter((e) =>
          e.Winner
            ? e.Winner.includes(matchDetails.T1P1)
            : e.GameWinner.includes(matchDetails.T1P1)
        ).length;
        const TeamBScore = gamesDetails.filter((e) =>
          e.Winner
            ? e.Winner.includes(matchDetails.T2P1)
            : e.GameWinner.includes(matchDetails.T2P1)
        ).length;
        // const TeamAScore = gamesDetails.filter((e) => e.GameWinner.includes(matchDetails.T1P1) || e.GameWinner.includes(matchDetails.T1P1)).length;
        // const TeamBScore = gamesDetails.filter((e) => e.GameWinner.includes(matchDetails.T2P1) || e.Winner.includes(matchDetails.T2P1)).length;

        if (TeamAScore > TeamBScore) {
          return "W" + gameDetails.T1P1 + "W";
        } else {
          return "W" + gameDetails.T2P1 + "W";
        }
      }
    }
    return null;
  };

  useEffect(() => {
    sett1(showScore.filter((e) => e.TeamTag === "A").length);
    sett2(showScore.filter((e) => e.TeamTag === "B").length);

    if (gameDetails.Winner === null && gameDetails.GameWinner === null) {
      alert("winner in db doesnt exist");

      if (t1 > t2) {
        setWinner(team1p1disname);
        setgameWinner("G" + gameDetails.T1P1 + "G");
      } else if (t1 < t2) {
        setWinner(team2p1disname);
        setgameWinner("G" + gameDetails.T2P1 + "G");
      }
    } else if (gameDetails.Winner != null) {
      alert("winner in db exists" + gameDetails.Winner);

      setWinner(
        gameDetails.Winner.includes(gameDetails.T1P1)
          ? team1p1disname
          : team2p1disname
      );
      setgameWinner(gameDetails.Winner);
    } else if (gameDetails.GameWinner != null) {
      alert("gamewinner in db exists" + gameDetails.GameWinner);

      setWinner(
        gameDetails.GameWinner.includes(gameDetails.T1P1)
          ? team1p1disname
          : team2p1disname
      );
      setgameWinner(gameDetails.GameWinner);
    }

    // if (t1 > t2) {
    //   setgameWinner("Team 1");
    // } else {
    //   setgameWinner("Team 2");
    // }

    console.log(gameDetails);
  }, [showScore]);

  useEffect(() => {
    if (
      gameDetails.Winner === null &&
      gameDetails.GameWinner === null &&
      (t1 !== 0 || t2 !== 0)
    ) {
      // alert('Winner='+gameDetails.Winner+' GameWinner= '+gameDetails.GameWinner+'   t1='+t1+'   t2='+t2)
      fetch(process.env.REACT_APP_API + "game/updategamewinner", {
        method: "PUT",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          GameID: sessionStorage.getItem("gameID"),

          GameWinner: gameWinner,
        }),
      })
        .then((res) => res.json())
        .then(
          async (Result) => {
            // sessionStorage.setItem("gameID",Result[0].Column1);
            alert(Result);
            // window.location.href = "/"+toLocation
            await new Promise(resolve=>{
              //  getgamesofmatch_API();
               resolve();
              }) 
              setisWinnerUpdated(true);
          },
          (error) => {
            alert("Failed");
          }
        );
    }
  }, [gameWinner]);

  useEffect(()=>{
    if (gameDetails.SetNo == matchDetails.NoOfSets && isWinnerUpdated===true ) {
      getgamesofmatch_API();
      // updateMatchWinnerAPI();
    }
  },[isWinnerUpdated])

  useEffect(()=>{
    if (gameDetails.SetNo == matchDetails.NoOfSets && isloadedgames===true ) {
      // getgamesofmatch_API();
       updateMatchWinnerAPI();
    }
  },[isloadedgames])

  const updateMatchWinnerAPI = () => {
    fetch(process.env.REACT_APP_API + "match/updatematchwinner", {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        MatchID: sessionStorage.getItem("MatchID"),

        Winner: Matchwinner(),
      }),
    })
      .then((res) => res.json())
      .then(
        (Result) => {
          // sessionStorage.setItem("gameID",Result[0].Column1);
          alert(Result);
          // window.location.href = "/"+toLocation
        },
        (error) => {
          alert("Failed");
        }
      );
  };

  const getUsername = (e) => {
    if (e == gameDetails.T1P1) {
      return team1p1disname;
    } else if (e == gameDetails.T1P2) {
      //   return(team1p2disname);
    } else if (e == gameDetails.T2P1) {
      return team2p1disname;
    } else if (e == gameDetails.T2P2) {
      //  return(team2p2disname);
    } else {
      return e;
    }

    //return("the username is "+ e +"...")
  };

  //loading message
  if (!isloaded)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
        </div>
      </div>
    );
  //error message
  if (IsError)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="">
            <h2>Oops! Something Went Wrong...</h2>
          </div>
        </div>
      </div>
    );

  //default component
  if (isloaded && !IsError) {
    return (
      <div className="bg-gray-50 min-h-screen">
        {showScore && ( // comment it
          <div>
            <div className="p-4">
              <div className="bg-white p-4 rounded-md">
                <div>
                  <h2 className="mb-4 text-xl font-bold text-gray-700">
                    ScoreBoard
                  </h2>

                  <div className="ml-8 mt-5 text-right absolute left-0">
                    <button className="" onClick={undoSummary}>
                      Back
                    </button>
                  </div>
                  <div className="mr-8 mt-5 text-left absolute right-0">
                    <button className="px-14" onClick={nextGame}>
                      {matchDetails.NoOfSets == gameDetails.SetNo || matchDetails.Winner!=null
                        ? "View Match Summary"
                        : "Play Next Game"}
                    </button>
                  </div>

                  <div>
                    <h2 className="text-2xl font-bold text-gray-600">{`${
                      gameDetails.Winner === null
                        ? ""
                        : "The game is manually ended by referee"
                    }`}</h2>
                    <h2 className="text-2xl font-bold text-gray-600">
                      Set Number {gameDetails.SetNo}
                    </h2>
                    <h2 className="text-2xl font-bold text-gray-600">
                      {Winner} won the game
                    </h2>
                    <h2 className="text-4xl font-normal text-gray-700">
                      {t1} : {t2}
                    </h2>

                    <table className="w-full ">
                      <thead>
                        <tr className="text-md font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600 ">
                          <th className="px-4 py-3">Index</th>
                          <th className="px-4 py-3">Point</th>

                          <th className="px-4 py-3">TeamTag</th>
                          <th className="px-4 py-3">Last Contact Player</th>
                          <th className="px-4 py-3">FH/BH</th>
                          <th className="px-4 py-3">Shot</th>
                          <th className="px-4 py-3">Position</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {showScore.map((e, i) => (
                          <tr className="text-gray-700 hover:bg-gray-100  ">
                            <td className="px-4 py-3 border">
                              <div className="flex items-center text-sm">
                                <div>
                                  <p className="font-semibold text-black">
                                    {i + 1}
                                    {/* {e.index} */}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 border">
                              <div className="flex items-center text-sm">
                                <div>
                                  <p className="font-semibold text-black">
                                    {e.Point}
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td className="px-4 py-3 border">
                              <div className="flex items-center text-sm">
                                <div>
                                  <p className="font-semibold text-black">
                                    {e.TeamTag}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 border">
                              <div className="flex items-center text-sm">
                                <div>
                                  <p className="font-semibold text-black">
                                    {getUsername(e.LastContactPlayer)}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 border">
                              <div className="flex items-center text-sm">
                                <div>
                                  <p className="font-semibold text-black">
                                    {e.fh ? "FH" : "BH"}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 border">
                              <div className="flex items-center text-sm">
                                <div>
                                  <p className="font-semibold text-black">
                                    {e.TypeofShot}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 border">
                              <div className="flex items-center text-sm">
                                <div>
                                  <p className="font-semibold text-black">
                                    {e.Position}
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen text-xl">
      <div className="flex justify-center items-center">
        <div className="">
          <h2 className="text-2xl text-center">
            Oops! An Unexpected Error Occured.
          </h2>
          <h3 className="text-base text-center">Try reloading the page.</h3>
        </div>
      </div>
    </div>
  );
}

export default SummarySingle;
