
function UserRoleDisplay(e) {
  switch (e) {
    case "1":
      return "IT Admin";
    case "2":
      return "Academy Admin";
    case "3":
      return "Tournament Admin";
    case "4":
      return "Free User";
    case "5":
      return "Premium User";

    default:
      throw new Error(`Unknown Role type: ${e}`);
  }
 
}

export default UserRoleDisplay;
