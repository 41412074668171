import React, { useEffect, useState } from 'react'

function AsReferee() {

    const [matchList, setmatchList] = useState([]);
    const [playedmatchList, setPlayedmatchList] = useState([]);

useEffect(()=>{
//get game list
fetch(process.env.REACT_APP_API + "match/getmatchesofreferee", {
    method: "POST",
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    body:JSON.stringify({
      
      "Referee": (sessionStorage.getItem("UserID"))  //change this to userid of the user
    })
  })
    .then((Response) => Response.json())
    .then((data) => {
     // alert(data[0].TeamTag+ 'thats it')
    setmatchList(data);
  });
console.log("game list loaded");


//get game list
fetch(process.env.REACT_APP_API + "match/getplayedmatchesofreferee", {
    method: "POST",
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    body:JSON.stringify({
      
      "Referee": (sessionStorage.getItem("UserID"))  //change this to userid of the user
    })
  })
    .then((Response) => Response.json())
    .then((data) => {
     // alert(data[0].TeamTag+ 'thats it')
     setPlayedmatchList(data);
  });
console.log("played game list loaded");
},[]);





  return (
    <div className="bg-gray-50 min-h-screen">









      {/* {sessionStorage.getItem("score") && showScore && (   // comment it */}
      <div>
        <div className="p-4">
          <div className="bg-white p-4 rounded-md">
            <div>
              <h2 className="mb-4 text-xl font-bold text-gray-700">
                Assigned As Referee
              </h2>
              <div>
                <h2 className="text-xl font-normal text-gray-700"></h2>

            

                <div className="homeLayout">
                
                  <h2 className="heading pt-3">Yet to be played</h2>
                  {matchList.length>0 ? (<></>) : (<h4 className='text-center'>No Games Assigned</h4>)}
                  <div className="grid grid-cols-3">
                    {matchList.map((e, i) => (


                      <div key={e.MatchID} className="scorecard mx-2">
                        <h6 className="date">{e.Date}</h6>
                        <h4 className="grid grid-flow-col-2 text-xl m-4">
                         
                        </h4>
                        <div className="flex flex-col justify-center items-center">
                          <div className="flex   ">
                          {e.T1P1FirstName} {e.GameType!='Single' ? ("/ "+e.T1P2FirstName) : ""}
                            <p className="ml-8 font-bold "> v/s </p>
                            {e.T2P1FirstName} {e.GameType!='Single' ? ("/ "+e.T2P2FirstName) : ""}
                          </div>
                        </div>
                        <h4 className="grid grid-flow-col-2 text-xl m-4">
                         {e.MatchID}
                        </h4>
                        <p className="mt-4"> </p>
                        <button className="px-7" onClick={()=>{

                            sessionStorage.setItem("MatchID", e.MatchID);
                            (e.GameType==='Single' ? window.location.href = "/updateplayerssingle" : window.location.href = "/updateplayers" ) ;
                           

                        }                     
                           
                            
                        }
                        >Start</button>
                      </div>



                    ))}

                   

                    
                  </div>
                  
                </div>


                
                <div className="homeLayout">
                  <h2 className="heading pt-3">Played</h2>
                  {playedmatchList.length>0 ? (<></>) : (<h4 className='text-center'>No Games Yet</h4>)}
                  <div className="grid grid-cols-3">
                    {playedmatchList.map((e, i) => (


                      <div key={e.MatchID} className="scorecard mx-2">
                        <h6 className="date">{e.Date}</h6>
                        <h4 className="grid grid-flow-col-2 text-xl m-4">
                         
                        </h4>
                        <div className="flex flex-col justify-center items-center">
                          <div className="flex   ">
                          {e.T1P1FirstName} {e.GameType!='Single' ? ("/ "+e.T1P2FirstName) : ""}
                            <p className="ml-8 font-bold "> v/s </p>
                            {e.T2P1FirstName} {e.GameType!='Single' ? ("/ "+e.T2P2FirstName) : ""}
                          </div>
                        </div>
                        <h4 className="grid grid-flow-col-2 text-xl m-4">
                         {e.MatchID}
                        </h4>
                        <p className="mt-4"> </p>
                        <button className="px-7" onClick={()=>{
                            sessionStorage.setItem("MatchID", e.MatchID);
                            e.GameType='Single' ? window.location.href = "/maininterfacesingle" : window.location.href = "/maininterface" ;
                          

                        }                     
                           
                            
                        }
                        >Summary</button>
                      </div>



                    ))}

                   

                    
                  </div>
                  
                </div>

              </div>
            </div>
          </div>
        </div>


       




      </div>
      {/*   )} */}
    </div>
  )
}

export default AsReferee