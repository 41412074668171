import { isError } from "lodash";
import React, { useEffect, useState } from "react";

function Live() {

    const [liveGames, setliveGames] = useState({});
//const for checking the whole content loaded
const [isloaded, setisloaded] = useState(false);

//const for checking individual contents loaded

const [isloadedLive, setisloadedLive] = useState(false);

//const for checking any error caught
const [IsError, setIsError] = useState(false);

//after successfully loading each content setting isloaded true
useEffect(() => {
  if (isloadedLive) {
    //add all the induvidual loading variables here
    setisloaded(true);
    console.log(isloadedLive);
    console.log(IsError);
  }
}, [isloadedLive]); //add all the induvidual loading variables here


const updatelivegames = ()=>{
    console.log("API call triggered");
    try {
      fetch(process.env.REACT_APP_API + "game/getlivegamesforpublic", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          console.log(Array.isArray(data));
          setliveGames(data);
        })
        .then(() => {
            setisloadedLive(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }
  }

  
  const [isFocused, setisFocused]=useState(false);

  useEffect(()=>{
    console.log("isFocused changed");
    if (isFocused==true){
    console.log("Tab is in focus");
    updatelivegames();
    
    const intervalId_live = setInterval(() => updatelivegames(),15000);
   
    return () => {
      clearInterval(intervalId_live);
      console.log("Tab is blurred");
    };
    }
  },[isFocused]);

//check the tab is in focus and set 'isFocused' according to it
useEffect(() => {
    console.log("page loaded")
      window.addEventListener("focus", ()=>setisFocused(true));
      window.addEventListener("blur", ()=>setisFocused(false));
      // set 'isFocused' true when the window first loads
      setisFocused(true);
      // Specify how to clean up after this effect:
      return () => {
          window.removeEventListener("focus", ()=>setisFocused(true));
          window.removeEventListener("blur", ()=>setisFocused(false));
      };
}, []);


  //loading message
  if (!isloaded)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
        </div>
      </div>
     
    );
  //error message
  if (IsError)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="">
            <h2>Oops! Something Went Wrong...</h2>
          </div>
        </div>
      </div>
    );
  //default component
  if (isloaded && !IsError) {
    return (
<>
<div className="DashboardMain bg-gray_light_1">
          <h2 className="heading  pt-3">Live Games</h2>
          <h2 className="text-Tertiary">{liveGames.length > 0 ? "" : "No Live Games"}</h2>
          <div className="grid md:grid-cols-3  text-Tertiary">
            {Array.isArray(liveGames) && liveGames.map((e, i) => (
              <div key={e.MatchID} className="scorecard mx-2">
                <h6 className="date">{e.StartedTime}</h6>
                <h4 className="grid grid-flow-col-2 text-xl m-4"></h4>
                <h4 className="grid grid-flow-col-2 text-xl m-2">
                  Match ID: {e.MatchID}
                </h4>
                <h4 className="grid grid-flow-col-2 text-lg m-2">
                  Set Number: {e.SetNo}
                </h4>
                <div className="flex flex-col justify-center items-center">
                  <div className="flex   ">
                    <p>
                      {e.T1P1FirstName}{" "}
                      {e.GameType != "Single" ? "/ " + e.T1P2FirstName : ""}
                    </p>
                    <p className="ml-8 font-bold "> v/s </p>
                    <p className="ml-8">
                      {e.T2P1FirstName}{" "}
                      {e.GameType != "Single" ? "/ " + e.T2P2FirstName : ""}
                    </p>
                  </div>
                </div>
                <h4 className="grid grid-flow-col-2 text-xl m-4">
                  
                </h4>
                <p className="mt-4"> </p>
                <h4 className="grid grid-flow-col-2 text-2xl m-4">
              {e.t1Score} : {e.t2Score}
            </h4>
                {/* <button
                  className="px-7"
                  onClick={() => {
                    // sessionStorage.setItem("MatchID", e.MatchID);
                    // e.GameType=='Single' ? window.location.href = "/maininterfacesingle" : window.location.href = "/maininterface" ;
                  }}
                >
                  View Score
                </button> */}
              </div>
            ))}
          </div>
        </div>
</>
 );
  }

  return (
    <div className="flex justify-center items-center h-screen text-xl">
      <div className="flex justify-center items-center">
        <div className="text-secondary">
          <h2 className="text-2xl text-center">
            Oops! An Unexpected Error Occured.
          </h2>
          <h3 className="text-base text-center">Try reloading the page.</h3>
        </div>
      </div>
    </div>
  );
  
}

export default Live;
