import React, { useState, useEffect } from "react";

function T2P1(props) {

 // let PlayerName=(sessionStorage.getItem("team2p1"));

    if (props.server === "T2P1") { // change to props and take the value
        return (
          <div>
            <div className="text-red-700">{props.playername}</div>
            <div className="absolute left-[66%]">
              <h2>Serving</h2>
            </div>
          </div>
        );
      } else if (props.receiver === "T2P1") { // change to props and take the value
        return (
          <div>
            <div className="text-black">{props.playername}</div>
            <div className="absolute left-[66%]">
              <h2>Receiving</h2>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="text-white">{props.playername}</div>
          </div>
        );
      }
}

export default T2P1