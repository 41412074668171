import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

function NotificationFeed(props) {
  const Redux_FirstName = useSelector((state) => state.userinfo.FirstName);
  const Redux_LastName = useSelector((state) => state.userinfo.LastName);
  const Redux_UserID = useSelector((state) => state.userinfo.UserID);
  const Redux_UserType = useSelector((state) => state.userinfo.UserType);
  const Redux_UserRole = useSelector((state) => state.userinfo.UserRole);

  const [notifications, setnotifications] = useState({});
  const [showNotifModal, setshowNotifModal] = useState(false);

  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedNotif, setisloadedNotif] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedNotif) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedNotif]); //add all the induvidual loading variables here

  console.log(Redux_UserID);

  useEffect(() => {
    updateNotifications();
  }, []);

  const updateNotifications = () => {
    try {
      fetch(process.env.REACT_APP_API + "notifications/" + "getnotifications", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ForUserID: Redux_UserID, //sessionStorage.getItem("UserID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          console.log(Array.isArray(data));
          setnotifications(data);
        })
        .then(() => {
          setisloadedNotif(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }
  };

  const [NotifID, setNotifID] = useState("");
  const [NotifTitle, setNotifTitle] = useState("");
  const [NotifContent, setNotifContent] = useState("");
  const [CreatedTime, setCreatedTime] = useState("");
  const [MatchID, setMatchID] = useState("");
  const [GameType, setGameType] = useState("");

  async function ShowModal(
    NotifID,
    NotifTitle,
    NotifContent,
    CreatedTime,
    MatchID,
    GameType
  ) {
    await new Promise((resolve) => {
      setNotifID(NotifID);
      setNotifTitle(NotifTitle);
      setNotifContent(NotifContent);
      setCreatedTime(CreatedTime);
      setMatchID(MatchID);
      setGameType(GameType);

      resolve();
    });
    console.log(GameType);
    setshowNotifModal(true);
  }

  const UpdateIsRead = (NotificationID) => {
    console.log("isread works");

    try {
      fetch(process.env.REACT_APP_API + "notifications/" + "updateisread", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          NotifID: NotificationID,
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not update data");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
        })
        .then(() => {
          props.NoitfCountFn();
          updateNotifications();
        })

        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  //loading message
  if (!isloaded)
    return (
      <div className="flex justify-center items-center min-h-full text-xl">
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full border-primary"></div>
        </div>
      </div>
    );
  //error message
  if (IsError)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="">
            <h2>Oops! Something Went Wrong...</h2>
          </div>
        </div>
      </div>
    );

  //default component
  if (isloaded && !IsError) {
    return (
      <>
        {/* //code for notification modal-------------------------------------------------------------------------------- */}

        {showNotifModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-2xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-lg font-semibold">Notification</h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-gray-700  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setshowNotifModal(false)}
                    >
                      <span className="bg-transparent h-6 w-6 text-2xl text-slate-400 block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>

                  <div className="relative p-6 flex-auto">
                    <div className="flex justify-between items-center">
                      <p className="my-2 text-slate-600 text-base leading-relaxed">
                        {NotifTitle}
                      </p>
                      <h6 className="date">
                        {CreatedTime ? moment(CreatedTime).fromNow() : "N/A"}
                      </h6>
                    </div>

                    <p className="my-2 text-slate-600 text-sm leading-relaxed">
                      Match ID - {MatchID}
                    </p>
                    <p className="my-2 text-slate-600 text-sm leading-relaxed">
                      {NotifContent}
                    </p>
                    <p className="my-2 text-slate-600 text-sm leading-relaxed">
                      Date - {moment(CreatedTime).format("DD-MM-YYYY hh:mm a")}
                    </p>

                    <div className="flex">
                      <div
                        onClick={() => {
                          sessionStorage.setItem("MatchID", MatchID);
                          GameType == "Single"
                            ? (window.location.href = "/updateplayerssingle")
                            : (window.location.href = "/updateplayers");
                        }}
                        className={`${"bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg cursor-pointer"}`}
                      >
                        Start Now
                      </div>
                    </div>

                    {/* <form onSubmit={copyHandler}>
                 
                    <div >
                    <div>Team A</div>
                      <label>{}</label>
                  <input type="checkbox" value={matchdetails.T1P1} name="t1p1" onChange={copyChangeHandler}  />
                  {" "}
                      <label>{}</label>
                  <input type="checkbox" value={} name="t1p2" onChange={copyChangeHandler}  />
                  <div>Team B</div>
                      <label>{matchdetails.T2P1FirstName}</label>
                  <input type="checkbox" value={matchdetails.T2P1} name="t2p1" onChange={copyChangeHandler}  />
                  {" "}
                      <label>{matchdetails.T2P2FirstName}</label>
                  <input type="checkbox" value={matchdetails.T2P2} name="t2p2" onChange={copyChangeHandler}  />

                    </div>
                    

                    <div className="input-container flex">
                    
                    <label>Date {matchdetails.Date}</label>
                      <input
                        type="checkbox"
                        placeholder="Enter Win Point"
                        onChange={copyChangeHandler}
                        name="date"
                        value={matchdetails.Date}
                        required
                      />
                      
                          
                    
                    </div>
                    <div className="input-container">
                    <label>Number of Copies</label>
                      <input
                        type="number"
                        placeholder="No. of copies"
                        onChange={copyChangeHandler}
                        name="CopyCount"
                        min="1"
                        required
                      />
                    
                    </div>
                    

                    

                    

                    <div className="button-container py-4">
                      
                        <button className="loginButton mr-3"
                        onClick={() => setshowNotifModal(false)}>Close</button>
                      

                      <button className="loginButton" 
                      type="submit"
                       //onClick={() => setshowEditModal(false)}
                       >
                        Submit
                      </button>
                    </div>
                  </form> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}

        {/* //end of code for notification modal-------------------------------------------------------------------------------- */}

        <div>
          <h2 className="my-6 text-2xl text-left font-semibold text-gray-700 dark:text-gray-200">
            Notifications
          </h2>
          {notifications.length ? (
            ""
          ) : (
            <p className="focus:outline-none text-sm flex flex-shrink-0 leading-normal px-3 py-16 text-gray-500">
              "No Notifications"
            </p>
          )}

          <div className=" h-screen overflow-y-auto  ">
            {notifications
              .slice(0)
              .reverse()
              .map((e, i) => (
                <div
                  key={e.NotifID}
                  className="w-full p-3 mt-4 bg-white rounded shadow flex flex-shrink-0 relative dark:bg-slate-800"
                >
                  {!e.IsRead && (
                    <span
                      className="absolute inset-y-0 left-0 w-1 bg-primary rounded-tr-lg rounded-br-lg"
                      aria-hidden="true"
                    ></span>
                  )}
                  <div
                    tabIndex="0"
                    aria-label="post icon"
                    role="img"
                    className="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex items-center justify-center"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.30325 12.6667L1.33325 15V2.66667C1.33325 2.48986 1.40349 2.32029 1.52851 2.19526C1.65354 2.07024 1.82311 2 1.99992 2H13.9999C14.1767 2 14.3463 2.07024 14.4713 2.19526C14.5963 2.32029 14.6666 2.48986 14.6666 2.66667V12C14.6666 12.1768 14.5963 12.3464 14.4713 12.4714C14.3463 12.5964 14.1767 12.6667 13.9999 12.6667H4.30325ZM5.33325 6.66667V8H10.6666V6.66667H5.33325Z"
                        fill="#4338CA"
                      />
                    </svg>
                  </div>
                  <div className="pl-3 w-full">
                    <div className="flex items-center justify-between w-full">
                      <div>
                        <p
                          tabIndex="0"
                          className="focus:outline-none text-sm leading-none font-medium dark:text-slate-300"
                        >
                          {e.NotifTitle}
                        </p>
                        <p
                          tabIndex="0"
                          className="focus:outline-none text-xs leading-none py-1 dark:text-slate-400"
                        >
                          {e.NotifContent}
                        </p>
                        <p
                          tabIndex="0"
                          className="focus:outline-none text-xs leading-3 pt-1 text-gray-500"
                        >
                          {moment(e.CreatedTime).fromNow()}
                        </p>
                      </div>
                      <p
                        tabIndex="0"
                        onClick={() => {
                          ShowModal(
                            e.NotifID,
                            e.NotifTitle,
                            e.NotifContent,
                            e.CreatedTime,
                            e.MatchID,
                            e.GameType
                          );
                          UpdateIsRead(e.NotifID);
                          console.log(e.IsRead === true && "tttttttt");
                        }}
                        className="focus:outline-none text-xs leading-3 cursor-pointer text-right bg-primary p-2 rounded-full text-white "
                      >
                        View
                      </p>
                    </div>
                  </div>
                </div>
              ))}

            {/* <h2
          tabIndex="0"
          className="focus:outline-none text-sm leading-normal pt-8 border-b pb-2 border-gray-300 text-gray-600"
        >
          YESTERDAY
        </h2> */}

            <div className="flex items-center justiyf-between">
              <hr className="w-full" />
              <p
                tabIndex="0"
                className="focus:outline-none text-sm flex flex-shrink-0 leading-normal px-3 py-16 text-gray-500"
              >
                Thats it for now
              </p>
              <hr className="w-full" />
            </div>
          </div>

          {/* <script>let notification = document.getElementById("notification");
let checdiv = document.getElementById("chec-div");
let flag3 = false;
const notificationHandler = () => {
  if (!flag3) {
    notification.classList.add("translate-x-full");
    notification.classList.remove("translate-x-0");
    setTimeout(function () {
      checdiv.classList.add("hidden");
    }, 1000);
    flag3 = true;
  } else {
    setTimeout(function () {
      notification.classList.remove("translate-x-full");
      notification.classList.add("translate-x-0");
    }, 1000);
    checdiv.classList.remove("hidden");
    flag3 = false;
  }
};
</script> */}
        </div>
      </>
    );
  }
  return (
    <div className="flex justify-center items-center h-screen text-xl">
      <div className="flex justify-center items-center">
        <div className="">
          <h2 className="text-2xl text-center">
            Oops! An Unexpected Error Occured.
          </h2>
          <h3 className="text-base text-center">Try reloading the page.</h3>
        </div>
      </div>
    </div>
  );
}

export default NotificationFeed;
