import React, { useState, useEffect } from "react";
import SearchBar from "../SearchBar/SearchBar";
import { Link } from "react-router-dom";
import GoBackButton from "../Layout/GoBackButton";

function UpdatePlayersSingle() {
  const [matchDetails, setmatchDetails] = useState({});
  const [gameDetails, setgameDetails] = useState({});
  const [PlayersDetails, setPlayersDetails] = useState({});

  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedmatch, setisloadedmatch] = useState(false);

  const [isloadedplayers, setisloadedplayers] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedmatch && isloadedplayers) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedmatch, isloadedplayers]); //add all the induvidual loading variables here

  useEffect(() => {
    try {
      fetch(process.env.REACT_APP_API + "match/getmatchdetails", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setmatchDetails(data[0]);
        })
        .then(() => {
          setisloadedmatch(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }

    try {
      fetch(process.env.REACT_APP_API + "match/getplayers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setPlayersDetails(data[0]);
        })
        .then(() => {
          setisloadedplayers(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }
  }, []);

  const infoChangeHandler = (datalabel, datavalue) => {
    // setInfo({...info,[e.target.name] : e.target.value});
    // console.log(e);
    setPlayersDetails({ ...PlayersDetails, [datalabel]: datavalue });
    console.log(datalabel);
    console.log(datavalue);
  };
  const SubmitPlayers = () => {
    if (PlayersDetails.T1P1ID && PlayersDetails.T2P1ID) {
      sessionStorage.setItem("team1p1", PlayersDetails.T1P1ID);
      sessionStorage.setItem("team2p1", PlayersDetails.T2P1ID);

      window.location.href = "/updatepointssingle";
    } else {
      alert("Select all Players.");
    }
  };
  //loading message
  if (!isloaded)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
        </div>
      </div>
    );
  //error message
  if (IsError)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="">
            <h2>Oops! Something Went Wrong...</h2>
          </div>
        </div>
      </div>
    );
  //default component
  if (isloaded && !IsError) {
    return (
      <div>
        {/* <div className="">
          <div className="text-2xl text-center p-4">Select Players</div>

          <div className="grid grid-cols-2 m-5">
            <div className=" justify-center items-center bg-slate-200 mx-2 rounded-3xl">
              <h2 className="text-3xl p-5">Team 1</h2>
              <label>Player 1</label>
              <SearchBar
                SessionLabel={"team1p1"}
                ArrayLabel={"T1P1ID"}
                inputDefaultValue={
                  PlayersDetails.T1P1Name + " " + PlayersDetails.T1P1LastName
                }
                handler={infoChangeHandler}
              />

             

              <br />
            </div>

            <div className=" justify-center items-center bg-slate-200 mx-2 rounded-3xl">
              <h2 className="text-3xl p-5">Team 2</h2>
              <label>Player 1</label>
              <SearchBar
                SessionLabel={"team2p1"}
                ArrayLabel={"T2P1ID"}
                inputDefaultValue={
                  PlayersDetails.T2P1Name + " " + PlayersDetails.T2P1LastName
                }
                handler={infoChangeHandler}
              />

              <br />
            </div>
          </div>

          <div
            className="text-3xl  flex justify-center items-center card2 bg-blue-700 hover:bg-blue-600 text-white m-8 rounded-3xl cursor-pointer"
            onClick={() => {
              sessionStorage.setItem("team1p1", PlayersDetails.T1P1ID);
              //  sessionStorage.setItem("team1p2", PlayersDetails.T1P2ID);
              sessionStorage.setItem("team2p1", PlayersDetails.T2P1ID);
              //  sessionStorage.setItem("team2p2", PlayersDetails.T2P2ID);

              window.location.href = "/updatepointssingle";
            }}
          >
            <h2>Go</h2>
          </div>

          <div className="button-container text-center ">
            <Link to="/dashboard/referee">
              {" "}
              <button className="loginButton" type="submit">
                Cancel
              </button>
            </Link>
          </div>
        </div> */}

        {/* 
           
         <div>  T1P1ID is {PlayersDetails.T1P1ID}</div>
         <div>  T1P1Name is {PlayersDetails.T1P1Name}</div>

         <div>  T1P2ID is {PlayersDetails.T1P2ID}</div>
         <div>  T1P2Name is {PlayersDetails.T1P2Name}</div>

         <div>  T2P1ID is {PlayersDetails.T2P1ID}</div>
         <div>  T2P1Name is {PlayersDetails.T2P1Name}</div>

         <div>  T2P2ID is {PlayersDetails.T2P2ID}</div>
         <div>  T2P2Name is {PlayersDetails.T2P2Name}</div>
            
            
            
    
            <SearchBar SessionLabel={"team1p1"} ArrayLabel={"T1P1ID"} inputDefaultValue={PlayersDetails.T1P1Name+" "+PlayersDetails.T1P1LastName} handler={infoChangeHandler} />
            <SearchBar SessionLabel={"team1p2"} ArrayLabel={"T1P2ID"} inputDefaultValue={PlayersDetails.T1P2Name+" "+PlayersDetails.T1P2LastName} handler={infoChangeHandler} />
            <SearchBar SessionLabel={"team2p1"} ArrayLabel={"T2P1ID"} inputDefaultValue={PlayersDetails.T2P1Name+" "+PlayersDetails.T2P1LastName} handler={infoChangeHandler} />
            <SearchBar SessionLabel={"team2p2"} ArrayLabel={"T2P2ID"} inputDefaultValue={PlayersDetails.T2P2Name+" "+PlayersDetails.T2P2LastName} handler={infoChangeHandler} />
             */}

        <>
          <div>
            <div className="bg-gray-100">
              <div className="min-h-screen w-full p-6 flex justify-center items-center">
                <div className="w-full max-w-xl">
                  <div className="bg-white border p-8 shadow rounded w-full mb-6">
                    <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
                      Select Players
                    </h1>

                    <div>
                      <div className="grid grid-cols-1 md:grid-cols-2 m-5 gap-2">
                        <div className=" justify-center items-center bg-green-200 mx-2 rounded-md">
                          <h2 className="text-md font-normal p-5">Team I</h2>
                          <div className="block sm:flex md:block justify-evenly items-center">
                            <label className="ml-2">Player 1</label>
                            <SearchBar
                              SessionLabel={"team1p1"}
                              ArrayLabel={"T1P1ID"}
                              inputDefaultValue={
                                PlayersDetails.T1P1Name +
                                " " +
                                PlayersDetails.T1P1LastName
                              }
                              handler={infoChangeHandler}
                            />
                          </div>

                          <br />
                        </div>
                        <div className=" justify-center items-center bg-green-200 mx-2 rounded-md">
                          <h2 className="text-md p-5 font-normal">Team II</h2>
                          <div className="block sm:flex md:block justify-evenly items-center">
                            <label className="ml-2">Player 1</label>
                            <SearchBar
                              SessionLabel={"team2p1"}
                              ArrayLabel={"T2P1ID"}
                              inputDefaultValue={
                                PlayersDetails.T2P1Name +
                                " " +
                                PlayersDetails.T2P1LastName
                              }
                              handler={infoChangeHandler}
                            />
                          </div>

                          <br />
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-center gap-2 text-center ">
                      <button
                        onClick={() => {
                          SubmitPlayers();
                        }}
                        className=" bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                      >
                        Next
                      </button>

                      {/* <button
                onClick={() => {
                  window.location.href = "/dashboard/matches";
                }}
                className=" bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
              >
                Cancel
              </button> */}
                      <GoBackButton
                        Cssclass=" bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                        btnTxt="Cancel"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }
  return (
    <div className="flex justify-center items-center h-screen text-xl">
      <div className="flex justify-center items-center">
        <div className="">
          <h2 className="text-2xl text-center">
            Oops! An Unexpected Error Occured.
          </h2>
          <h3 className="text-base text-center">Try reloading the page.</h3>
        </div>
      </div>
    </div>
  );

  //---------------------------------------------
  if ({ PlayersDetails }) {
    return (
      <div>
        <div> T1P1ID is {PlayersDetails.T1P1ID}</div>
        <div> T1P1Name is {PlayersDetails.T1P1Name}</div>

        <SearchBar
          SessionLabel={"team1p1"}
          ArrayLabel={"T1P1ID"}
          inputDefaultValue={
            PlayersDetails.T1P1Name + " " + PlayersDetails.T1P1LastName
          }
          handler={infoChangeHandler}
        />
      </div>
    );
  }
  //-----------------------------------
}

export default UpdatePlayersSingle;
