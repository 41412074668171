import React from "react";
import T1P1 from "./T1P1";
import T1P2 from "./T1P2";
import T2P1 from "./T2P1";
import T2P2 from "./T2P2";

function BL(props) {
  if (props.PPlayer.BL === "T1P1") {
    return (
      <div>
        <T1P1 server={props.server} receiver={props.receiver} playername={props.team1p1Name}/>
      </div>
    );
  }
  if (props.PPlayer.BL === "T1P2") {
    return (
      <div>
        <T1P2 server={props.server} receiver={props.receiver} playername={props.team1p2Name}/>
      </div>
    );
  }
  if (props.PPlayer.BL === "T2P1") {
    return (
      <div>
        <T2P1 server={props.server} receiver={props.receiver} playername={props.team2p1Name}/>
      </div>
    );
  }
  if (props.PPlayer.BL === "T2P2") {
    return (
      <div>
        <T2P2 server={props.server} receiver={props.receiver} playername={props.team2p2Name}/>
      </div>
    );
  }
  else{
    return (
      <div>
        error
      </div>
    );
  }
}

export default BL;
