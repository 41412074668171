import React, { useEffect } from "react";
import { useState } from "react";

function ShuttleCounter(props) {
  const [cockCount, setCockCount] = useState(1);

  useEffect(()=>{
    

    fetch(process.env.REACT_APP_API + "game/shuttles", {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "Shuttles": cockCount,
        "GameID": props.GameIDNumber
      }) ,
    })
      .then((res) => res.json())
      .then(result => console.log(result))
      .catch(err=>console.error(err))



  },[]);
  
  useEffect(()=>{

    fetch(process.env.REACT_APP_API + "game/shuttles", {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "Shuttles": cockCount,
        "GameID": props.GameIDNumber
      }) ,
    })
      .then((res) => res.json())
      .then(result => console.log(result))
      .catch(err=>console.error(err))


  },[cockCount])


  return (
    <div>

      <div className="p-2">
        <p className="font-bold relative">Shuttles Used</p>
        <div className="flex p-2 justify-center">
        <button
        className="shuttleCountButton"
            onClick={() => {
           if( cockCount>=2 ) { setCockCount(cockCount - 1)};
              //the previous value of cockCount is considering in the function even if the value is affected in the function, so substracting 1 from cockCount while storing
             // sessionStorage.setItem("shuttles", cockCount-1);
            }}
          >
              <p className=" font-extrabold">-</p>
          </button>
          <div>
            <p className="p-2 text-lg font-bold">{cockCount}</p>
          </div>
          <button
          className="shuttleCountButton"
            onClick={() => {
              setCockCount(cockCount + 1);            
              //the previous value of cockCount is considering in the function even if the value is affected in the function, so adding 1 to cockCount while storing
             // sessionStorage.setItem("shuttles", cockCount+1);
            }}
          >
            <p className=" font-extrabold">+</p>
          </button>
          
          
        </div>
      </div>
    </div>
  );
}

export default ShuttleCounter;
