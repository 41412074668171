import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';

function MatchSummary(props) {
    
    const [matchDetails, setmatchDetails]= useState([]);
    const [gamesDetails, setgamesDetails]= useState([]);
    const [PlayersDetails, setPlayersDetails]=useState({});

    const [team1p1disname,setteam1p1disname]=useState("Guest1");
    const [team1p2disname,setteam1p2disname]=useState("Guest2");
    const [team2p1disname,setteam2p1disname]=useState("Guest3");
    const [team2p2disname,setteam2p2disname]=useState("Guest4");
 

//const for checking the whole content loaded
    const [isloaded, setisloaded] = useState(false);

//const for checking individual contents loaded
    const [isloadedmatch, setisloadedmatch] = useState(false);
    const [isloadedgames, setisloadedgames] = useState(false);
    const [isloadedplayers, setisloadedplayers] = useState(false);

//const for checking any error caught
    const [IsError, setIsError] = useState(false);

//after successfully loading each content setting isloaded true
    useEffect(() => {
      if (isloadedmatch && isloadedgames && isloadedplayers) { //add all the induvidual loading variables here
        setisloaded(true);
      }
    }, [isloadedmatch, isloadedgames, isloadedplayers]); //add all the induvidual loading variables here

    useEffect(() => {
        try {
          fetch(process.env.REACT_APP_API + "match/getmatchdetails", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                MatchID: sessionStorage.getItem("MatchID"),
            }),
          })
            .then((Response) => {
              if (!Response.ok) {
                throw Error("Could not fetch data.");
              }
              return Response.json();
            })
            .then((data) => {
              console.log(data);
              setmatchDetails(data[0]);
            })
            .then(() => {
                setisloadedmatch(true);
            })
            .catch((err) => {
              console.error(err);
              setisloaded(true);
              setIsError(true);
            });
        } catch (error) {
          setisloaded(true);
          setIsError(true);
        }

        try {
            fetch(process.env.REACT_APP_API + "game/getgamesofmatch", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  MatchID: sessionStorage.getItem("MatchID"),
              }),
            })
              .then((Response) => {
                if (!Response.ok) {
                  throw Error("Could not fetch data.");
                }
                return Response.json();
              })
              .then((data) => {
                console.log(data);
                setgamesDetails(data);
              })
              .then(() => {
                setisloadedgames(true);
              })
              .catch((err) => {
                console.error(err);
                setisloaded(true);
                setIsError(true);
              });
          } catch (error) {
            setisloaded(true);
            setIsError(true);
          }

          try {
            fetch(process.env.REACT_APP_API + "match/"+"getplayers", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  MatchID: sessionStorage.getItem("MatchID"),
              }),
            })
              .then((Response) => {
                if (!Response.ok) {
                  throw Error("Could not fetch data.");
                }
                return Response.json();
              })
              .then((data) => {
                console.log(data);
                setPlayersDetails(data[0]);
                
              })
              .then(() => {
                setisloadedplayers(true);
              })
              .catch((err) => {
                console.error(err);
                setisloaded(true);
                setIsError(true);
              });
          } catch (error) {
            setisloaded(true);
            setIsError(true);
          }

      }, []);

      useEffect(()=>{
        if (isloadedplayers===true){
          
          setteam1p1disname(PlayersDetails.T1P1Name);
          setteam1p2disname(PlayersDetails.T1P2Name);
          setteam2p1disname(PlayersDetails.T2P1Name);
          setteam2p2disname(PlayersDetails.T2P2Name);
        }
      
      },[isloadedplayers]);
      let TeamAScore="0"
      let TeamBScore="0"
    
const MatchWinner = () => {

    TeamAScore = gamesDetails.filter((e) => (e.Winner ? e.Winner.includes(matchDetails.T1P1) : e.GameWinner.includes(matchDetails.T1P1))).length;
    TeamBScore = gamesDetails.filter((e) => (e.Winner ? e.Winner.includes(matchDetails.T2P1) : e.GameWinner.includes(matchDetails.T2P1))).length;
   // const TeamAScore = gamesDetails.filter((e) => e.GameWinner.includes(matchDetails.T1P1) || e.GameWinner.includes(matchDetails.T1P1)).length;
   // const TeamBScore = gamesDetails.filter((e) => e.GameWinner.includes(matchDetails.T2P1) || e.Winner.includes(matchDetails.T2P1)).length;

if (TeamAScore > TeamBScore){
    return (team1p1disname+' and '+team1p2disname)
}
else {
    return (team2p1disname+' and '+team2p2disname)
}

    if ((gamesDetails[0].GameWinner.includes(matchDetails.T1P1)) || (gamesDetails[0].GameWinner.includes(matchDetails.T1P1))){
        return ('Team A')
    }
    else if ((gamesDetails[0].GameWinner.includes(matchDetails.T2P1)) || (gamesDetails[0].GameWinner.includes(matchDetails.T2P1))){
        return ('Team B')
    }
    return ('No one')

    

};


//loading message 
      if (!isloaded)
        return (
          <div className="flex justify-center items-center h-screen text-xl">
            <div className="flex justify-center items-center">
              <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
            </div>
          </div>
        );
//error message 
      if (IsError)
        return (
          <div className="flex justify-center items-center h-screen text-xl">
            <div className="flex justify-center items-center">
              <div className="">
                <h2>Oops! Something Went Wrong...</h2>
              </div>
            </div>
          </div>
        );
//default component
if (isloaded && !IsError){
    
      return (
        <div>
          <div className="flex justify-center items-center h-screen text-xl">
            <div className="">
            <h2 className="text-2xl text-center text-white py-5"> Match Summary</h2>
              <h2 className="text-4xl text-center text-white">
                {MatchWinner()}
              </h2>
              <h2 className="text-3xl text-center text-white">
                {" "}
                Won the Match
              </h2>
              <h2 className="text-xl text-center text-white"> Score</h2>
              <h2 className="text-3xl text-center text-white">
                {" "}
                {TeamAScore} : {TeamBScore}
              </h2>
              <div className="flex justify-center items-center py-5">
                
                  {/* <button className="loginButton">Login</button> */}
                  <div
                    className={`${"bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg cursor-pointer"}`}
                    onClick={()=>{window.location.href="/dashboard/matches"}}
                  >
                    GOTO MATCHES
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      );
        }

    
  return (
    <div className="flex justify-center items-center h-screen text-xl">
    <div className="flex justify-center items-center">
      <div className="">
        <h2 className='text-2xl text-center'>Oops! An Unexpected Error Occured.</h2>
        <h3 className='text-base text-center'>Try reloading the page.</h3>
      </div>
    </div>
  </div>
   
  )


}

export default MatchSummary