import React, { useRef, useState } from "react";
import { useEffect } from "react";


function ApiTestGet(props) {
   // const [filteredData, setFilteredData] = useState([]);
   const [UsersList, setUsersList] = useState([]);
   const [searchWord, setsearchWord] = useState("");
   const [enteredText, setenteredText] = useState("saas");
   const [showResults, setshowResults] = useState(false);


   const resultContainer = useRef(null);
 
   const handleFilter = (e) => {
     //   const searchWord = event.target.value;
 
     setenteredText(e.target.value);
     setsearchWord(e.target.value);
//     props.handler(props.ArrayLabel, e.target.value);
     // const newFilter = data.filter((value) => {
 
     // return value.title.toLowerCase().includes(searchWord.toLowerCase());
   };

   const handleBlur = (e) => {
    //   const searchWord = event.target.value;

//    props.handler(props.ArrayLabel, e.target.value);

    setshowResults(false)
console.log("on blur event.target.value is "+e.target.value)

    // const newFilter = data.filter((value) => {

    // return value.title.toLowerCase().includes(searchWord.toLowerCase());
  };
 
   useEffect(() => {
     if (searchWord) {
       try {
         fetch(process.env.REACT_APP_API + "users/" + "readspecificusers", {
           method: "POST",
           headers: {
             Accept: "application/json",
             "Content-Type": "application/json",
           },
           body: JSON.stringify({
             SearchQuery: searchWord,
           }),
         })
           .then((Response) => Response.json())
           .then((data) => {
             console.log(data);
             console.log(searchWord);
             setUsersList(data);
             setshowResults(true);
           });
       } catch (error) {
         alert("error loading players");
       }
     } else {
       setUsersList([]);
       setshowResults(false);
     }
   }, [searchWord]);
 
   const clearInput = () => {
     setUsersList([]);
     setenteredText("");
     setshowResults(false);
//     props.handler(props.ArrayLabel, "");
   };
 
   const HandleSelect = ( UserID, FirstName, LastName) => {
    
     console.log("UserID is " + UserID + " and FirstName is " + FirstName);
     sessionStorage.setItem(props.SessionLabel, UserID);
     setenteredText(FirstName + " " + LastName);
     setshowResults(false);
//     props.handler(props.ArrayLabel, UserID);
   };
const [focusIndex, setfocusIndex]=useState(-1);
const handleKeyDown=(e)=>{
  let nextIndexCount = 0;
  console.log(e.key)
  if (e.key==="ArrowDown"){
    console.log("e.key is arrow down")
    nextIndexCount= (focusIndex + 1) % UsersList?.length
  }
  if (e.key==="ArrowUp"){
    console.log("e.key is arrow up")
    nextIndexCount= (focusIndex + UsersList?.length - 1) % UsersList?.length
  }
  if (e.key==="Escape"){
    console.log("e.key is  escape")
  }
  if (e.key==="Enter"){
    console.log("e.key is enter")
  }
  setfocusIndex(nextIndexCount);
};
 

useEffect(()=>{
  if (!resultContainer.current) return
  resultContainer.current.scrollIntoView({block : "center"});
},[focusIndex]);





   return (
     <div className="flex h-full" >
      <div >
<div className="p-5 bg-slate-300"></div>
      </div>
     <div className="search">
       <div className="searchInputs">
         <div className="inputDiv ml-14" tabIndex={1} onKeyDown={handleKeyDown} >
           <input
             type="text"
             placeholder="Search Player"
             value={enteredText}
             onChange={handleFilter}
             onBlur={handleBlur}
             required
             className="p-4 rounded-lg m-3"
           />
 
           <div className="searchIcon">
             {enteredText.length != 0 && (
               <p id="clearBtn" onClick={clearInput}>
                 X
               </p>
             )}
           </div>
         </div>
       </div>
       {showResults && (
         <div className="dataResult">
           {UsersList.map((e, index) => {
             return (
               <ul className="">
                 <li>
                   <div
                     className="dataItem border-b-2 px-2 py-4 text-black text-xl hover:bg-black hover:bg-opacity-10"
                     key={e.UserID}
                     onMouseDown={() =>
                       HandleSelect(e.UserID, e.FirstName, e.LastName)
                     }
                     ref={index==focusIndex ? resultContainer : null}
                     style={{backgroundColor : (index=== focusIndex)? "rgba(0,0,0,0.1)" : ""}}
                   >
                     <div>
                       {e.FirstName} {e.LastName}
                     </div>
                     <div className="text-slate-600 text-base">{e.Email}</div>
                   </div>
                 </li>
               </ul>
             );
           })}
         </div>
       )}
     </div>
     </div>
   );
}

export default ApiTestGet;
