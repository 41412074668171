import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function StartMatch() {

  const [counter, setCounter] = React.useState(sessionStorage.getItem("duration"));
  React.useEffect(() => {
      const timer =
        counter > 0 ? setInterval(() => setCounter(counter - 1), 1000): window.location.href="/maininterface"
         
      return () => clearInterval(timer);

    }, [counter]);

   const handleSubmit=(event)=>{
    event.preventDefault();
    window.location.href="/maininterface";
   }



  return (
    <div>
      
      {/* <div className="">
      <div className="text-2xl text-center font-semibold pt-10">The game will start in</div>
      <div className="text-7xl text-center font-semibold py-8">{sessionStorage.getItem("duration") && counter }</div>
        <div className="text-2xl text-center">Seconds</div>
        <div className="text-1xl text-center pt-4">Or</div>
        <div className="grid m-5">
          <Link
            to="/maininterface"
            className="text-3xl flex justify-center items-center Startcard bg-slate-200 mx-2 rounded-3xl "
          >
            <h2>Start The Game Now</h2>
          </Link>
         

          
        </div>
        <div className="button-container text-center ">
          <Link to="/timer"> <button className="loginButton" type="submit" >Back</button></Link>
          </div>
      </div> */}



      <div className="bg-gray-100">
        <div className="min-h-screen w-full p-6 flex justify-center items-center">
          <div className="w-full max-w-md">
            <div className="bg-white border p-8 shadow rounded w-full mb-6">
              <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
              The game will start in
              </h1>

              <div>
                <form onSubmit={handleSubmit}>
                  <fieldset className="mb-4 justify-center items-center ">
                 
                  <div className="text-7xl text-center font-semibold py-4">{sessionStorage.getItem("duration") && counter }</div>

                    <label className="block text-sm text-gray-900 mb-2 text-center">
                      Seconds
                    </label>
                  </fieldset>

                  <div className="flex flex-wrap justify-center text-center gap-x-2">
                    <button type="submit" className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                     Start The Game Now
                    </button>

                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        window.location.href = "/timer";
                      }}
                      className="inline-block bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Back
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartMatch;
