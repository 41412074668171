import React, { useEffect } from "react";
import { useState } from "react";
import { omit } from "lodash";

function FunctionTest() {
  const [lastGameDetails, setlastGameDetails] = useState({});

  const [isloaded, setisloaded] = useState(false);
  const [isloadedgame, setisloadedgame] = useState(false);

  const [IsError, setIsError] = useState(false);

  useEffect(() => {
    if (isloadedgame) {
      setisloaded(true);
    }
  }, [isloadedgame]);

  const handler = (e) => {
    console.log(e);
  };

  useEffect(() => {
    try {
      fetch(process.env.REACT_APP_API + "game/getlastgame", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UID: sessionStorage.getItem("UserID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setlastGameDetails(data[0]);
        })
        .then(() => {
          setisloadedgame(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }
    console.log("page loaded");
  }, []);
  //-----------------------------------------------------------------------------

  const [errors, setErrors] = useState({});

  const validate = (e) => {
    //A function to validate each input values

    if (e.target.name == "FirstName") {
      if (e.target.value.length <= 1) {
        setErrors({
          ...errors,
          FirstName: "First Name should atleast have 2 letters",
        });
        console.log("firstname errors");
        console.log(errors);
      } else {
        let newObj = omit(errors, "FirstName");
        setErrors(newObj);
      }
    }
    if (e.target.name == "LastName") {
      if (e.target.value.length < 1) {
        setErrors({
          ...errors,
          LastName: "Last Name should atleast have 1 letter",
        });
        console.log("lastname errors");
      } else {
        let newObj = omit(errors, "LastName");
        setErrors(newObj);
      }
    }
    if (e.target.name == "Email") {
      if (
        !new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(e.target.value)
      ) {
        setErrors({
          ...errors,
          Email: "Enter a valid email address",
        });
        console.log("email errors");
      } else {
        let newObj = omit(errors, "Email");
        setErrors(newObj);
      }
    }
    if (e.target.name == "Mobile") {
      if (e.target.value.length < 10) {
        setErrors({
          ...errors,
          Mobile: "Mobile Number should atleast have 10 digits",
        });
        console.log("Mobile errors");
      } else {
        let newObj = omit(errors, "Mobile");
        setErrors(newObj);
      }
    }
    if (e.target.name == "DOB") {
      console.log(Date());
      let newDate = new Date();
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let currentDate = year + "-" + month + "-" + date;
      if (e.target.value > currentDate) {
        setErrors({
          ...errors,
          DOB: "DOB is invalid",
        });
        console.log("DOB errors");
      } else {
        let newObj = omit(errors, "DOB");
        setErrors(newObj);
      }
    }
    if (e.target.name == "Password") {
      if (
        !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(
          e.target.value
        )
      ) {
        setErrors({
          ...errors,
          Password:
            "Password should contain atleast 8 charaters, an uppercase letter, a lowercase letter and a number",
        });
        console.log("password errors");
      } else {
        let newObj = omit(errors, "Password");
        setErrors(newObj);
      }
    }

    
  };

  const [userInfo, setuserInfo] = useState({
    UserID: "",
    FirstName: "",
    LastName: "",
    Gender: "Male",
    DOB: "",
    Mobile: "",
    Email: "",
    RoleType: "2",
    PhotoFileName: "",
    Password: "",
  });

  const changeHandler = (e) => {
    setuserInfo({ ...userInfo, [e.target.name]: e.target.value });
    validate(e);
  };

  const submitHandler = (e) => {
    console.log(errors.length);
    e.preventDefault();
    if (errors && errors.length == null) {
      console.log("no errors " + errors.length);
      console.log(errors);

      fetch(process.env.REACT_APP_API + "users11111111111111111111111", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          FirstName: userInfo.FirstName,
          LastName: userInfo.LastName,
          DOB: userInfo.DOB,
          Gender: userInfo.Gender,
          Mobile: userInfo.Mobile,
          Email: userInfo.Email,
          RoleType: userInfo.RoleType,
          PhotoFileName: userInfo.PhotoFileName,
          //  "UserID": null, // this value is autogenerated by DB server
          Password: userInfo.Password,
        }),
      })
        .then((res) => res.json())
        .then(
          (Result) => {
            console.log(Result[0].Column1);
            sessionStorage.setItem("UserID", Result[0].Column1);
            window.location.href = "/dashboard";
          },
          (error) => {
            alert("Failed");
          }
        );
    } else {
      alert("Enter Valid Details");
    }
  };

  if (!isloaded)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
        </div>
      </div>
    );
  if (IsError)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="">
            <h2>Oops! Something Went Wrong...</h2>
          </div>
        </div>
      </div>
    );
  //default component
  if (isloaded && !IsError && lastGameDetails) {
    return (
      <div>
        <div className="signupcontainer">
          <h2 className="heading">Sign Up</h2>
          <div className="form">
            <form onSubmit={submitHandler}>
              <div className="input-container">
                <label htmlFor="Gender">Register As </label>

                <select
                  className="genderselect"
                  onChange={changeHandler}
                  name="RoleType"
                  Defaultvalue="2"
                  required
                >
                  <option value="2">Organizer</option>
                  <option value="3">Player</option>
                </select>
              </div>
              <div className="input-container">
                <label>First Name </label>
                <input
                  type="text"
                  placeholder="Enter First Name"
                  onChange={changeHandler}
                  name="FirstName"
                  required
                />
                {errors.FirstName && <h3 className="text-red-500">{errors.FirstName}</h3>}
              </div>
              <div className="input-container">
                <label>Last Name </label>
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  onChange={changeHandler}
                  name="LastName"
                  required
                />
                {errors.LastName && <h3 className="text-red-500">{errors.LastName}</h3>}
              </div>

              <div className="input-container">
                <label>DOB</label>
                <input
                  type="date"
                  placeholder="Enter Date of Birth"
                  onChange={changeHandler}
                  name="DOB"
                  required
                />
                {errors.DOB && <h3 className="text-red-500">{errors.DOB}</h3>}
              </div>
              {/* <div className="input-container">
                  <label>Gender </label>
                  <input
                    type="text"
                    placeholder="Enter Gender"
                    onChange={changeHandler}
                    name="Gender"
                    required
                  />
                </div> */}
              <div className="input-container">
                <label htmlFor="Gender">Gender </label>

                <select
                  className="genderselect"
                  onChange={changeHandler}
                  name="Gender"
                  value="Select"
                  required
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="input-container">
                <label>Mobile </label>
                <input
                  type="text"
                  placeholder="Enter Mobile Number"
                  onChange={changeHandler}
                  name="Mobile"
                  required
                />
                {errors.Mobile && <h3 className="text-red-500">{errors.Mobile}</h3>}
              </div>

              <div className="input-container pt-12">
                <label>Email </label>
                <input
                  type="text"
                  placeholder="Enter Email ID"
                  onChange={changeHandler}
                  name="Email"
                  required
                />
                {errors.Email && <h3 className="text-red-500">{errors.Email}</h3>}
              </div>

              <div className="input-container">
                <label>Password </label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  onChange={changeHandler}
                  name="Password"
                  required
                />
                {errors.Password && <h3 className="text-red-500">{errors.Password}</h3>}
              </div>

              <div className="button-container py-4">
                <button className="loginButton" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="p-4 border-2" onClick={handler}>
        done
      </div>
      <div>GameID</div>
      <div>done</div>
    </div>
  );
}

export default FunctionTest;
