import React from "react";
import "./Layout.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import NavigationBar from "./NavigationBar";

function Layout() {
  const Redux_FirstName = useSelector((state) => state.userinfo.FirstName);
  const Redux_LastName = useSelector((state) => state.userinfo.LastName);
  const Redux_UserID = useSelector((state) => state.userinfo.UserID);
  const Redux_UserType = useSelector((state) => state.userinfo.UserType);
  const Redux_UserRole = useSelector((state) => state.userinfo.UserRole);

  const [userData, setuserData] = useState([]);
  const [nouser, setnouser] = useState(false);

  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedUser, setisloadedUser] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedUser) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedUser]); //add all the induvidual loading variables here

  useEffect(() => {
    //  if (sessionStorage.getItem("UserID")!= null && sessionStorage.getItem("UserID")!= ""){
    if (Redux_UserID != null && Redux_UserID != "") {
      console.log("user not null");
      console.log(sessionStorage.getItem("UserID"));

      try {
        fetch(process.env.REACT_APP_API + "users/" + "readusers", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            UserID: Redux_UserID, //sessionStorage.getItem("UserID"),
          }),
        })
          .then((Response) => {
            if (!Response.ok) {
              throw Error("Could not fetch data.");
            }
            return Response.json();
          })
          .then((data) => {
            console.log(data);
            setuserData(data[0]);
          })
          .then(() => {
            setisloadedUser(true);
          })
          .catch((err) => {
            console.error(err);
            setisloaded(true);
            setIsError(true);
          });
      } catch (error) {
        setisloaded(true);
        setIsError(true);
        console.error(error);
      }
    } else {
      setnouser(true);
      setisloaded(true);
      console.log("no user");
    }
  }, []);

  //loading message
  if (!isloaded)
    return (
      <div className="flex justify-center items-center h-screen ">
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
        </div>
      </div>
    );
  //error message
  if (IsError)
    return (
      <div>
<NavigationBar userData={userData}/>
        <header className="bg-secondary relative z-20 px-6">
          <div className="container mx-auto flex justify-between items-center relative h-16">
          <div className="flex items-center"> 
          <a href="#" className="md:hidden  w-8 h-auto">
              <img
                className="w-8 h-8"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACCSURBVHhe7dmhDYBQAEPBv//SIGsaDGlA3CVPd4AeAAAAAAAAAOA/Lj0210aV5tqo0lwbVZpro0pzbVQJAAAAAAAAeKXdcEpzbVRpro0qzbVRpbk2qjTXRpUAAAAAAACAV9oNpzTXRpXm2qjSXBtVmmujSnNtVAkAAAAAAAAA+N45N+IVCzyqwPF8AAAAAElFTkSuQmCC"
              />
            </a>

            <a
              href="#"
              className=" h-full p-2 flex items-center font-black text-sm sm:text-2xl hover:bg-secondary"
            >
              {/* <img src="http://placehold.it/280x160" className="h-full w-auto" /> */}
              Badminton Buddy
            </a>
            </div>

            <ul className="hidden md:flex flex-1 h-full justify-end items-center text-sm">
              <li>
                <a href="#" className="hover:opacity-75">
                  Home
                </a>
              </li>
              <li className="ml-6">
                <a href="#">About Us</a>
              </li>
              <li className="ml-6">
                <a href="#">Products</a>
              </li>
              <li className="ml-6">
                <a href="#">Services</a>
              </li>

            </ul>

            <div className="md:px-6">
              {/* {`${((sessionStorage.getItem("UserID").length)>0)? (""):(<Link to="/login"><button className="loginButton">Login</button></Link>)}`} */}
              {!window.location.href.includes("/login") && (
                <Link to="/login">
                  {/* <button className="loginButton">Login</button> */}
                  <div    className={`${
                  Redux_UserID ? "hidden" : "bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg"  }`}>Login</div>
                </Link>
              )}
            </div>

          </div>
        </header>

      </div>
    );

  if (nouser) {
    return (
      <div>


        <header className="bg-secondary relative z-20 px-6">
          <div className="container mx-auto flex justify-between items-center relative h-16">
           <div className="flex items-center"> 
          <a href="#" className="md:hidden  w-8 h-auto">
              <img
                className="w-8 h-8"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACCSURBVHhe7dmhDYBQAEPBv//SIGsaDGlA3CVPd4AeAAAAAAAAAOA/Lj0210aV5tqo0lwbVZpro0pzbVQJAAAAAAAAeKXdcEpzbVRpro0qzbVRpbk2qjTXRpUAAAAAAACAV9oNpzTXRpXm2qjSXBtVmmujSnNtVAkAAAAAAAAA+N45N+IVCzyqwPF8AAAAAElFTkSuQmCC"
              />
            </a>

            <a
              href="#"
              className=" h-full p-2 flex items-center font-black text-sm sm:text-2xl hover:bg-secondary"
            >
              {/* <img src="http://placehold.it/280x160" className="h-full w-auto" /> */}
              Badminton Buddy
            </a>
            </div>
            <ul className="hidden md:flex flex-1 h-full justify-end items-center text-sm">
              <li>
                <a href="#" className="hover:opacity-75">
                  Home
                </a>
              </li>
              <li className="ml-6">
                <a href="#">About Us</a>
              </li>
              <li className="ml-6">
                <a href="#">Products</a>
              </li>
              <li className="ml-6">
                <a href="#">Services</a>
              </li>

            </ul>

            <div className="md:px-6">
              {/* {`${((sessionStorage.getItem("UserID").length)>0)? (""):(<Link to="/login"><button className="loginButton">Login</button></Link>)}`} */}
              {!window.location.href.includes("/login") && (
                <Link to="/login">
                  {/* <button className="loginButton">Login</button> */}
                  <div className="bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg">Login</div>
                </Link>
              )}
            </div>

     
          </div>
        </header>
      </div>
    );
  }

  //default component
  if (isloaded && !IsError) {
    return (
      <div>

        <header className="bg-secondary relative z-20 px-6">
          <div className="container mx-auto flex justify-between items-center relative h-16">
          <div className="flex items-center"> 
          <a href="#" className="md:hidden  w-8 h-auto">
              <img
                className="w-8 h-8"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACCSURBVHhe7dmhDYBQAEPBv//SIGsaDGlA3CVPd4AeAAAAAAAAAOA/Lj0210aV5tqo0lwbVZpro0pzbVQJAAAAAAAAeKXdcEpzbVRpro0qzbVRpbk2qjTXRpUAAAAAAACAV9oNpzTXRpXm2qjSXBtVmmujSnNtVAkAAAAAAAAA+N45N+IVCzyqwPF8AAAAAElFTkSuQmCC"
              />
            </a>

            <a
              href="#"
              className=" h-full p-2 flex items-center font-black text-sm sm:text-2xl hover:bg-secondary"
            >
              {/* <img src="http://placehold.it/280x160" className="h-full w-auto" /> */}
              Badminton Buddy
            </a>
            </div>

            <ul className="hidden md:flex flex-1 h-full justify-end items-center text-sm">
              <li>
                <a href="#" className="hover:opacity-75">
                  Home
                </a>
              </li>
              <li className="ml-6">
                <a href="#">About Us</a>
              </li>
              <li className="ml-6">
                <a href="#">Products</a>
              </li>
              <li className="ml-6">
                <a href="#">Services</a>
              </li>

            </ul>

            <div className="md:px-6">
            <h2 className="font-medium leading-tight   text-gray-800 text-right">{`${
              userData.FirstName ? "Hi, " + userData.FirstName : ""
            }`}</h2>
              {/* {`${((sessionStorage.getItem("UserID").length)>0)? (""):(<Link to="/login"><button className="loginButton">Login</button></Link>)}`} */}
              <Link to="/login">
                  {/* <button className="loginButton">Login</button> */}
                  <div    className={`${
                  Redux_UserID ? "hidden" : "bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg"  }`}>Login</div>
                </Link>
            </div>
     
          </div>
        </header>

      </div>
    );
  }

  return (
    <div>
        <header className="bg-secondary relative z-20 px-6">
          <div className="container mx-auto flex justify-between items-center relative h-16">
          <div className="flex items-center"> 
          <a href="#" className="md:hidden  w-8 h-auto">
              <img
                className="w-8 h-8"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACCSURBVHhe7dmhDYBQAEPBv//SIGsaDGlA3CVPd4AeAAAAAAAAAOA/Lj0210aV5tqo0lwbVZpro0pzbVQJAAAAAAAAeKXdcEpzbVRpro0qzbVRpbk2qjTXRpUAAAAAAACAV9oNpzTXRpXm2qjSXBtVmmujSnNtVAkAAAAAAAAA+N45N+IVCzyqwPF8AAAAAElFTkSuQmCC"
              />
            </a>

            <a
              href="#"
              className=" h-full p-2 flex items-center font-black text-sm sm:text-2xl hover:bg-secondary"
            >
              {/* <img src="http://placehold.it/280x160" className="h-full w-auto" /> */}
              Badminton Buddy
            </a>
            </div>
<a>Error</a>
<ul className="hidden md:flex flex-1 h-full justify-end items-center text-sm">
              <li>
                <a href="#" className="hover:opacity-75">
                  Home
                </a>
              </li>
              <li className="ml-6">
                <a href="#">About Us</a>
              </li>
              <li className="ml-6">
                <a href="#">Products</a>
              </li>
              <li className="ml-6">
                <a href="#">Services</a>
              </li>

            </ul>

            <div className="md:px-6">
            <h2 className="font-medium leading-tight   text-gray-800 text-right">{`${
              userData.FirstName ? "Hi, " + userData.FirstName : ""
            }`}</h2>
              {/* {`${((sessionStorage.getItem("UserID").length)>0)? (""):(<Link to="/login"><button className="loginButton">Login</button></Link>)}`} */}
              <Link to="/login">
                  {/* <button className="loginButton">Login</button> */}
                  <div    className={`${
                  Redux_UserID ? "hidden" : "bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg"  }`}>Login</div>
                </Link>
            </div>
     
          </div>
        </header>
    </div>
  );
}

export default Layout;
