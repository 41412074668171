import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../InitialSteps/Toss.css";

function EndgameSingle() {
  

  
  // let team1p1=(sessionStorage.getItem("team1p1"));
  // let team1p2=(sessionStorage.getItem("team1p2"));
  // let team2p1=(sessionStorage.getItem("team2p1"));
  // let team2p2=(sessionStorage.getItem("team2p2"));
  const [team1p1id, setteam1p1id] = useState(sessionStorage.getItem("team1p1"));
  const [team1p2id, setteam1p2id] = useState(sessionStorage.getItem("team1p2"));
  const [team2p1id, setteam2p1id] = useState(sessionStorage.getItem("team2p1"));
  const [team2p2id, setteam2p2id] = useState(sessionStorage.getItem("team2p2"));

  const [matchDetails, setmatchDetails] = useState([]);
  const [gameDetails, setgameDetails] = useState([]);
  const [gamesDetails, setgamesDetails] = useState([]);
  // const [gameDetails, setgameDetails] = useState({
  //   GameID: "1000000004",
  //   TournamentID: "1",
  //   Round: "1",
  //   SetNo: "1",
  //   TossWinner: "1",
  //   FirstServer: "1",
  //   FirstReceiver: "1",
  //   Date: "11-11-2011",
  //   T1P1: "1",
  //   T1P2: "1",
  //   T2P1: "1",
  //   T2P2: "1",
  //   Winpoint: "1",
  //   Goldenpoint: "1",
  //   Master: "1",
  // });

   
// checking loading starts-------------------------------------------------------------------------------------------

//const for checking the whole content loaded
const [isloaded, setisloaded] = useState(false);

//const for checking individual contents loaded

    
    const [isloadedgames, setisloadedgames] = useState(false);
    const [isloadedmatch, setisloadedmatch] = useState(false);


//const for checking any error caught
    const [IsError, setIsError] = useState(false);

//after successfully loading each content, setting isloaded true
    useEffect(() => {
      if ( isloadedgames && isloadedmatch) { //add all the induvidual loading variables here
        setisloaded(true);
      }
    }, [isloadedgames, isloadedmatch]);//add all the induvidual loading variables here


  useEffect(() => {
    sessionStorage.setItem("Winner", null)


    //get game details
    fetch(process.env.REACT_APP_API + "game/" + (sessionStorage.getItem("gameID"))) // change the value of gameid
      .then((Response) => Response.json())
      .then((data) => {
        setgameDetails(data[0]);
        // alert(data[0])
      });

      
      try {
        fetch(process.env.REACT_APP_API + "match/" + "getmatchdetails", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            MatchID: sessionStorage.getItem("MatchID"), //"1000001145"
          }),
        })
          .then((Response) => Response.json())
          .then((data) => {
            console.log(data);
            setmatchDetails(data);
          })
          .then(setisloadedmatch(true));
          
      } catch (error) {
        alert("error loading match details");
      }

      
      try {
        fetch(process.env.REACT_APP_API + "game/getgamesofmatch", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
              MatchID: sessionStorage.getItem("MatchID"),
          }),
        })
          .then((Response) => {
            if (!Response.ok) {
              throw Error("Could not fetch data.");
            }
            return Response.json();
          })
          .then((data) => {
            console.log(data);
            setgamesDetails(data);
          })
          .then(() => {
            setisloadedgames(true);
          })
          .catch((err) => {
            console.error(err);
            setisloaded(true);
            setIsError(true);
          });
      } catch (error) {
        setisloaded(true);
        setIsError(true);
      }

    }, []);


    
  useEffect(() => {
    setteam1p1id(gameDetails.T1P1);
 //   setteam1p2id(gameDetails.T1P2);
    setteam2p1id(gameDetails.T2P1);
//    setteam2p2id(gameDetails.T2P2);

    
  
    }, [gameDetails]);

    
    const undoSummary = () => {
      alert("undo clicked");
      sessionStorage.setItem("summaryUndo", true);
      window.location.href = "/maininterfacesingle";
    };




    const Matchwinner=()=>{
        if (gameDetails.SetNo==matchDetails.NoOfSets){
    if (matchDetails.GameType!="Single"){
          const TeamAScore = gamesDetails.filter((e) => (e.Winner ? e.Winner.includes(matchDetails.T1P1) : e.GameWinner.includes(matchDetails.T1P1))).length;
          const TeamBScore = gamesDetails.filter((e) => (e.Winner ? e.Winner.includes(matchDetails.T2P1) : e.GameWinner.includes(matchDetails.T2P1))).length;
         // const TeamAScore = gamesDetails.filter((e) => e.GameWinner.includes(matchDetails.T1P1) || e.GameWinner.includes(matchDetails.T1P1)).length;
         // const TeamBScore = gamesDetails.filter((e) => e.GameWinner.includes(matchDetails.T2P1) || e.Winner.includes(matchDetails.T2P1)).length;
      
      if (TeamAScore > TeamBScore){
          return ("W"+gameDetails.T1P1+"W"+gameDetails.T1P2+"W")
      }
      else {
          return ("W"+gameDetails.T2P1+"W"+gameDetails.T2P2+"W")
      }
    }
    if (matchDetails.GameType=="Single"){
      const TeamAScore = gamesDetails.filter((e) => (e.Winner ? e.Winner.includes(matchDetails.T1P1) : e.GameWinner.includes(matchDetails.T1P1))).length;
      const TeamBScore = gamesDetails.filter((e) => (e.Winner ? e.Winner.includes(matchDetails.T2P1) : e.GameWinner.includes(matchDetails.T2P1))).length;
     // const TeamAScore = gamesDetails.filter((e) => e.GameWinner.includes(matchDetails.T1P1) || e.GameWinner.includes(matchDetails.T1P1)).length;
     // const TeamBScore = gamesDetails.filter((e) => e.GameWinner.includes(matchDetails.T2P1) || e.Winner.includes(matchDetails.T2P1)).length;
    
    if (TeamAScore > TeamBScore){
      return ("W"+gameDetails.T1P1+"W")
    }
    else {
      return ("W"+gameDetails.T2P1+"W")
    }
    }
        }
      }


  const putwinner=(Winner)=>{
    sessionStorage.setItem("Winner", Winner)
    fetch(process.env.REACT_APP_API + "game/putwinner", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "GameID": gameDetails.GameID,
            "Winner": Winner
        }),
      })
        .then((res) => res.json())
        .then(
          (Result) => {
            alert(Result);
          },
          (error) => {
            alert("Failed to update winner");
          }
        )
        .then(()=>{
          if (gameDetails.SetNo==matchDetails.NoOfSets) {
         
            fetch(process.env.REACT_APP_API + "game/updatematchwinner", {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                MatchID: sessionStorage.getItem("MatchID"),
      
                Winner: Matchwinner(),
              }),
            })
              .then((res) => res.json())
              .then(
                (Result) => {
                  // sessionStorage.setItem("gameID",Result[0].Column1);
                  alert(Result);
                  // window.location.href = "/"+toLocation
                },
                (error) => {
                  alert("Failed to update match winner");
                }
              )
          }
        }

        )
        .then(()=>{
          window.location.href = "/summarysingle";
        })



  };


  return (
    <div>
    <div className="TossContainer">
    <div className="text-2xl text-center">Who Won The Game</div>
      <div className="grid grid-cols-2 m-5">
       
       <div onClick={()=> putwinner("W"+gameDetails.T1P1+"W")} 
          className="text-3xl flex justify-center items-center card bg-slate-200 mx-2 rounded-3xl "
        >
          <h2>{team1p1id}</h2>
        
        </div>

        <div onClick={()=> putwinner("W"+gameDetails.T2P1+"W")} 
          className="text-3xl flex justify-center items-center card bg-slate-200 mx-2 rounded-3xl"
        >
          <h2>{team2p1id}</h2>
        
        </div>

        

        
      </div>

      <div className="button-container text-center w-full p-2">
          <Link to="/summarysingle">
            {" "}
            <button className="loginButton" type="submit">
              Set Winner According To The Score
            </button>
          </Link>
        </div>


      <div className="button-container text-center " >
         <button className="loginButton" type="submit" onClick={undoSummary} >Cancel</button>
        </div>
    </div>
  </div>
  )
}

export default EndgameSingle