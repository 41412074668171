import React, { useEffect, useState } from 'react'
import MatchSummary from '../Summary/MatchSummary';
import Interface from './Interface'


function MainInterface() {

    const [matchDetails, setmatchDetails]= useState([]);
    const [gamesDetails, setgamesDetails]= useState([]);

//const for checking the whole content loaded
    const [isloaded, setisloaded] = useState(false);

//const for checking individual contents loaded
    const [isloadedmatch, setisloadedmatch] = useState(false);
    const [isloadedgames, setisloadedgames] = useState(false);

//const for checking any error caught
    const [IsError, setIsError] = useState(false);

//after successfully loading each content setting isloaded true
    useEffect(() => {
      if (isloadedmatch && isloadedgames) { //add all the induvidual loading variables here
        setisloaded(true);
      }
    }, [isloadedmatch, isloadedgames]); //add all the induvidual loading variables here

    useEffect(() => {
        try {
          fetch(process.env.REACT_APP_API + "match/getmatchdetails", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                MatchID: sessionStorage.getItem("MatchID"),
            }),
          })
            .then((Response) => {
              if (!Response.ok) {
                throw Error("Could not fetch data.");
              }
              return Response.json();
            })
            .then((data) => {
              console.log(data);
              setmatchDetails(data[0]);
            })
            .then(() => {
                setisloadedmatch(true);
            })
            .catch((err) => {
              console.error(err);
              setisloaded(true);
              setIsError(true);
            });
        } catch (error) {
          setisloaded(true);
          setIsError(true);
        }

        try {
            fetch(process.env.REACT_APP_API + "game/getgamesofmatch", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  MatchID: sessionStorage.getItem("MatchID"),
              }),
            })
              .then((Response) => {
                if (!Response.ok) {
                  throw Error("Could not fetch data.");
                }
                return Response.json();
              })
              .then((data) => {
                console.log(data);
                setgamesDetails(data);
              })
              .then(() => {
                setisloadedgames(true);
              })
              .catch((err) => {
                console.error(err);
                setisloaded(true);
                setIsError(true);
              });
          } catch (error) {
            setisloaded(true);
            setIsError(true);
          }

      }, []);
    
const activegame = () => {
    
   
  if ((gamesDetails[0].GameWinner === null) && (gamesDetails[0].Winner === null)) {

    return ('1')
  }
  else if ((gamesDetails[1]?.GameWinner === null) && (gamesDetails[1]?.Winner === null)) {

    return ('2')
  }
  else if (gamesDetails[2]?.GameWinner === null && gamesDetails[2]?.Winner === null) {
    
    return ('3')
  }
  else {
    return ('0')
  }
};


//loading message 
      if (!isloaded)
        return (
          <div className="flex justify-center items-center h-screen text-xl">
            <div className="flex justify-center items-center">
              <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
            </div>
          </div>
        );
//error message 
      if (IsError)
        return (
          <div className="flex justify-center items-center h-screen text-xl">
            <div className="flex justify-center items-center">
              <div className="">
                <h2>Oops! Something Went Wrong...</h2>
              </div>
            </div>
          </div>
        );
//default component
if (isloaded && !IsError){
   
        if (activegame()=='1'){
            sessionStorage.setItem("gameID", gamesDetails[0].GameID);

            return(
                <Interface />

            );
        
        }
        if ( activegame()=='2'){
            sessionStorage.setItem("gameID", gamesDetails[1].GameID)
            return(
                <Interface />

            );
        }
        if (activegame()=='3'){
            sessionStorage.setItem("gameID", gamesDetails[2].GameID)
            return(
               <Interface />

            );
        }
        if (activegame()=='0'){
            // sessionStorage.setItem("gameID", gamesDetails[2].GameID)
            return(
               <MatchSummary matchDetails={matchDetails} />

            );
        }

    }
  return (
    <div className="flex justify-center items-center h-screen text-xl">
    <div className="flex justify-center items-center">
      <div className="">
        <h2 className='text-2xl text-center'>Oops! An Unexpected Error Occured.</h2>
        <h3 className='text-base text-center'>Try reloading the page.</h3>
      </div>
    </div>
  </div>
   
  )
}

export default MainInterface