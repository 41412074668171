import React from "react";
import { useState, useEffect } from "react";

function AveragePoints() {
  const [Totalgamecount, setTotalgamecount] = useState(0);
  const [TotalPoints, setTotalPoints] = useState(0);

  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedcount, setisloadedcount] = useState(false);
  const [isloadedpoints, setisloadedpoints] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedcount && isloadedpoints) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedcount, isloadedpoints]); //add all the induvidual loading variables here

  useEffect(() => {
    try {
      fetch(process.env.REACT_APP_API + "scoreboard/getaveragepoints", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UID: sessionStorage.getItem("UserID"), //change this to userid of the user
        }),
      })
        .then((Response) => Response.json())
        .then((data) => {
          // alert(data[0].TeamTag+ 'thats it')
          setTotalgamecount(data[0].Totalgamecount);
          setTotalPoints(data[0].TotalPoints);
          setisloadedcount(true);
          setisloadedpoints(true);
        //  console.log(data);
        })
        .then();
    } catch (error) {
      setIsError(true);
    }
  }, []);

  
  //loading message
  if (!isloaded)
    return (
          <div className="spinner-border animate-spin inline-block w-5 h-5 border-t-2 rounded-t-full"></div>
    );
  //error message
  if (IsError)
    return (
          <div className="">
            <h2>Error...</h2>
          </div>
    );
  //default component
  if (isloaded && !IsError) 
  return (
    <div>
      <p className="">{(TotalPoints / Totalgamecount).toFixed(2)}</p>
    </div>
  );
}

export default AveragePoints;
