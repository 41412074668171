import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function ViewUserDetails() {
    const [userData, setuserData] = useState();

    useEffect(()=>{


      fetch(process.env.REACT_APP_API + "users/"+"readusers", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body:JSON.stringify({
          
          "UserID": sessionStorage.getItem("UserID")
        })
      })
        .then((Response) => Response.json())
        .then((data) => {
         // alert(data[0]+ 'thats it')
           setuserData(data[0]);
      });
  
    },[]);    


  return (
    <div>
        {
         userData && 
        (
        <div className="signupcontainer">
            <h2 className="heading">{ userData.FirstName}</h2>
            <h2  className="heading text-3xl">Profile</h2>
            
           
            
            <div className="form">
              <div >
                <div className="input-container">
                  <label>User ID</label>
                  { userData.UserID}
                </div>
                <div className="input-container">
                  <label>First Name </label>
                  { userData.FirstName}
                </div>
                <div className="input-container">
                  <label>Last Name </label>
                  { userData.LastName}
                </div>
  
                <div className="input-container">
                  <label>DOB</label>
                  { userData.DOB}
                </div>
                <div className="input-container">
                <label>Gender</label>
                { userData.Gender}
                </div>
              

               



                <div className="input-container">
                  <label>Mobile </label>
                  { userData.Mobile}
                </div>
  
                <div className="input-container">
                  <label>Email </label>
                  { userData.Email}
                </div>
  
           
  
                <div className="button-container py-4">
                <Link to="/dashboard">
                  <button className="loginButton m-2" >
                    Back
                  </button>
                </Link>
                <Link to="/edituserdetails">
                  <button className="loginButton m-2" >
                    Edit
                  </button>
                </Link>
                
                </div>
                <div className="button-container py-4">
                
                </div>
              </div>
            </div>
          </div>
        )
}
    </div>
  )
}

export default ViewUserDetails