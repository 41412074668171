import React from "react";

import { Navigate, Route, Routes, } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Home from "./Home/Home";
import Layout from "./Layout/Layout old";
import Login from "./Login/Login";
import Interface from "./Interface/Interface"
import Summary from "./Summary/Summary";
import Toss from "./InitialSteps/Toss";
import WhoisServing from "./InitialSteps/WhoisServing";
import WhoReceiving from "./InitialSteps/WhoReceiving";
import StartMatch from "./InitialSteps/StartMatch";
import Timer from "./InitialSteps/Timer";
import CustomPoints from "./InitialSteps/CustomPoints";
import Signup from "./Login/Signup";
import SelectPlayers from "./InitialSteps/SelectPlayers";
import ApiTestGet from "./Test/ApiTestGet";
import EditUserDetails from "./User/EditUserDetails";
import ViewUserDetails from "./User/ViewUserDetails";
import Endgame from "./Summary/Endgame";

import CreateTournament from "./Tournament/CreateTournament";
import Schedule from "./InitialSteps/Schedule";
import AsReferee from "./User/AsReferee";
import UpdatePlayers from "./InitialSteps/UpdatePlayers";
import UpdatePoints from "./InitialSteps/UpdatePoints";
import PlayerProfile from "./Stats/PlayerProfile";
import CreatedGames from "./User/CreatedGames";
import MyTournaments from "./Tournament/MyTournaments";
import ViewTournament from "./Tournament/ViewTournament";
import DatalistTest from "./Test/DatalistTest";
import SwitchCourt from "./InitialSteps/SwitchCourt";
import ChooseCourtOrServe from "./InitialSteps/ChooseCourtOrServe";
import FunctionTest from "./Test/FunctionTest";
import InterfaceSingle from "./Interface/InterfaceSingle";
import GameType from "./InitialSteps/GameType";
import SelectPlayersSingle from "./InitialStepsSingle/SelectPlayersSingle";
import CustomPointsSingle from "./InitialStepsSingle/CustomPointsSingle";
import StartMatchSingle from "./InitialStepsSingle/StartMatchSingle";
import TimerSingle from "./InitialStepsSingle/TimerSingle";
import TossSingle from "./InitialStepsSingle/TossSingle";
import WhoServeSingle from "./InitialStepsSingle/WhoServeSingle";
import MainInterface from "./Interface/MainInterface";
import PageNotFound from "../PageNotFound";
import SwitchCourtSingle from "./InitialStepsSingle/SwitchCourtSingle";
import MainInterfaceSingle from "./Interface/MainInterfaceSingle";
import SummarySingle from "./Summary/SummarySingle";
import ScheduleSingle from "./InitialStepsSingle/ScheduleSingle";
import SearchbarTest from "./Test/SearchbarTest";
import UpdatePlayersSingle from "./InitialStepsSingle/UpdatePlayersSingle";
import UpdatePointsSingle from "./InitialStepsSingle/UpdatePointsSingle";
import ClassImportTest from "./Test/ClassImportTest";
import ForgotPassword from "./Login/ForgotPassword";
import Counter from "./Test/ReduxTest";
import HomepageUItest from "./Test/HomepageUItest";
import Contact from "./Contact/Contact";
import DashRouter from "./Dashboard/DashRouter";
import Profile from "./Dashboard/Profile";
import NotificationFeed from "./Dashboard/NotificationFeed";
import Live from "./Live.js/Live";
import SignalRTest from "./Test/SignalRTest";
import EndgameSingle from "./Summary/EndgameSingle";








function MainRouter({Authorized}) {
  console.log(Authorized);
  return (
    <div>


      <Routes>
        <Route path="/" element={<Layout />} />
        <Route index element={<Home />} />
        <Route path="*" element={<PageNotFound />} />
        <Route exact path="/login" element={!Authorized ? <Login /> : <Navigate to="/dashboard/profile" /> } />
        <Route path="/dashboard/:tabname" element={!Authorized ? <Navigate to="/login" /> : <Dashboard />  } />
        <Route exact path="/interface" element={!Authorized ? <Navigate to="/login" /> : <Interface />} />
        <Route exact path="/summary" element={!Authorized ? <Navigate to="/login" /> : <Summary />} />
        <Route exact path="/toss" element={!Authorized ? <Navigate to="/login" /> : <Toss />} />
        <Route exact path="/whoserving" element={!Authorized ? <Navigate to="/login" /> : <WhoisServing />} />
        <Route exact path="/whoreceiving" element={!Authorized ? <Navigate to="/login" /> : <WhoReceiving />} />
        <Route exact path="/startmatch" element={!Authorized ? <Navigate to="/login" /> : <StartMatch />} />
        <Route exact path="/timer" element={!Authorized ? <Navigate to="/login" /> : <Timer />} />
        <Route exact path="/custompoints" element={!Authorized ? <Navigate to="/login" /> : <CustomPoints />} />
        <Route exact path="/signup" element={ <Signup />} />
        <Route exact path="/selectplayers" element={!Authorized ? <Navigate to="/login" /> : <SelectPlayers />} />
        <Route exact path="/edituserdetails" element={!Authorized ? <Navigate to="/login" /> : <EditUserDetails />} />
        <Route exact path="/apitestget" element={<ApiTestGet />} />
        <Route exact path="/userdetails" element={!Authorized ? <Navigate to="/login" /> : <ViewUserDetails />} />
        <Route exact path="/endgame" element={!Authorized ? <Navigate to="/login" /> : <Endgame />} />
        <Route exact path="/endgamesingle" element={!Authorized ? <Navigate to="/login" /> : <EndgameSingle />} />
        <Route exact path="/createdgames" element={!Authorized ? <Navigate to="/login" /> : <CreatedGames/>} />  {/* removable */}
        <Route exact path="/createtournament" element={!Authorized ? <Navigate to="/login" /> : <CreateTournament />} />
        <Route exact path="/schedule" element={!Authorized ? <Navigate to="/login" /> : <Schedule />} />
        <Route exact path="/asreferee" element={!Authorized ? <Navigate to="/login" /> : <AsReferee />} />
        <Route exact path="/updateplayers" element={!Authorized ? <Navigate to="/login" /> : <UpdatePlayers/>} />
        <Route exact path="/updatepoints" element={!Authorized ? <Navigate to="/login" /> : <UpdatePoints/>} />
        <Route exact path="/playerprofile" element={!Authorized ? <Navigate to="/login" /> : <PlayerProfile/>} />
        <Route exact path="/mytournaments" element={!Authorized ? <Navigate to="/login" /> : <MyTournaments/>} />
        <Route exact path="/viewTournament" element={!Authorized ? <Navigate to="/login" /> : <ViewTournament/>} />
        <Route exact path="/test" element={<DatalistTest/>} />
        <Route exact path="/switchcourt" element={!Authorized ? <Navigate to="/login" /> : <SwitchCourt/>} />
        <Route exact path="/choose" element={!Authorized ? <Navigate to="/login" /> : <ChooseCourtOrServe/>} />
        <Route exact path="/function" element={<FunctionTest/>} />
        <Route exact path="/interfacesingle" element={!Authorized ? <Navigate to="/login" /> : <InterfaceSingle/>} />
        <Route exact path="/gametype" element={!Authorized ? <Navigate to="/login" /> : <GameType/>} />
        <Route exact path="/selectplayerssingle" element={!Authorized ? <Navigate to="/login" /> : <SelectPlayersSingle/>} />
        <Route exact path="/custompointssingle" element={!Authorized ? <Navigate to="/login" /> : <CustomPointsSingle/>} />
        <Route exact path="/startmatchsingle" element={!Authorized ? <Navigate to="/login" /> : <StartMatchSingle/>} />
        <Route exact path="/timersingle" element={!Authorized ? <Navigate to="/login" /> : <TimerSingle/>} />
        <Route exact path="/tosssingle" element={!Authorized ? <Navigate to="/login" /> : <TossSingle/>} />
        <Route exact path="/whoservesingle" element={!Authorized ? <Navigate to="/login" /> : <WhoServeSingle/>} />
        <Route exact path="/maininterface" element={!Authorized ? <Navigate to="/login" /> : <MainInterface/>} />
        <Route exact path="/maininterfacesingle" element={!Authorized ? <Navigate to="/login" /> : <MainInterfaceSingle/>} />
        <Route exact path="/switchcourtsingle" element={!Authorized ? <Navigate to="/login" /> : <SwitchCourtSingle/>} />
        <Route exact path="/summarysingle" element={!Authorized ? <Navigate to="/login" /> : <SummarySingle/>} />
        <Route exact path="/schedulesingle" element={!Authorized ? <Navigate to="/login" /> : <ScheduleSingle/>} />
        <Route exact path="/searchbartest" element={!Authorized ? <Navigate to="/login" /> : <SearchbarTest/>} />
        <Route exact path="/updateplayerssingle" element={!Authorized ? <Navigate to="/login" /> : <UpdatePlayersSingle/>} />
        <Route exact path="/updatepointssingle" element={!Authorized ? <Navigate to="/login" /> : <UpdatePointsSingle/>} />
        <Route exact path="/classimporttest" element={<ClassImportTest/>} />
        <Route exact path="/forgotpassword" element={<ForgotPassword/>} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route exact path="/live" element={<Live/>} />
        <Route exact path="/reduxtest" element={<Counter/>} />
        <Route exact path="/homeuitest" element={<HomepageUItest/>} />
        <Route exact path="/signalrtest" element={<SignalRTest/>} />


        
        {/* <Route exact path="dashrouter" >
          <Route exact path="profile"       element={<Dashboard ><Profile /></Dashboard >} />
          <Route exact path="notification"  element={<Dashboard ><NotificationFeed /></Dashboard >} />
          <Route exact path="matches"       element={<Dashboard ><Profile /></Dashboard >} />
          <Route exact path="tournaments"   element={<Dashboard ><Profile /></Dashboard >} />
          <Route exact path="stats"         element={<Dashboard ><Profile /></Dashboard >} />
          <Route exact path="account"       element={<Dashboard ><Profile /></Dashboard >} />
          
        </Route> */}
 
        
       

      </Routes>
    </div>
  );
}

export default MainRouter;
