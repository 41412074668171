import React from 'react'

function Contact() {
  return (
    <div>
      		
		<div className="w-full bg-white">

			{/* <!-- title --> */}
			<div className="text-center px-6 py-12 mb-6 bg-primary border-b">
				<h1 className=" text-xl md:text-4xl pb-4 text-secondary">Contact Us</h1>
				<p className="leading-loose  text-secondary">
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
				</p>
			</div>
			{/* <!-- /title --> */}

			<div className="container max-w-4xl mx-auto pb-10 px-12 md:px-0">

				<div className="flex flex-wrap justify-start items-start -mx-4">

					<div className="w-full md:w-1/2 p-4">
						
						<form>
							<fieldset className="mb-4">
								<label className="block text-sm text-gray-dark pb-2">Name</label>
								<input className="block w-full border rounded py-2 px-3 text-sm text-gray-700" type="text" required />
								
							</fieldset>

							<fieldset className="mb-4">
								<label className="block text-sm text-gray-dark pb-2">Email</label>
								<input className="block w-full border rounded py-2 px-3 text-sm text-gray-700" type="email" required/>
							</fieldset>

							<fieldset className="mb-4">
								<label className="block text-sm text-gray-dark pb-2">Message</label>
								<textarea className="block w-full border border-red-300er rounded py-2 px-3 text-sm text-gray-700 h-32" required></textarea>

							</fieldset>

							<button className="text-sm py-2 px-3 bg-primary text-white rounded-full hover:shadow-md border-none">Submit</button>
						</form>

					</div>

					<div className="w-full md:w-1/2 p-4">
						<div className="overflow-hidden rounded border mb-6">
              <iframe className="w-full h-64" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.273897426678!2d77.64612501464569!3d13.018222090825251!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae170d4cc68f71%3A0xbe8bb4669954dbfa!2sSwiftSense%20IT%20Solutions%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1676760736394!5m2!1sen!2sin" style={{border:0}}  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

						</div>

						<p className="text-black font-bold mb-1">
							SwiftSense IT Solutions Pvt Ltd
						</p>
						<p className="text-sm mb-2">
            918, 3rd Cross Rd,<br />
            1st Block, HRBR Layout, Kalyan Nagar,<br />
            Bengaluru, Karnataka 560043
						</p>
						<p className="text-black font-medium">info@swiftsenseitsolutions.com</p>
            <p className="text-black font-medium">+91 7022531630</p>
					</div>

				</div>

			</div>

		</div>
    </div>
  )
}

export default Contact