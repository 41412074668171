import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

function UpdatePointsSingle() {
  const Winpoint = useRef();
  const Goldenpoint = useRef();
  const Sets = useRef();
  const [gamesDetails, setgamesDetails] = useState({});

  const [matchDetails, setmatchDetails] = useState({
    GameID: sessionStorage.getItem("gameID"),
    TournamentID: "1",
    Round: "1",
    SetNo: "1",
    TossWinner: "1",
    FirstServer: "1",
    FirstReceiver: "1",
    Date: "11-11-2011",
    T1P1: "1",
    T1P2: "1",
    T2P1: "1",
    T2P2: "1",
    Winpoint: "",
    Goldenpoint: "",
    NoOfSets: "",
    Master: "1",
  });

  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedmatch, setisloadedmatch] = useState(false);
  const [isloadedgames, setisloadedgames] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedmatch && isloadedgames) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedmatch, isloadedgames]); //add all the induvidual loading variables here

  useEffect(() => {
    try {
      fetch(process.env.REACT_APP_API + "match/getmatchdetails", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setmatchDetails(data[0]);
        })
        .then(() => {
          setisloadedmatch(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }

    try {
      fetch(process.env.REACT_APP_API + "game/getgamesofmatch", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setgamesDetails(data);
        })
        .then(() => {
          setisloadedgames(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }
  }, []);

  // const SaveGameDetails = (toLocation) => {
  //   if (
  //     Winpoint.current.value > 0 &&
  //     Goldenpoint.current.value > 0 &&
  //     Winpoint.current.value <= Goldenpoint.current.value
  //   ) {
  //     fetch(process.env.REACT_APP_API + "match/updatematchdtlsstart", {
  //       method: "PUT",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         MatchID: sessionStorage.getItem("MatchID"),
  //         T1P1: sessionStorage.getItem("team1p1"),
  //         T1P2: "", // (sessionStorage.getItem("team1p2")),
  //         T2P1: sessionStorage.getItem("team2p1"),
  //         T2P2: "", // (sessionStorage.getItem("team2p2")),
  //         Winpoint: Winpoint.current.value, //(sessionStorage.getItem("winpoint")),
  //         Goldenpoint: Goldenpoint.current.value, // (sessionStorage.getItem("goldenpoint")),
  //       }),
  //     })
  //       .then((Response) => {
  //         if (!Response.ok) {
  //           throw Error("Could not update data.");
  //         } else {
  //           window.location.href = "/" + toLocation;
  //         }

  //         return Response.json();
  //       })
  //       .then(
  //         (Result) => {
  //           // sessionStorage.setItem("gameID",Result[0].Column1);
  //           // window.location.href = "/"+toLocation
  //         },
  //         (error) => {
  //           alert("Failed");
  //         }
  //       );
  //   } else if (
  //     Winpoint.current.value > 0 &&
  //     Goldenpoint.current.value > 0 &&
  //     Winpoint.current.value >= Goldenpoint.current.value
  //   ) {
  //     alert("'Golden Point' should be greater than 'Points to win'");
  //   } else {
  //     alert("Enter Valid Points");
  //   }
  // };


  
  const SaveGameDetails = (toLocation) => {
    if (
      parseInt(Winpoint.current.value) > 0 &&
      parseInt(Goldenpoint.current.value) > 0 &&
      parseInt(Winpoint.current.value) <= parseInt(Goldenpoint.current.value)
    ) {
      fetch(process.env.REACT_APP_API + "match/updatematchdtlsstart", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          MatchID: sessionStorage.getItem("MatchID"),
          T1P1: sessionStorage.getItem("team1p1"),
          //T1P2: sessionStorage.getItem("team1p2"),
          T2P1: sessionStorage.getItem("team2p1"),
         // T2P2: sessionStorage.getItem("team2p2"),
          Winpoint: parseInt(Winpoint.current.value),
          Goldenpoint: parseInt(Goldenpoint.current.value),
          NoOfSets: parseInt(Sets.current.value),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not update data.");
          } else {
            window.location.href = "/" + toLocation;
          }

          return Response.json();
        })
        .then(
          (Result) => {
            // sessionStorage.setItem("MatchID", Result[0].Column1);
            // //  sessionStorage.setItem("gameID",Result[0].Column1);
            // window.location.href = "/" + toLocation;
          },
          (error) => {
            alert("Failed");
          }
        );
    } else if (
      parseInt(Winpoint.current.value) > 0 &&
      parseInt(Goldenpoint.current.value) > 0 &&
      parseInt(Winpoint.current.value) >= parseInt(Goldenpoint.current.value)
    ) {
      alert(
        "'Golden Point' should be greater than or equel to 'Points to win'"
      );
    } else {
      alert("Enter Valid Points");
    }
  };

  const SubmitHandler = (event) => {
    event.preventDefault();
    SaveGameDetails("tosssingle");
  };

  return (
    <div>
      {/* <div className="">
        <div className="text-2xl text-center">Choose The Points</div>
        <div className="grid grid-cols-1 m-5">
          <div className=" justify-center items-center bg-slate-200 m-2 rounded-3xl ">
            <h2 className="text-xl p-5">Points to win</h2>
            <div className="text-center">
              <label></label>
              <input
                className="p-4 rounded-lg"
                type="number"
                placeholder="Enter Points"
                name="Winpoint"
                required
                defaultValue={gamesDetails[0]?.Winpoint}
                ref={Winpoint}
              />
            </div>{" "}
            <h2 className="text-xl p-5">Golden Point</h2>
            <div className="text-center pb-5">
              <label></label>
              <input
                className="p-4 rounded-lg"
                type="number"
                placeholder="Enter golden Point"
                name="Goldenpoint"
                required
                defaultValue={gamesDetails[0]?.Goldenpoint}
                ref={Goldenpoint}
              />
            </div>
            <div
              className="text-3xl  flex justify-center items-center card2 bg-blue-700 hover:bg-blue-600 text-white m-8 rounded-3xl cursor-pointer"
              onClick={() => {
                SaveGameDetails("tosssingle");

                // window.location.href = "/timer";
              }}
            >
              <h2>Go</h2>
            </div>
            <div
              className="text-3xl  flex justify-center items-center card2 bg-blue-700 hover:bg-blue-600 text-white m-8 rounded-3xl cursor-pointer"
              onClick={() => {
                SaveGameDetails("dashboard/createdgames");
                // window.location.href = "/userdash";
              }}
            >
              <h2>Save Game Details</h2>
            </div>
          </div>
        </div>

        <div className="button-container text-center ">
          <Link to="/dashboard/matches">
            {" "}
            <button className="loginButton" type="submit">
              Back
            </button>
          </Link>
        </div>
      </div> */}


      <>
        <div className="bg-gray-100">
          <div className="min-h-screen w-full p-6 flex justify-center items-center">
            <div className="w-full max-w-md">
              <form onSubmit={SubmitHandler}>
                <div className="bg-white border p-8 shadow rounded w-full mb-6">
                  <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
                    Choose The Points
                  </h1>

                  <div>
                    <fieldset className="mb-4">
                      <label className="block text-sm text-gray-900 mb-2">
                        Points to win
                      </label>
                      <input
                        id="Winpoint"
                        type="number"
                        className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                        name="Winpoint"
                        placeholder="Enter Points"
                        required
                        defaultValue={gamesDetails[0]?.Winpoint} //{gamesDetails[0]?.Winpoint}
                        ref={Winpoint}
                      />
                    </fieldset>

                    <fieldset className="mb-4">
                      <div className="w-full flex justify-between items-center">
                        <label
                          htmlFor="Golden Point"
                          className="block text-sm text-gray-900 mb-2"
                        >
                          Golden Point
                        </label>
                      </div>
                      <input
                        id="Golden Point"
                        className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                        type="number"
                        placeholder="Enter golden Point"
                        name="Goldenpoint"
                        required
                        defaultValue={gamesDetails[0]?.Goldenpoint}
                        ref={Goldenpoint}
                      />
                    </fieldset>
                    <fieldset className="mb-4">
                      <div className="w-full flex justify-between items-center">
                        <label
                          htmlFor="Golden Point"
                          className="block text-sm text-gray-900 mb-2"
                        >
                          Number of Sets
                        </label>
                      </div>
                      <select
                        className="block rounded-sm border bg-white py-2 px-3 text-sm"
                        type="number"
                        name="sets"
                        required
                        ref={Sets}
                        value={matchDetails?.NoOfSets}
                      >
                        <option value="3">3</option>
                        <option value="1">1</option>
                      </select>
                    </fieldset>
                  </div>

                  <div className="flex flex-wrap items-center text-center gap-x-2">
                    <button
                      type="submit"
                      className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Next
                    </button>
                    <button
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        SaveGameDetails("dashboard/matches");
                      }}
                      className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Save
                    </button>
                    {/* <button
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        scheduleMatch();
                      }}
                      className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Schedule
                    </button> */}
                    <button
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();
                        window.location.href = "/updateplayerssingle";
                      }}
                      className="inline-block bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default UpdatePointsSingle;
