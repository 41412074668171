import React, { useState, useEffect } from "react";

function ChooseServePercentage() {
  const [PlayedgameCount, setPlayedgameCount] = useState(0);
  const [ChoosetoServecount, setChoosetoServecount] = useState(0);

  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedcount, setisloadedcount] = useState(false);
  const [isloadedserveCount, setisloadedserveCount] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedcount && isloadedserveCount) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedcount, isloadedserveCount]); //add all the induvidual loading variables here

  useEffect(() => {
    try {
      fetch(process.env.REACT_APP_API + "game/getchoosetoservepercentage", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UID: sessionStorage.getItem("UserID"), //change this to userid of the user
        }),
      })
        .then((Response) => Response.json())
        .then((data) => {
          // alert(data[0].TeamTag+ 'thats it')
          setPlayedgameCount(data[0].Totalgamecount);
          setChoosetoServecount(data[0].ChoosetoServecount);
          setisloadedcount(true);
          setisloadedserveCount(true);
          console.log(data);
        })
        .then(
          (Math.round(ChoosetoServecount / PlayedgameCount) * 100).toFixed(2)
        );
    } catch (error) {
      setIsError(true);
    }
  }, []);

   //loading message
   if (!isloaded)
   return (
         <div className="spinner-border animate-spin inline-block w-5 h-5 border-t-2 rounded-t-full"></div>
   );
 //error message
 if (IsError)
   return (
         <div className="">
           <h2>Error...</h2>
         </div>
   );
 //default component
 if (isloaded && !IsError)
  return (
    <div>
      <p className="">
        {Number((ChoosetoServecount / PlayedgameCount) * 100).toFixed(2)}%
      </p>
    </div>
  );
}

export default ChooseServePercentage;
