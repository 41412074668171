import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";

function TimerSingle() {
  const Durationref = useRef();

  const [redirect, setRedirect] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (Durationref.current.value >= 90000) {
      alert("Please enter a value less than 90000");
    } else {
      sessionStorage.setItem("duration", Durationref.current.value);
      setRedirect(true);
    }
  };

  if (redirect) {
    return <Navigate to="/startmatchsingle" />;
  }

  return (
    <div>
      {/* <div className="">
        <div className="text-2xl text-center">
          Select Practice Time Duration
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 m-5">
            <div className=" justify-center items-center card bg-slate-200 m-2 rounded-3xl ">
              <h2 className="text-xl py-5">Custom Duration</h2>
              <div className="box-border">
                <div className="text-center">
                  <label></label>
                  <input
                    className="p-4 rounded-lg"
                    type="number"
                    placeholder="Enter Duration"
                    name="uname"
                    required
                    ref={Durationref}
                  />
                  <label> Seconds</label>
                </div>
              </div>
            </div>

            
            <button
              className="text-3xl flex justify-center items-center card2 bg-blue-700 hover:bg-blue-600 text-white m-2 rounded-3xl cursor-pointer"
              type="submit"
            >
              Go
            </button>
          </div>
        </form>
        <div className="button-container text-center ">
          <Link to="/whoservesingle">
            {" "}
            <button className="loginButton" type="submit">
              Back
            </button>
          </Link>
        </div>
      </div> */}

      <div className="bg-gray-100">
        <div className="min-h-screen w-full p-6 flex justify-center items-center">
          <div className="w-full max-w-md">
            <div className="bg-white border p-8 shadow rounded w-full mb-6">
              <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
                Select Practice Time Duration
              </h1>

              <div>
                <form onSubmit={handleSubmit}>
                  <fieldset className="mb-4 flex flex-wrap gap-2 justify-center items-center ">
                    <input
                      id="Winpoint"
                      type="number"
                      className="block rounded-sm border bg-white py-2 px-3 text-sm"
                      name="Winpoint"
                      placeholder="Enter Duration"
                      ref={Durationref}
                      autoFocus
                    />
                    <label className="block text-sm text-gray-900 mb-2">
                      Seconds
                    </label>
                  </fieldset>

                  <div className="flex flex-wrap justify-center text-center gap-x-2">
                    <button
                      type="submit"
                      className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Go
                    </button>

                    <button
                      type="button"
                      onClick={() => {
                        window.location.href = "/whoservesingle";
                      }}
                      className="inline-block bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                    >
                      Back
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimerSingle;
