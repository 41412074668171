import React from 'react'
import { Link } from 'react-router-dom'

function ChooseCourtOrServe() {
  return (
    <div>
    {/* <div className="">
    <div className="text-2xl text-center">Choose Court or Serve</div>
      <div className="grid grid-cols-2 m-5">
       
        <div
          
          className="text-3xl flex justify-center items-center card bg-slate-200 mx-2 rounded-3xl cursor-pointer"
          onClick={() => {sessionStorage.setItem("ChoosetoServe", true);
          window.location.href = "/switchcourt";}
        }
          
        >
         <h2>Choose to Serve</h2> 
        </div>
        <div
          
          className="text-3xl flex justify-center items-center card bg-slate-200 mx-2 rounded-3xl cursor-pointer"
          onClick={async () => {
            await new Promise(resolve=>{  sessionStorage.setItem("ChoosetoServe", false);
            resolve();});
            window.location.href="/switchcourt";
        }}
        >

         
          <h2>Choose the Court</h2>
        </div>

        
      </div>
      <div className="button-container text-center ">
          <Link to="/toss"> <button className="loginButton" type="submit" >Back</button></Link>
          </div>
    </div>
mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm */}

    <div className="bg-gray-100">
        <div className="min-h-screen w-full p-6 flex justify-center items-center">
          <div className="w-full max-w-xl">
            <div className="bg-white border p-8 shadow rounded w-full mb-6">
              <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
              Choose Court or Serve
              </h1>

              <div>
                <div className="grid grid-cols-1 md:grid-cols-2 m-5 gap-2">
                  <div
                    className=" justify-center items-center bg-green-200 mx-2 rounded-md cursor-pointer"
                    onClick={async () => {
                      await new Promise(resolve=>{  sessionStorage.setItem("ChoosetoServe", true);
                      resolve();});
                      window.location.href="/switchcourt";
                  }}
                  >
                    <h2 className="text-base font-normal p-3">Choose to Serve</h2>
                  </div>
                  <div
                    className=" justify-center items-center bg-green-200 mx-2 rounded-md cursor-pointer"
                    onClick={async () => {
                      await new Promise(resolve=>{  sessionStorage.setItem("ChoosetoServe", false);
                      resolve();});
                      window.location.href="/switchcourt";
                  }}
                  >
                    <h2 className="text-base font-normal p-3">Choose the Court</h2>
                  </div>
                </div>
              </div>

              <div className="flex justify-center gap-2 text-center ">
                <button
                  onClick={() => {
                    window.location.href = "/toss";
                  }}
                  className=" bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  )
}

export default ChooseCourtOrServe