import React, { useRef, useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const UserID = useRef();
  const Password = useRef();

  const [BtnText, setBtnText] = useState("Sign in");
  const [Errormsg, setErrormsg] = useState(false);

  const loginHandler = (e) => {
    setErrormsg(false);
    setBtnText("Signing in...");
    e.preventDefault();
    fetch(process.env.REACT_APP_API + "users/logincheck", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserID: UserID.current.value,
        Password: Password.current.value,
      }),
    })
      .then((res) => res.json())
      .then(async (Result) => {
        console.log(Result);
        if (Result.length === 0) {
          //alert("Invalid credentials. Please verify your login details and try again.");
          setErrormsg(true);
          setBtnText("Sign in");
        } else if (Result[0].UserID) {
          await new Promise((resolve) => {
            sessionStorage.setItem("UserID", Result[0].UserID);
            sessionStorage.setItem("UserData", JSON.stringify(Result[0]));
            console.log(Result[0].UserID);
            resolve();
          });

          window.location.href = "/dashboard/profile";
        }
      });
    //setBtnText("Sign in");
    //  .then(()=> window.location.href = "/dashboard")
  };

  return (
    // <div className="formcontainer">
    //   <h2 className="heading">Login</h2>
    //   <div className="form">

    //       <div className="input-container">
    //         <label>User ID or Email</label>
    //         <input type="text" placeholder="Enter User ID or Registered Email" name="uname" ref={UserID} required />
    //       </div>
    //       <div className="input-container">
    //         <label>Password </label>
    //         <input type="password" placeholder="Enter Password" name="pass" ref={Password} required />
    //       </div>
    //       <div className="button-container p-4">
    //       <button className="p-4" onClick={loginHandler}>
    //        Submit
    //       </button>
    //       </div>
    //       <p className="text-gray-400 p-4">Don't have an account? <span className="cursor-pointer text-blue-700" onClick={()=>{window.location.href="/signup"}}>Sign Up</span> </p>
    //       <p className="text-gray-400 p-4"><span className="cursor-pointer text-blue-700" onClick={()=>{window.location.href="/forgotpassword"}}>Forgot Password </span> </p>

    //   </div>
    // </div>
    <div className="bg-gray-100">
      <div className="min-h-screen w-full p-6 flex justify-center items-center">
        <div className="w-full max-w-xs">
          <div className="bg-white border p-8 shadow rounded w-full mb-6">
            <h1 className="mb-6 text-lg text-gray-900 font-thin">
              Login to your account
            </h1>

            <form onSubmit={loginHandler}>
              <fieldset className="mb-4">
                <label className="block text-sm text-gray-900 mb-2">
                  User ID or Email address
                </label>
                <input
                  id="email"
                  type="text"
                  className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                  name="email"
                  required
                  autofocus
                  ref={UserID}
                />
              </fieldset>

              <fieldset className="mb-4">
                <div className="w-full flex justify-between items-center">
                  <label
                    for="password"
                    className="block text-sm text-gray-900 mb-2"
                  >
                    Password
                  </label>
                  <a
                    className="text-xs font-thin text-blue no-underline hover:underline"
                    href="/forgotpassword"
                  >
                    Forgotten password?
                  </a>
                </div>
                <input
                  id="password"
                  type="password"
                  className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                  name="password"
                  required
                  ref={Password}
                />
              </fieldset>
              <div>
                {Errormsg ? (
                  <h6 className="text-red-600 pb-2 -mt-2 text-sm">
                    <span>
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>{" "}
                    Invalid Credentials
                  </h6>
                ) : (
                  ""
                )}
              </div>

              {/* <div className="pt-1 pb-5 text-sm text-gray-darker font-thin">
                <label>
                  <input
                    className="mr-1"
                    type="checkbox"
                    name="remember"
                    id="remember"
                  />{" "}
                  Remember me
                </label>
              </div> */}

              <button
                type="submit"
                className="block w-full bg-blue-600 text-white rounded-sm py-3 text-sm tracking-wide"
              >
                {BtnText}
              </button>
            </form>
          </div>

          <p className="text-center text-sm text-gray-600 font-thin">
            Don't have an account yet?{" "}
            <a
              href="/signup"
              className="text-blue-500 no-underline hover:underline"
            >
              Sign up
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
