import React, { useState, useEffect } from "react";
import ARS from "./ARS";
import ALS from "./ALS";
import BLS from "./BLS";
import BRS from "./BRS";
// import T1P1 from "./T1P1";
// import T1P2 from "./T1P2";
// import T2P1 from "./T2P1";
// import T2P2 from "./T2P2";
import Displayserver from "./Displayserver";
import Elapsed from "./Elapsed";
import ShuttleCounter from "./ShuttleCounter";
import "./Interface.css";


function InterfaceSingleCore() {
    // const [gameDetails, setgameDetails] = useState([]);
    const [PlayersDetails, setPlayersDetails]=useState({});
  
    const [gameDetails, setgameDetails] = useState({
      GameID: (sessionStorage.getItem("gameID")),
      TournamentID: "1",
      Round: "1",
      SetNo: "1",
      TossWinner: "1",
      FirstServer: "1",
      FirstReceiver: "1",
      Date: "11-11-2011",
      T1P1: "1",
      T1P2: "1",
      T2P1: "1",
      T2P2: "1",
      Winpoint: "1",
      Goldenpoint: "1",
      Master: "1",
    });
  
    const [team1p1disname,setteam1p1disname]=useState("Guest1");
    // const [team1p2disname,setteam1p2disname]=useState("Guest2");
     const [team2p1disname,setteam2p1disname]=useState("Guest2");
   //  const [team2p2disname,setteam2p2disname]=useState("Guest4");
  
  
  
   // checking loading starts-------------------------------------------------------------------------------------------
  
   //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);
  
  //const for checking individual contents loaded
  
      const [isloadedplayers, setisloadedplayers] = useState(false);
  
  //const for checking any error caught
      const [IsError, setIsError] = useState(false);
  
  //after successfully loading each content, setting isloaded true
      useEffect(() => {
        if (isloadedplayers) { //add all the induvidual loading variables here
          setisloaded(true);
        }
      }, [isloadedplayers]);//add all the induvidual loading variables here
  
      useEffect(() => {
  
        try {
            fetch(process.env.REACT_APP_API + "match/"+"getplayers", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  MatchID: sessionStorage.getItem("MatchID"),
              }),
            })
              .then((Response) => {
                if (!Response.ok) {
                  throw Error("Could not fetch data.");
                }
                return Response.json();
              })
              .then((data) => {
                console.log(data);
                setPlayersDetails(data[0]);
                
              })
              .then(() => {
                setisloadedplayers(true);
              })
              .catch((err) => {
                console.error(err);
                setisloaded(true);
                setIsError(true);
              });
          } catch (error) {
            setisloaded(true);
            setIsError(true);
          }
  
      }, []);
  
      useEffect(()=>{
        if (isloadedplayers===true){
          
          setteam1p1disname(PlayersDetails.T1P1Name);
       //   setteam1p2disname(PlayersDetails.T1P2Name);
          setteam2p1disname(PlayersDetails.T2P1Name);
      //    setteam2p2disname(PlayersDetails.T2P2Name);
        }
      
      },[isloadedplayers]);
  
      const [currentState, setcurrentState] = useState({});
  
    useEffect(() => {
      if (sessionStorage.getItem("summaryUndo") !== "true") {
        console.log("summary not true");
        sessionStorage.removeItem("score");
      }
  
      if (
        sessionStorage.getItem("summaryUndo") === "true" &&
        sessionStorage.getItem("score")
      ) {
        setScoreBoard(JSON.parse(sessionStorage.getItem("score")));
       // setplacePlayers(JSON.parse(sessionStorage.getItem('placePlayers')));
       // getlastscore();
       setcurrentState(JSON.parse(sessionStorage.getItem('currentState')));
       console.log("");
        console.log("undo worked" + sessionStorage.getItem("score").length);
        const t1 = scoreBoard.filter((e) => e.teamTag === "A").length;
        const t2 = scoreBoard.filter((e) => e.teamTag === "B").length;
        sett1Score(t1);
        sett2Score(t2);
        sessionStorage.setItem("summaryUndo", false);
      }
  
      fetch(
        process.env.REACT_APP_API + "game/" + sessionStorage.getItem("gameID")
      ) // change the value of gameid
        .then((Response) => Response.json())
        .then((data) => {
          setgameDetails(data[0]);
          // alert(data[0])
        });
  
      fetch(process.env.REACT_APP_API + "game/updatestartedtime", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          GameID: sessionStorage.getItem("gameID"),
        }),
      })
        .then((res) => res.json())
        .then((result) => console.log(result))
        .catch((err) => console.error(err));
    }, []);
  
  
    
    useEffect(() => {
      // fetch(process.env.REACT_APP_API + "users/"+"readusers", {
      //   method: "POST",
      //   headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json'
      //   },
      //   body:JSON.stringify({
          
      //     "UserID": gameDetails.T1P1
      //   })
      // })
      //   .then((Response) => Response.json())
      //   .then((data) => {
      //    // alert(data[0]+ 'thats it')
      //    setteam1p1disname(data[0].FirstName);
         
      // });
    
      // fetch(process.env.REACT_APP_API + "users/"+"readusers", {
      //   method: "POST",
      //   headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json'
      //   },
      //   body:JSON.stringify({
          
      //     "UserID": gameDetails.T1P2
      //   })
      // })
      //   .then((Response) => Response.json())
      //   .then((data) => {
      //    // alert(data[0]+ 'thats it')
      //   // setteam1p2disname(data[0].FirstName);
      // });
    
      // fetch(process.env.REACT_APP_API + "users/"+"readusers", {
      //   method: "POST",
      //   headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json'
      //   },
      //   body:JSON.stringify({
          
      //     "UserID": gameDetails.T2P1
      //   })
      // })
      //   .then((Response) => Response.json())
      //   .then((data) => {
      //    // alert(data[0]+ 'thats it')
      //    setteam2p1disname(data[0].FirstName);
      // });
    
      // fetch(process.env.REACT_APP_API + "users/"+"readusers", {
      //   method: "POST",
      //   headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json'
      //   },
      //   body:JSON.stringify({
          
      //     "UserID": gameDetails.T2P2
      //   })
      // })
      //   .then((Response) => Response.json())
      //   .then((data) => {
      //    // alert(data[0]+ 'thats it')
      //   // setteam2p2disname(data[0].FirstName);
      // });
  
      if ((gameDetails.GameWinner)){
  
        console.log("gamewinner exists")
  
        fetch(process.env.REACT_APP_API + "game/updategamewinner", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "GameID":sessionStorage.getItem("gameID"),
          
            "GameWinner": null
          }),
        })
          .then((res) => res.json())
          .then(
            (Result) => {
             // sessionStorage.setItem("gameID",Result[0].Column1);
              console.log(Result);
             // window.location.href = "/"+toLocation
            },
            (error) => {
              alert("Failed");
            }
          );
    
  
  
       };
    }, [gameDetails]);
  
  
  
  
    useEffect(() => {
  
      console.log("GAME DETAILS CHANGED" + (JSON.stringify(gameDetails)));
  
      if(Object.keys(currentState).length !== 0){
        SetServerTag(currentState.ServerTag);
        SetReceiverTag(currentState.ReceiverTag);
      }
   else if(gameDetails){
      SetServerTag(gameDetails.FirstServer);
      SetReceiverTag(gameDetails.FirstReceiver);
   }
  
  
      //start the placing of players by triggering the placing of server
      setTimeout(() => {
        setinitialServerPlaceTrigger(initialServerPlaceTrigger + 1);
      }, 120);
  
      setWinningScore(gameDetails.Winpoint);
      setgoldenpoint(gameDetails.Goldenpoint);
  
      let ddd = 120;
    }, [gameDetails]);
  
    const labels = [
      {
        text: "Clear / Lob",
      },
      {
        text: "Drop Shot",
      },
      {
        text: "Smash",
      },
      {
        text: "Serve",
      },
      {
        text: "Net Shot",
      },
      {
        text: "Net Kill",
      },
      {
        text: "Net Lift",
      },
      {
        text: "Drive",
      },
    ];
  
    let team1p1 = gameDetails.T1P1;
  //  let team1p2 = gameDetails.T1P2;
    let team2p1 = gameDetails.T2P1;
  //  let team2p2 = gameDetails.T2P2;
  
    const [t1Score, sett1Score] = useState(0);
    const [t2Score, sett2Score] = useState(0);
    const [fp, setFp] = useState(false);
    const [t1Clicked, sett1Clicked] = useState(false);
    const [hClicked, setHClicked] = useState(false);
    const [shotClicked, setShotClicked] = useState(false);
    const [lcpSelected, setLcpSelected] = useState(false);
  
    let index = 1;
  
    const [ServerTag, SetServerTag] = useState(gameDetails.FirstServer);
    const [ReceiverTag, SetReceiverTag] = useState(gameDetails.FirstReceiver);
    const [initialServerPlaceTrigger, setinitialServerPlaceTrigger] = useState(1);
    const [initialReceiverPlaceTrigger, setinitialReceiverPlaceTrigger] =
      useState(1);
    const [serverChangeTrigger, setserverChangeTrigger] = useState(1);
  
    const [placePlayers, setplacePlayers] = useState({
      AL: "T1P1",
      AR: "", //CHANGED
      BR: "T2P1",
      BL: "", //CHANGED
    });
  
    const [postDataTrigger, setpostDataTrigger] = useState(1);
  
    const endgame=async()=>{
      console.log({...placePlayers, ServerTag:ServerTag, ReceiverTag:ReceiverTag});
      sessionStorage.setItem('currentState', JSON.stringify({ ...placePlayers, ServerTag: ServerTag, ReceiverTag: ReceiverTag }));
       
  
        window.location.href = "/endgamesingle";
  
    };
  
    const [lastScore, setlastScore] = useState([]);
  
  
    const getlastscore=()=>{
  
      return fetch(process.env.REACT_APP_API + "scoreboard/getlastscore", {
         method: "POST",
         headers: {
           Accept: "application/json",
           "Content-Type": "application/json",
         },
         body: JSON.stringify({
           GameID: (sessionStorage.getItem("gameID")),
         }),
       })
         .then((response) => {
           if (!response.ok) {
             throw new Error('Network response was not ok');
           }
           return response.json();
         })
         .then(
           (Result) => {
             setlastScore(Result[0]);
             console.log(Result[0]);
            // setScore(Result[0]);
             
           }
         )
         .catch(error => {
           console.error('Error fetching lastScore API:', error);
           alert("Failed to get last score");
           throw error;
         });
         
     };
   
   const deleteLastScorefromDB=()=>{
     console.log("deleting last score from db");
     return fetch(process.env.REACT_APP_API + "scoreboard/" + gameDetails.GameID, {
       method: "DELETE",
       headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
         GameID: (sessionStorage.getItem("gameID")),
       }),
     })
     .then(response => {
       if (!response.ok) {
         throw new Error('Network response was not ok');
       }
       return response.json();
     })
       .then(
         (Result) => {
           console.log('Successfully deleted last score from db');
           alert(Result);
   
         }
       )
       .catch(error => {
         console.error('Error deleting last score from db:', error);
         throw error;
       });
   };
   
  
    async function deleteLastScoreFn() {
  
      try {
        await getlastscore();
        await deleteLastScorefromDB();
        // the following lines will excecute after succesfully calling the api functions
        
          //-----------delete the last entered score form the state
        scoreBoard.pop();
    
        const t1 = scoreBoard.filter((e) => e.teamTag === "A").length;
        const t2 = scoreBoard.filter((e) => e.teamTag === "B").length;
        sett1Score(t1);
        sett2Score(t2);
        //end of-----------delete the last entered score form the state
    
      } catch (error) {
        // Handle any errors
        console.error('Error in API calls:', error);
        alert('Failed to delete the last score. Try Again..!');
    
      }
    
    };
  
  
  
  
  
    const undofunction=()=>{
  
      //set shuttle count to zero, if game doesn't start
  
      const t1 = scoreBoard.filter((e) => e.teamTag === "A").length;
      const t2 = scoreBoard.filter((e) => e.teamTag === "B").length;
        if (t1+t2===0){
          fetch(process.env.REACT_APP_API + "game/shuttles", {
            method: "PUT",
            headers: {
              'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              "Shuttles": 0,
              "GameID": gameDetails.GameID
            }) ,
          })
            .then((res) => res.json())
            .then(result => console.log(result))
            .then(()=>window.location.href="/timersingle")
            .catch(err=>console.error(err))
      
        };
        //end of ----- set shuttle count to zero, if game doesn't start
  
        //-------------function for undo the last changes in position of players and server and receiver tags
        if (fp===true){
          setFp(false);
          sett1Clicked(false);
          setShotClicked(false);
          setLcpSelected(false);
          setHClicked(false);
          setShotClicked(false);
        }
        else{
  
          console.log("the condition for deleting score");
          deleteLastScoreFn();
  
        };  
      
  
  
  
        //-------------end of function for undo the last changes in position of players and server and receiver tags
  
    };
  
    useEffect(() => {
      if (lastScore.id===undefined){
        console.log("last Score is null >> "+lastScore.id)
      }
      else{
        console.log("last Score id is"+lastScore.id);
        SetServerTag(lastScore.Server);
        SetReceiverTag(lastScore.Receiver);
        setplacePlayers({
          AL: lastScore.AL,
          AR: lastScore.AR,
          BR: lastScore.BR,
          BL: lastScore.BL,
        })
      };
    },[lastScore])
  
    //initial placement of players---------------------------------------------------------------------------------
    //following useeffect excecutes when the value of initialServerPlaceTrigger changes
  
    useEffect(() => {
      console.log("initaial server trigger "+initialServerPlaceTrigger);
      console.log(currentState ? "current state true"+currentState.length:"current state false");
      if(Object.keys(currentState).length !== 0){
        setplacePlayers({
          AL: currentState.AL,
          AR: currentState.AR,
          BR: currentState.BR,
          BL: currentState.BL,
        })
      }
  
      else if (ServerTag === "T1P1") {
        setplacePlayers({
          AL: "",
          AR: "T1P1",
          BR: "T2P1",
          BL: "",
        });
        //alert("server T1P1 set AR");
      } else if (ServerTag === "T2P1") {
        setplacePlayers({
          AL: "",
          AR: "T1P1",
          BR: "T2P1",
          BL: "",
        });
        //alert("server T2P1 set BR");
      } 
      setinitialReceiverPlaceTrigger(initialReceiverPlaceTrigger + 1);
    }, [initialServerPlaceTrigger]);
  
    useEffect(() => {
      console.log("initaial reciver trigger "+initialReceiverPlaceTrigger);
      console.log(currentState);
      if(Object.keys(currentState).length !== 0){
        setplacePlayers({
          AL: currentState.AL,
          AR: currentState.AR,
          BR: currentState.BR,
          BL: currentState.BL,
        })
      }
      else if (ReceiverTag === "T1P1") {
        setplacePlayers({
          AL: "",
          AR: "T1P1",
          BR: "T2P1",
          BL: "",
        });
        //alert("receiver T1P1 set AR");
      } else if (ReceiverTag === "T2P1") {
        setplacePlayers({
          AL: "",
          AR: "T1P1",
          BR: "T2P1",
          BL: "",
        });
        //alert("receiver T2P1 set BR");
      } 
    }, [initialReceiverPlaceTrigger]);
    //End of "initial placement of players"---------------------------------------------------------------------------------
  
    const [scoreBoard, setScoreBoard] = useState([]);
    const [score, setScore] = useState({
      GameID: (sessionStorage.getItem("gameID")),
      point: 1,
  
      teamTag: "",
      lastContactPlayer: "",
      fh: false,
      bh: false,
      typeOfShot: "",
      position: "",
      server:"",
      receiver:"",
      AL:"",
      AR:"",
      BL:"",
      BR:"",
      CreatedBy:(sessionStorage.getItem("UserID")),
      ModifiedBy:(sessionStorage.getItem("UserID"))
    });
    const [winningScore, setWinningScore] = useState(gameDetails.Winpoint);
    const [goldenpoint, setgoldenpoint] = useState(gameDetails.Goldenpoint);
    const [instruction, setInstruction] = useState("Click on +1 to add a point ");
    const [showScore, setShowScore] = useState([]);
  
    // useEffect(() => console.table(score), [score]);
    // useEffect(() => console.table(scoreBoard), [scoreBoard]);
  
    useEffect(() => {
      // if (sessionStorage.getItem("score")) {
      //   setShowScore(JSON.parse(sessionStorage.getItem("score")));
      //  alert((sessionStorage.getItem("score").length))
      // }
      // postData();
    }, [scoreBoard]);
  
    // console.log(showScore);
  
    const setPosition = (position) => {
      index++;
      //alert("Score has been added");
      setScore({ ...score, position: position, server: ServerTag, receiver: ReceiverTag, AL: placePlayers.AL, AR: placePlayers.AR , BL: placePlayers.BL, BR: placePlayers.BR }); //???????????????????????????????????????????
   
      setScoreBoard((e) => e.concat({ ...score, position }));
      setFp(false);
      sett1Clicked(false);
      setShotClicked(false);
      setLcpSelected(false);
      setHClicked(false);
      setShotClicked(false);
      console.log(score.position);
      // setScore({
      //   point: 1,
      //   index: index,
      //   teamTag: "",
      //   lastContactPlayer: "",
      //   fh: false,
      //   bh: false,
      //   typeOfShot: "",
      //   position: "",
      // });
  
      setTimeout(() => {
        setInstruction("Click on +1 to add a point ");
      }, 0);
  
      setpostDataTrigger(postDataTrigger + 1);
    };
  
    useEffect(() => {
      if (postDataTrigger > 1) {
        postData();
      }
    }, [postDataTrigger]);
  
    const [DurationTrigger, setDurationTrigger]=useState(0);
  
    const postData = () => {
      // sessionStorage.setItem("score", JSON.stringify(scoreBoard));
      // console.log(JSON.stringify(scoreBoard));
  
      fetch(process.env.REACT_APP_API + "scoreboard", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(score),
      })
        .then((res) => res.json())
        .then(
          (Result) => {
            console.log(Result);
            setDurationTrigger(DurationTrigger+1)
          },
          (error) => {
            alert("Failed");
          }
        )
        .then(
         
        )
        ;
    };
  
    useEffect(() => {
      const t1 = scoreBoard.filter((e) => e.teamTag === "A").length;
      const t2 = scoreBoard.filter((e) => e.teamTag === "B").length;
      sett1Score(t1);
      sett2Score(t2);
      console.log("scoreboard changed")
  
      if (Math.abs(t1 - t2) < 2) {
        setWinningScore((e) => {
          if (t1 === e || t2 === e) {
            if (e < goldenpoint) {
              return e + 1;
            }
            if (t1 === e) {
              sessionStorage.setItem('currentState', JSON.stringify({ ...placePlayers, ServerTag: ServerTag, ReceiverTag: ReceiverTag }));
              alert({team1p1disname}+" Won!!");
              window.location.reload();
              //  postData();
              window.location.href = "/summarysingle";
  
              setScore({
                point: 1,
                index: index,
                teamTag: "",
                lastContactPlayer: "",
                fh: false,
                bh: false,
                typeOfShot: "",
                position: "",
              });
              sessionStorage.setItem("score", JSON.stringify(scoreBoard));
            }
            if (t2 === e) {
              sessionStorage.setItem('currentState', JSON.stringify({ ...placePlayers, ServerTag: ServerTag, ReceiverTag: ReceiverTag }));
              alert({team2p1disname}+" Won!!");
              window.location.reload();
              //  postData();
              window.location.href = "/summarysingle";
  
              setScore({
                point: 1,
                index: index,
                teamTag: "",
                lastContactPlayer: "",
                fh: false,
                bh: false,
                typeOfShot: "",
                position: "",
              });
              sessionStorage.setItem("score", JSON.stringify(scoreBoard));
            }
            return e;
          }
          return e;
        });
      } else {
        setWinningScore((e) => {
          if (t1 === e) {
            sessionStorage.setItem('currentState', JSON.stringify({ ...placePlayers, ServerTag: ServerTag, ReceiverTag: ReceiverTag }));
            alert({team1p1disname}+" Won!!");
            window.location.reload();
            //  postData();
            window.location.href = "/summarysingle";
  
            setScore({
              point: 1,
              index: index,
              teamTag: "",
              lastContactPlayer: "",
              fh: false,
              bh: false,
              typeOfShot: "",
              position: "",
            });
            sessionStorage.setItem("score", JSON.stringify(scoreBoard));
          }
          if (t2 === e) {
            sessionStorage.setItem('currentState', JSON.stringify({ ...placePlayers, ServerTag: ServerTag, ReceiverTag: ReceiverTag }));
            alert({team2p1disname}+" Won!!");
            window.location.reload();
            //  postData();
            window.location.href = "/summarysingle";
  
            setScore({
              point: 1,
              index: index,
              teamTag: "",
              lastContactPlayer: "",
              fh: false,
              bh: false,
              typeOfShot: "",
              position: "",
            });
            sessionStorage.setItem("score", JSON.stringify(scoreBoard));
          }
          return e;
        });
      }
    }, [scoreBoard]);
  
    useEffect(() => {
      //code for place players while scoring-----------------------------------------------------------------------------------------------
      //alert("t1score or t2score changed")
      if (t1Score !== 0 || t2Score !== 0) {
        if (score.teamTag === "A") {
          if (t1Score % 2 === 1) {
            if (ServerTag === "T1P1") {
              setplacePlayers({
                AL: "T1P1",
                AR: "",
                BR: "",
                BL: "T2P1",
              });
  
              //alert("A odd p1 AL");
              //alert(placePlayers.AL);
              // SetServerTag(placePlayers.AL);
              // SetReceiverTag(placePlayers.BL);
              // SetServerTag(placePlayers.AL);
            } else if (ServerTag === "T2P1") {
              setplacePlayers({
                AL: "T1P1",
                AR: "",
                BR: "",
                BL: "T2P1",
              });
              //  SetServerTag(placePlayers.AL);
              //  SetReceiverTag(placePlayers.BL);
              //   SetServerTag(placePlayers.AL);
  
              //alert("A odd p3 AL");
            } 
          } else if (t1Score % 2 === 0) {
            if (ServerTag === "T1P1") {
              setplacePlayers({
                AL: "",
                AR: "T1P1",
                BR: "T2P1",
                BL: "" ,
              });
              //  SetServerTag(placePlayers.AR);
              //  SetReceiverTag(placePlayers.BR);
              //  SetServerTag(placePlayers.AR);
  
              //alert("A even p1 AR");
            } else if (ServerTag === "T2P1") {
              setplacePlayers({
                AL: "",
                AR: "T1P1",
                BR: "T2P1",
                BL: "",
              });
              //  SetServerTag(placePlayers.AR);
              //  SetReceiverTag(placePlayers.BR);
              //  SetServerTag(placePlayers.AR);
  
              //alert("A even p1 AR");
            } 
          }
        } else if (score.teamTag === "B") {
          if (t2Score % 2 === 1) {
            if (ServerTag === "T2P1") {
              setplacePlayers({
                AL: "T1P1",
                AR: "",
                BL: "T2P1",
                BR: "",
              });
              //  SetServerTag(placePlayers.BL);
              //  SetReceiverTag(placePlayers.AL);
              //  SetServerTag(placePlayers.BL);
              //alert("B odd p3 BL");
            } else if (ServerTag === "T1P1") {
              setplacePlayers({
                AL: "T1P1",
                AR: "",
                BL: "T2P1",
                BR: "",
              });
              //  SetServerTag(placePlayers.BL);
              //  SetReceiverTag(placePlayers.AL);
              //  SetServerTag(placePlayers.BL);
  
              //alert("B odd p1 BL");
            } 
          } else if (t2Score % 2 === 0) {
            if (ServerTag === "T2P1") {
              setplacePlayers({
                AL: "",
                AR: "T1P1",
                BL: "",
                BR: "T2P1",
              });
              //  SetServerTag(placePlayers.BR);
              //  SetReceiverTag(placePlayers.AR);
              //  SetServerTag(placePlayers.BR);
  
              //alert("B even P3 BR");
            } else if (ServerTag === "T1P1") {
              setplacePlayers({
                AL: "",
                AR: "T1P1",
                BL: "",
                BR: "T2P1",
              });
              //  SetServerTag(placePlayers.BR);
              //  SetReceiverTag(placePlayers.AR);
              //  SetServerTag(placePlayers.BR);
  
              //alert("B even P1 BR");
            }
          }
        }
        //end of ----code for place players while scoring-----------------------------------------------------------------------------------------------
        ////alert("end of place players while scoring");
        setserverChangeTrigger(serverChangeTrigger + 1);
      } else {
      }
    }, [t1Score, t2Score]);
  
    //------setting server/reciever------------------------------------------------------------------------------
    useEffect(() => {
      if (score.teamTag === "A") {
        if (t1Score % 2 === 1) {
          if (ServerTag === "T1P1") {
            // setplacePlayers({
            //   AL: "T1P1",
            //   AR: "T1P2",
            //   BR: placePlayers.BR,
            //   BL: placePlayers.BL,
            // });
            setTimeout(() => {
              //alert("A odd p1 AL");
              //alert(placePlayers.AL);
              SetServerTag("T1P1"); //first line(this line) is not actually being executed, this can be considered as a dummy line
              SetReceiverTag("T2P1");
              SetServerTag("T1P1");
            }, 500);
          } else if (ServerTag === "T2P1") {
            // setplacePlayers({
            //   AL: placePlayers.AL,
            //   AR: placePlayers.AR,
            //   BR: placePlayers.BR,
            //   BL: placePlayers.BL,
            // });
            SetServerTag("T1P1");//first line(this line) is not actually being executed, this can be considered as a dummy line
            SetReceiverTag("T2P1");
            SetServerTag("T1P1");
  
            //alert("A odd p3 AL");
          }
        } else if (t1Score % 2 === 0) {
          if (ServerTag === "T1P1") {
            // setplacePlayers({
            //   AL: "T1P2",
            //   AR: "T1P1",
            //   BR: placePlayers.BR,
            //   BL: placePlayers.BL,
            // });
            SetServerTag("T1P1");//first line(this line) is not actually being executed, this can be considered as a dummy line
            SetReceiverTag("T2P1");
            SetServerTag("T1P1");
  
            //alert("A even p1 AR");
          } else if (ServerTag === "T2P1") {
            // setplacePlayers({
            //   AL: placePlayers.AL,
            //   AR: placePlayers.AR,
            //   BR: placePlayers.BR,
            //   BL: placePlayers.BL,
            // });
            SetServerTag("T1P1");//first line(this line) is not actually being executed, this can be considered as a dummy line
            SetReceiverTag("T2P1");
            SetServerTag("T1P1");
  
            //alert("A even p1 AR");
          }
        }
      } else if (score.teamTag === "B") {
        if (t2Score % 2 === 1) {
          if (ServerTag === "T2P1") {
            // setplacePlayers({
            //   AL: placePlayers.AL,
            //   AR: placePlayers.AR,
            //   BL: "T2P1",
            //   BR: "T2P2",
            // });
            SetServerTag("T2P1");//first line(this line) is not actually being executed, this can be considered as a dummy line
            SetReceiverTag("T1P1");
            SetServerTag("T2P1");
            //alert("B odd p3 BL");
          } else if (ServerTag === "T1P1") {
            // setplacePlayers({
            //   AL: placePlayers.AL,
            //   AR: placePlayers.AR,
            //   BL: placePlayers.BL,
            //   BR: placePlayers.BR,
            // });
            SetServerTag("T2P1");//first line(this line) is not actually being executed, this can be considered as a dummy line
            SetReceiverTag("T1P1");
            SetServerTag("T2P1");
  
            //alert("B odd p1 BL");
          } 
        } else if (t2Score % 2 === 0) {
          if (ServerTag === "T2P1") {
            // setplacePlayers({
            //   AL: placePlayers.AL,
            //   AR: placePlayers.AR,
            //   BL: "T2P2",
            //   BR: "T2P1",
            // });
            SetServerTag("T2P1");//first line(this line) is not actually being executed, this can be considered as a dummy line
            SetReceiverTag("T1P1");
            SetServerTag("T2P1");
  
            //alert("B even P3 BR");
          } else if (ServerTag === "T1P1") {
            // setplacePlayers({
            //   AL: placePlayers.AL,
            //   AR: placePlayers.AR,
            //   BL: placePlayers.BL,
            //   BR: placePlayers.BR,
            // });
            SetServerTag("T2P1");//first line(this line) is not actually being executed, this can be considered as a dummy line
            SetReceiverTag("T1P1");
            SetServerTag("T2P1");
  
            //alert("B even P1 BR");
          } 
        }
      }
    }, [serverChangeTrigger]);
  
    //----end of ----------setting server/receiver-----------------------------------------------------------------------------
  const [Pause,setPause]=useState(false);
    return (
      <>
        <div className="w-[95%] mx-auto pt-4 interface">
          {/* <div>
            <Elapsed />
          </div> */}
          <div className="flex flex-col-2 justify-center items-center font-medium text-base">
            <div className="ml-8 mt-5 text-right absolute left-0">
              <button className=""onClick={undofunction}>{((scoreBoard.filter((e) => e.teamTag).length)===0)&&(fp===false)?"Back":"Undo"}</button>
              <button className="ml-5" onClick={endgame}>End Game</button>
              <button className="ml-5" onClick={()=>{setPause(!Pause);}}>{`${Pause ? "Resume Game" : "Pause Game"}`}</button>
            </div>
            <div className="ml-8">
              <div className="flex">
                <div>
                  <Elapsed Pause={Pause} DurationTrigger={DurationTrigger}/>
                </div>
              </div>
            </div>
            <div className="ml-8 text-right">
              <div className="flex">
                <p>Points to win</p>
                <p className="ml-8 font-bold text-right ">{winningScore}</p>
              </div>
              <div className="flex">
                <p>Golden Point</p>
                <p className="ml-8 font-bold  text-right">{goldenpoint}</p>
              </div>
              <div className="flex">
                <p className="font-bold">Set No. {gameDetails.SetNo}</p>
              </div>
            </div>
            <div className="ml-8 mt-5 text-right absolute right-0">
              <ShuttleCounter GameIDNumber={gameDetails.GameID} />
            </div>
          </div>
          <div className="flex flex-col-2 justify-center items-center font-medium text-base">
            <div className="m-2">
              <div className="flex">
                <p className="ml-8 font-bold text-2xl  p-2">
                  {team1p1disname} {" "}
                </p>
                <p className="ml-8 font-bold text-3xl  p-2 border-y-4 border-l-4 border-r-2">{`${
                  t1Score < 10 ? "0" + t1Score : t1Score
                }`}</p>
                <p className="mr-8 font-bold text-3xl p-2 border-y-4 border-l-2 border-r-4">{`${
                  t2Score < 10 ? "0" + t2Score : t2Score
                }`}</p>
                <p className="mr-8 font-bold text-2xl p-2">
                  {team2p1disname}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className=" grid grid-cols-field ">
            <div className="flex flex-col">
              {labels.map((e) => (
                <div
                  key={e.text}
                  onClick={() => {
                    setShotClicked(true);
                    setScore({ ...score, typeOfShot: e.text });
                    setInstruction("Select the Position of the shuttlecock");
                    if (e.text==="Net Shot"){setPosition("Net")};
                  }}
                  className={`${
                    hClicked && !shotClicked
                      ? "flex justify-center cursor-pointer items-center border-2 border-white-200 bg-blue-600 hover:bg-blue-500"
                      : "flex justify-center opacity-50 cursor-not-allowed  pointer-events-none items-center border-2 border-yellow-300"
                  } `}
                >
                  <p className="text-white font-bold p-6 text-center">
                    {e.text}{" "}
                  </p>
                </div>
              ))}
            </div>
            <div
              onClick={() => {
                setFp(true);
                setScore({ ...score, teamTag: "A" });
                setInstruction("Select the last contact player");
              }}
              className={`${
                fp
                  ? "bg-green-500 opacity-50 cursor-not-allowed pointer-events-none hover:bg-green-600 flex justify-center items-center"
                  : "bg-green-500 hover:bg-green-600 cursor-pointer flex justify-center items-center"
              } `}
            >
              <p className="text-white">+1</p>
            </div>
            <div
              className={` ${
                t1Clicked && !shotClicked
                  ? "cursor-not-allowed  grid place-items-center text-white text-3xl font-bold font-mono"
                  : " grid place-items-center text-white text-3xl font-bold font-mono  "
              } `}
            >
              <div className="grid place-items-center   grid-cols-innerField  h-full w-full ">
                <div className="h-full w-full grid grid-rows-innerInnerField  ">
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  ></div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  ></div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  ></div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                </div>
                <div className="h-full w-10 grid grid-rows-innerInnerField  ">
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                  <div
                    onClick={() => setPosition("A_BL")}
                    className={`${
                      !fp
                        ? "h-full  bg-red-300 cursor-not-allowed pointer-events-none border-l-2 border-t-2 border-white "
                        : shotClicked
                        ? "h-full  bg-red-300 cursor-pointer border-l-2 border-t-2 border-white "
                        : "h-full  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-l-2 border-t-2 border-white "
                    } `}
                  />
  
                  <div className="h-full ">
                    <div
                      className={`${
                        !fp
                          ? "h-full   bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
                          : shotClicked
                          ? "h-full grid grid-rows-2  bg-red-300 cursor-pointer border-t-2 border-white "
                          : "h-full   bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
                      } `}
                    >
                      <p
                        onClick={() => setPosition("A_LSLLL")}
                        className={`${
                          shotClicked
                            ? "h-full w-full border-2 border-white"
                            : "hidden"
                        } `}
                      ></p>
                      <p
                        onClick={() => setPosition("A_LSLLR")}
                        className={`${
                          shotClicked
                            ? "h-full w-full border-2 border-white"
                            : "hidden"
                        } `}
                      ></p>
                    </div>
                  </div>
                  <div className="h-full ">
                    <div
                      className={`${
                        !fp
                          ? "h-full   bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
                          : shotClicked
                          ? "h-full grid grid-rows-2  bg-red-300 cursor-pointer border-t-2 border-white "
                          : "h-full   bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
                      } `}
                    >
                      <p
                        onClick={() => setPosition("A_LSLRL")}
                        className={`${
                          shotClicked
                            ? "h-full w-full border-2 border-white"
                            : "hidden"
                        } `}
                      ></p>
                      <p
                        onClick={() => setPosition("A_LSLRR")}
                        className={`${
                          shotClicked
                            ? "h-full w-full border-2 border-white"
                            : "hidden"
                        } `}
                      ></p>
                    </div>
                  </div>
                  <div
                    onClick={() => setPosition("A_BR")}
                    className={`${
                      !fp
                        ? "h-full  bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
                        : shotClicked
                        ? "h-full  bg-red-300 cursor-pointer border-2 border-white "
                        : "h-full  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
                    } `}
                  ></div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                </div>
                <div className="h-full w-full grid grid-rows-innerInnerField ">
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                  <div
                    className={`${
                      !fp
                        ? "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
                        : shotClicked
                        ? "h-full grid grid-cols-3  bg-red-300 cursor-pointer border-2 border-white "
                        : "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
                    } `}
                  >
                    <p
                      onClick={() => setPosition("A_SLLB")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                    <p
                      onClick={() => setPosition("A_SLLM")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                    <p
                      onClick={() => setPosition("A_SLLF")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                  </div>
                  {shotClicked ? (
                    <div
                      className={`${
                        (fp && !lcpSelected) || shotClicked
                          ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                          : fp
                          ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                          : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                      }`}
                    >
                      <div
                        className={`${
                          !shotClicked
                            ? "hidden"
                            : "relative pointer-events-auto h-full w-full grid grid-cols-2 grid-rows-2 bg-blue-700 "
                        } `}
                      >
                        <p
                          onMouseUp={() => setPosition("A_LSCBL")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("A_LSCFL")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("A_LSCBR")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("A_LSCFR")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                      </div>
                      <p
                        className={`${
                          shotClicked
                            ? "hidden"
                            : "text=3xl flex justify-center items-center"
                        } `}
                      >
                        {/* P1 */}
                        <ALS
                          PPlayer={placePlayers}
                          server={ServerTag}
                          receiver={ReceiverTag}
                          team1p1Name={team1p1disname}
                        //  team1p2Name={team1p2disname}
                          team2p1Name={team2p1disname}
                        //  team2p2Name={team2p2disname}
                        />
                        {/* {placePlayers.AL} */}
                      </p>
                    </div>
                  ) : (
                    <div
                      className={`${
                        (fp && !lcpSelected) || shotClicked
                          ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                          : fp
                          ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                          : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                      }`}
                      onClick={() => {
                        console.log(shotClicked);
                        if (!shotClicked) {
                         // alert("shotClicked" + shotClicked);
                          sett1Clicked(true);
                          setInstruction("Select Forehand or Backhand");
                          setLcpSelected(true);
                         // alert("lcp true t1p1");
                          //setScore({ ...score, lastContactPlayer: placePlayers.AL });
                          setScore({ ...score, lastContactPlayer:  gameDetails.T1P1 });
                         
                        }
                      }}
                    >
                      <p
                        className={`${
                          shotClicked
                            ? "hidden"
                            : "text=3xl flex justify-center items-center"
                        } `}
                      >
                        {/* P1 */}
                        <ALS
                          PPlayer={placePlayers}
                          server={ServerTag}
                          receiver={ReceiverTag}
                          team1p1Name={team1p1disname}
                        //  team1p2Name={team1p2disname}
                          team2p1Name={team2p1disname}
                        //  team2p2Name={team2p2disname}
                        />
                        {/* {placePlayers.AL} */}
                      </p>
                    </div>
                  )}
  
                  {shotClicked ? (
                    <div
                      className={`${
                        (fp && !lcpSelected) || shotClicked
                          ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                          : fp
                          ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                          : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                      }`}
                    >
                      <div
                        className={`${
                          !shotClicked
                            ? "hidden"
                            : "relative  h-full w-full grid grid-cols-2 grid-rows-2 bg-blue-700 "
                        } `}
                      >
                        <p
                          onMouseUp={() => setPosition("A_RSCBL")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("A_RSCFL")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("A_RSCBR")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("A_RSCFR")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                      </div>
                      <p
                        className={`${
                          shotClicked
                            ? "hidden"
                            : "text=3xl flex justify-center items-center"
                        } `}
                      >
                        {/* P2 */}
                        <ARS
                          PPlayer={placePlayers}
                          server={ServerTag}
                          receiver={ReceiverTag}
                          team1p1Name={team1p1disname}
                        //  team1p2Name={team1p2disname}
                          team2p1Name={team2p1disname}
                        //  team2p2Name={team2p2disname}
                        />
                        {/* {placePlayers.AR} */}
                      </p>
                    </div>
                  ) : (
                    <div
                      className={`${
                        (fp && !lcpSelected) || shotClicked
                          ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                          : fp
                          ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                          : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                      }`}
                      onClick={() => {
                        sett1Clicked(true);
                        setLcpSelected(true);
                       // setScore({ ...score, lastContactPlayer: placePlayers.AR });
                        setScore({ ...score, lastContactPlayer: gameDetails.T1P1});
                        setInstruction("Select Forehand or Backhand");
                      }}
                    >
                      <p
                        className={`${
                          shotClicked
                            ? "hidden"
                            : "text=3xl flex justify-center items-center"
                        } `}
                      >
                        {/* P2 */}
                        <ARS
                          PPlayer={placePlayers}
                          server={ServerTag}
                          receiver={ReceiverTag}
                          team1p1Name={team1p1disname}
                        //  team1p2Name={team1p2disname}
                          team2p1Name={team2p1disname}
                        //  team2p2Name={team2p2disname}
                        />
                        {/* {placePlayers.AR} */}
                      </p>
                    </div>
                  )}
  
                  <div
                    className={`${
                      !fp
                        ? "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none border-b-2 border-white "
                        : shotClicked
                        ? "h-full grid grid-cols-3  bg-red-300 cursor-pointer border-b-2 border-white "
                        : "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-b-2 border-white "
                    } `}
                  >
                    <p
                      onClick={() => setPosition("A_SLRB")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                    <p
                      onClick={() => setPosition("A_SLRM")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                    <p
                      onClick={() => setPosition("A_SLRF")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                  </div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                </div>
  
                <div className="h-full w-full grid grid-rows-innerInnerField ">
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                  <div
                    className={`${
                      !fp
                        ? "h-full grid grid-cols-2  bg-red-300 cursor-not-allowed pointer-events-none border-t-2   border-white "
                        : shotClicked
                        ? "h-full grid grid-cols-2  bg-red-300 cursor-pointer border-t-2   border-white "
                        : "h-full grid grid-cols-2  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-t-2   border-white "
                    } `}
                  >
                    <p
                      onClick={() => setPosition("A_FL")}
                      className="h-full w-full border-2 border-white"
                    ></p>
                    <p
                      onClick={() => setPosition("B_FR")}
                      className="h-full w-full border-2 border-white"
                    ></p>
                  </div>
                  <div className="border-2 border-white h-full w-full ">
                    <div
                      className={`${
                        shotClicked ? "hidden" : "h-full w-full grid grid-cols-fh"
                      }`}
                    >
                      <div
                        onClick={() => {
                          // setFp(false);
                          setScore({ ...score, fh: true, bh: false });
                          setHClicked(true);
                          setInstruction("Select the type of shot");
                        }}
                        className={` ${
                          !fp
                            ? "cursor-not-allowed pointer-events-none h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                            : lcpSelected && !hClicked
                            ? "cursor-pointer h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                            : "h-full w-full cursor-not-allowed pointer-events-none opacity-50 flex justify-center items-center bg-yellow-400 transition-all"
                        }   `}
                      >
                        FH
                      </div>
                      <div
                        className={`${
                          !fp
                            ? "h-full w-full bg-white"
                            : shotClicked
                            ? "h-full w-full bg-white"
                            : "h-full w-full bg-white cursor-not-allowed pointer-events-none opacity-50"
                        } `}
                      />
                      <div
                        onClick={() => {
                          // setFp(false);
                          setScore({ ...score, fh: true, bh: false });
                          setHClicked(true);
                          setInstruction("Select the type of shot");
                        }}
                        className={` ${
                          !fp
                            ? "cursor-not-allowed pointer-events-none h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                            : lcpSelected && !hClicked
                            ? "cursor-pointer h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                            : "h-full w-full cursor-not-allowed pointer-events-none opacity-50 flex justify-center items-center bg-yellow-400 transition-all"
                        }   `}
                      >
                        FH
                      </div>
                    </div>
                    <div
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "relative h-full w-full grid grid-cols-fh  bg-yellow-400 cursor-pointer   "
                      } `}
                    >
                      <div className="grid grid-row-2">
                        <p
                          onMouseUp={() => setPosition("A_SSLLL")}
                          className="h-full w-full border-2 border-white hover:bg-yellow-300"
                        ></p>
                        <p
                          onMouseUp={() => setPosition("B_SSLRR")}
                          className="h-full w-full border-2 border-white hover:bg-yellow-300"
                        ></p>
                      </div>
  
                      <div
                        onMouseDown={() => setPosition("Net")}
                        className="h-full w-full bg-white"
                      />
                      <div className="grid grid-row-2">
                        <p
                          onMouseUp={() => setPosition("A_SSLLR")}
                          className="h-full w-full border-2 border-white hover:bg-yellow-300"
                        ></p>
                        <p
                          onMouseUp={() => setPosition("B_SSLRL")}
                          className="h-full w-full border-2 border-white hover:bg-yellow-300"
                        ></p>
                      </div>
                    </div>
                  </div>
  
                  <div className="border-2 border-white h-full w-full ">
                    <div
                      className={`${
                        shotClicked ? "hidden" : "h-full w-full grid grid-cols-fh"
                      }`}
                    >
                      <div
                        onClick={() => {
                          //  setFp(false);
                          setScore({ ...score, bh: true, fh: false });
                          setHClicked(true);
                          setInstruction("Select the type of shot");
                        }}
                        className={` ${
                          !fp
                            ? "cursor-not-allowed pointer-events-none h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                            : lcpSelected && !hClicked
                            ? "cursor-pointer h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                            : "h-full w-full cursor-not-allowed pointer-events-none opacity-50 flex justify-center items-center bg-yellow-400 transition-all"
                        }   `}
                      >
                        BH
                      </div>
                      <div
                        className={`${
                          !fp
                            ? "h-full w-full bg-white"
                            : shotClicked
                            ? "h-full w-full bg-white"
                            : "h-full w-full bg-white cursor-not-allowed pointer-events-none opacity-50"
                        } `}
                      />
                      <div
                        onClick={() => {
                          // setFp(false);
                          setScore({ ...score, bh: true, fh: false });
                          setHClicked(true);
                          setInstruction("Select the type of shot");
                        }}
                        className={` ${
                          !fp
                            ? "cursor-not-allowed pointer-events-none h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                            : lcpSelected && !hClicked
                            ? "cursor-pointer h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                            : "h-full w-full cursor-not-allowed pointer-events-none opacity-50 flex justify-center items-center bg-yellow-400 transition-all"
                        }   `}
                      >
                        BH
                      </div>
                    </div>
  
                    <div
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "relative h-full w-full grid grid-cols-fh bg-yellow-400 cursor-pointer   "
                      } `}
                    >
                      <div className="grid grid-rows-2">
                        <p
                          onMouseUp={() => setPosition("A_SSLRL")}
                          className="h-full w-full border-2 border-white hover:bg-yellow-300"
                        ></p>
                        <p
                          onMouseUp={() => setPosition("B_SSLRL")}
                          className="h-full w-full border-2 border-white hover:bg-yellow-300"
                        ></p>
                      </div>
                      <div
                        onMouseDown={() => setPosition("Net")}
                        className="h-full w-full bg-white"
                      />
                      <div className="grid grid-row-2">
                        <p
                          onMouseUp={() => setPosition("A_SSLRR")}
                          className="h-full w-full border-2 border-white hover:bg-yellow-300"
                        ></p>
                        <p
                          onMouseUp={() => setPosition("B_SSLLL")}
                          className="h-full w-full border-2 border-white hover:bg-yellow-300"
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      !fp
                        ? "h-full grid grid-cols-2  bg-red-300 cursor-not-allowed pointer-events-none border-b-2 border-r-2 border-l-2  border-white "
                        : shotClicked
                        ? "h-full grid grid-cols-2  bg-red-300 cursor-pointer border-b-2 border-r-2 border-l-2  border-white "
                        : "h-full grid grid-cols-2  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-b-2 border-r-2 border-l-2  border-white "
                    } `}
                  >
                    <p
                      onClick={() => setPosition("A_FR")}
                      className="h-full w-full border-2 border-white"
                    ></p>
                    <p
                      onClick={() => setPosition("B_FL")}
                      className="h-full w-full border-2 border-white"
                    ></p>
                  </div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                </div>
                <div className="h-full w-full grid grid-rows-innerInnerField ">
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                  <div
                    className={`${
                      !fp
                        ? "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none border-t-2 border-white "
                        : shotClicked
                        ? "h-full grid grid-cols-3  bg-red-300 cursor-pointer border-t-2 border-white "
                        : "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-t-2 border-white "
                    } `}
                  >
                    <p
                      onClick={() => setPosition("B_SLRF")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                    <p
                      onClick={() => setPosition("B_SLRM")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                    <p
                      onClick={() => setPosition("B_SLRB")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                  </div>
                  {shotClicked ? (
                    <div
                      className={`${
                        (fp && !lcpSelected) || shotClicked
                          ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                          : fp
                          ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                          : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                      }`}
                    >
                      <div
                        className={`${
                          !shotClicked
                            ? "hidden"
                            : "relative  h-full w-full grid grid-cols-2 grid-rows-2 bg-blue-700"
                        } `}
                      >
                        <p
                          onMouseUp={() => setPosition("B_RSCFR")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("B_RSCBR")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("B_RSCFL")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("B_RSCBL")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                      </div>
                      <p
                        className={`${
                          shotClicked
                            ? "hidden"
                            : "text=3xl flex justify-center items-center"
                        } `}
                      >
                        {/* P3 */}
                        <BRS
                          PPlayer={placePlayers}
                          server={ServerTag}
                          receiver={ReceiverTag}
                          team1p1Name={team1p1disname}
                        //  team1p2Name={team1p2disname}
                          team2p1Name={team2p1disname}
                        //  team2p2Name={team2p2disname}
                        />
                        {/* {placePlayers.BR} */}
                      </p>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setLcpSelected(true);
                       // setScore({ ...score, lastContactPlayer: placePlayers.BR });
                        setScore({ ...score, lastContactPlayer: gameDetails.T2P1});
                        
                        setInstruction("Select Forehand or Backhand");
                      }}
                      className={`${
                        (fp && !lcpSelected) || shotClicked
                          ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                          : fp
                          ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                          : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                      }`}
                    >
                      <p
                        className={`${
                          shotClicked
                            ? "hidden"
                            : "text=3xl flex justify-center items-center"
                        } `}
                      >
                        {/* P3 */}
                        <BRS
                          PPlayer={placePlayers}
                          server={ServerTag}
                          receiver={ReceiverTag}
                          team1p1Name={team1p1disname}
                        //  team1p2Name={team1p2disname}
                          team2p1Name={team2p1disname}
                        //  team2p2Name={team2p2disname}
                        />
                        {/* {placePlayers.BR} */}
                      </p>
                    </div>
                  )}
  
                  {shotClicked ? (
                    <div
                      className={`${
                        (fp && !lcpSelected) || shotClicked
                          ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                          : fp
                          ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                          : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                      }`}
                    >
                      <div
                        className={`${
                          !shotClicked
                            ? "hidden"
                            : "relative  h-full w-full grid grid-cols-2 grid-rows-2 bg-blue-700"
                        } `}
                      >
                        <p
                          onMouseUp={() => setPosition("B_LSCFR")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("B_LSEBR")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("B_LSCFL")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                        <p
                          onMouseUp={() => setPosition("B_LSCBL")}
                          className="h-full w-full border-2 border-white hover:bg-blue-800 "
                        ></p>
                      </div>
                      <p
                        className={`${
                          shotClicked
                            ? "hidden"
                            : "text=3xl flex justify-center items-center"
                        } `}
                      >
                        {/* P4 */}
                        <BLS
                          PPlayer={placePlayers}
                          server={ServerTag}
                          receiver={ReceiverTag}
                          team1p1Name={team1p1disname}
                        //  team1p2Name={team1p2disname}
                          team2p1Name={team2p1disname}
                        //  team2p2Name={team2p2disname}
                        />
                        {/* {placePlayers.BL} */}
                      </p>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setLcpSelected(true);
                       // setScore({ ...score, lastContactPlayer: placePlayers.BL });
                        setScore({ ...score, lastContactPlayer: gameDetails.T2P1});
                        
                        setInstruction("Select Forehand or Backhand");
                      }}
                      className={`${
                        (fp && !lcpSelected) || shotClicked
                          ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                          : fp
                          ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                          : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                      }`}
                    >
                      <p
                        className={`${
                          shotClicked
                            ? "hidden"
                            : "text=3xl flex justify-center items-center"
                        } `}
                      >
                        {/* P4 */}
                        <BLS
                          PPlayer={placePlayers}
                          server={ServerTag}
                          receiver={ReceiverTag}
                          team1p1Name={team1p1disname}
                         // team1p2Name={team1p2disname}
                          team2p1Name={team2p1disname}
                        //  team2p2Name={team2p2disname}
                        />
                        {/* {placePlayers.BL} */}
                      </p>
                    </div>
                  )}
  
                  <div
                    className={`${
                      !fp
                        ? "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none border-b-2 border-white "
                        : shotClicked
                        ? "h-full grid grid-cols-3  bg-red-300 cursor-pointer border-b-2 border-white "
                        : "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-b-2 border-white "
                    } `}
                  >
                    <p
                      onClick={() => setPosition("B_SLLF")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                    <p
                      onClick={() => setPosition("B_SLLM")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                    <p
                      onClick={() => setPosition("B_SLLB")}
                      className={`${
                        !shotClicked
                          ? "hidden"
                          : "h-full w-full border-2 border-white"
                      } `}
                    ></p>
                  </div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                </div>
  
                <div className="h-full w-10 grid grid-rows-innerInnerField ">
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                  <div
                    onClick={() => setPosition("B_BR")}
                    className={`${
                      !fp
                        ? "h-full  bg-red-300 cursor-not-allowed pointer-events-none border-t-4 border-x-2 border-white "
                        : shotClicked
                        ? "h-full  bg-red-300 cursor-pointer border-t-4 border-x-2 border-white "
                        : "h-full  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-t-4 border-x-2 border-white "
                    } `}
                  ></div>
                  <div className="h-full ">
                    <div
                      className={`${
                        !fp
                          ? "h-full   bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
                          : shotClicked
                          ? "h-full grid grid-rows-2  bg-red-300 cursor-pointer border-t-2 border-white "
                          : "h-full   bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
                      } `}
                    >
                      <p
                        onClick={() => setPosition("B_LSLRR")}
                        className={`${
                          shotClicked
                            ? "h-full w-full border-2 border-white"
                            : "hidden"
                        } `}
                      ></p>
                      <p
                        onClick={() => setPosition("B_LSLRL")}
                        className={`${
                          shotClicked
                            ? "h-full w-full border-2 border-white"
                            : "hidden"
                        } `}
                      ></p>
                    </div>
                  </div>
                  <div className="h-full ">
                    <div
                      className={`${
                        !fp
                          ? "h-full   bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
                          : shotClicked
                          ? "h-full grid grid-rows-2  bg-red-300 cursor-pointer border-t-2 border-white "
                          : "h-full   bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
                      } `}
                    >
                      <p
                        onClick={() => setPosition("B_LSLLR")}
                        className={`${
                          shotClicked
                            ? "h-full w-full border-2 border-white"
                            : "hidden"
                        } `}
                      ></p>
                      <p
                        onClick={() => setPosition("B_LSLLL")}
                        className={`${
                          shotClicked
                            ? "h-full w-full border-2 border-white"
                            : "hidden"
                        } `}
                      ></p>
                    </div>
                  </div>
                  <div
                    onClick={() => setPosition("B_BL")}
                    className={`${
                      !fp
                        ? "h-full  bg-red-300 border-b-4 border-r-2 border-l-2 border-white cursor-not-allowed pointer-events-none"
                        : shotClicked
                        ? "h-full  bg-red-300 border-b-4 border-r-2 border-l-2 border-white "
                        : "h-full  bg-red-300 border-b-4 border-r-2 border-l-2 border-white cursor-not-allowed pointer-events-none opacity-50 "
                    } `}
                  ></div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                </div>
                <div className="h-full w-full grid grid-rows-innerInnerField  ">
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  ></div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  ></div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  ></div>
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                  <div
                    onClick={() => setPosition("Out")}
                    className={`${
                      !fp
                        ? "pointer-events-none h-full  bg-green-400"
                        : shotClicked
                        ? "h-full  bg-green-400 cursor-pointer"
                        : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
                    } `}
                  />
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setFp(true);
                setScore({ ...score, teamTag: "B" });
                setInstruction("Select the last contact player");
              }}
              className={`${
                fp
                  ? "bg-green-500 opacity-50 cursor-not-allowed pointer-events-none hover:bg-green-600 flex justify-center items-center"
                  : "bg-green-500 hover:bg-green-600 cursor-pointer flex justify-center items-center"
              } `}
            >
              <p className="text-white   ">+1</p>
            </div>
            <div className="flex flex-col">
              {labels.map((e) => (
                <div
                  key={e.text}
                  onClick={() => {
                    setShotClicked(true);
                    setScore({ ...score, typeOfShot: e.text });
                    setInstruction("Select the Position of the shuttlecock");
                    if (e.text==="Net Shot"){setPosition("Net")};
                  }}
                  className={`${
                    hClicked && !shotClicked
                      ? "flex justify-center cursor-pointer items-center border-2 border-white-200 bg-blue-600 hover:bg-blue-500"
                      : "flex justify-center opacity-50 cursor-not-allowed  pointer-events-none items-center border-2 border-yellow-300"
                  } `}
                >
                  <p className="text-white font-bold p-6 text-center">
                    {e.text}{" "}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <p className="flex justify-center text-red-500 items-center font-bold text-4xl mt-2 mb-2 font-mono">
            {instruction}
          </p>
          <div className="font-bold text-2xl mt-2 mb-2 font-mono text-center">
            <Displayserver
              displayserver={ServerTag}
              displayReceiver={ReceiverTag}
              team1p1Name={team1p1disname}
            //  team1p2Name={team1p2disname}
              team2p1Name={team2p1disname}
            //  team2p2Name={team2p2disname}
            />
          </div>
          {/* value of AL {placePlayers.AL} <br />
          value of AR {placePlayers.AR} <br />
          value of BR {placePlayers.BR} <br />
          value of BL {placePlayers.BL} <br />
          server tag {ServerTag} <br />
          receiver tag {ReceiverTag} <br />
          initialServerPlaceTrigger {initialServerPlaceTrigger} <br />
          initialReceiverPlaceTrigger {initialReceiverPlaceTrigger} <br />
          trigger {serverChangeTrigger}
          <br />
          fp {`${fp ? "true" : "false"} `}
          <br />
          lcpSelected {`${lcpSelected ? "true" : "false"} `}
          <br />
          t1Clicked {`${t1Clicked ? "true" : "false"} `}
          <br />
          hClicked {`${hClicked ? "true" : "false"} `}
          <br />
          shotClicked {`${shotClicked ? "true" : "false"} `}
          <br />
          GameID {gameDetails.GameID}
          <br />
          TossWinner{gameDetails.TossWinner}
          <br />
          FirstServer {gameDetails.FirstServer}
          <br />
          FirstReceiver {gameDetails.FirstReceiver}
          <br />
          T1P1 {gameDetails.T1P1}
          <br />
        
          <br />
          T2P1 {gameDetails.T2P1}
          <br />
       
          <br />
        
          <br />
          Winpoint {gameDetails.Winpoint}
          <br />
          Goldenpoint {gameDetails.Goldenpoint}
          <br />
          Master {gameDetails.Master}
          <br />
          TournamentID {gameDetails.TournamentID}
          <br />
          */}
        </div>
      </>
    );
  }
export default InterfaceSingleCore