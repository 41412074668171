import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserRoleDisplay from "../User/UserRoleDisplay";
import MatchesPlayed from "../Stats/MatchesPlayed";
import AveragePoints from "../Stats/AveragePoints";

function Profile() {
  const Redux_FirstName = useSelector((state) => state.userinfo.FirstName);
  const Redux_LastName = useSelector((state) => state.userinfo.LastName);
  const Redux_UserID = useSelector((state) => state.userinfo.UserID);
  const Redux_UserType = useSelector((state) => state.userinfo.UserType);
  const Redux_UserRole = useSelector((state) => state.userinfo.UserRole);

  const [userData, setuserData] = useState([]);

  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedUser, setisloadedUser] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedUser) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedUser]); //add all the induvidual loading variables here

  console.log(Redux_UserID);

  useEffect(() => {
    try {
      fetch(process.env.REACT_APP_API + "users/" + "readusers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserID: Redux_UserID, //sessionStorage.getItem("UserID"),
        }),
      })
        .then((Response) => {
          if (!Response.ok) {
            throw Error("Could not fetch data.");
          }
          return Response.json();
        })
        .then((data) => {
          console.log(data);
          setuserData(data[0]);
        })
        .then(() => {
          setisloadedUser(true);
        })
        .catch((err) => {
          console.error(err);
          setisloaded(true);
          setIsError(true);
        });
    } catch (error) {
      setisloaded(true);
      setIsError(true);
    }
  }, []);

  //loading message
  if (!isloaded)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
        </div>
      </div>
    );
  //error message
  if (IsError)
    return (
      <div className="flex justify-center items-center h-screen text-xl">
        <div className="flex justify-center items-center">
          <div className="">
            <h2>Oops! Something Went Wrong...</h2>
          </div>
        </div>
      </div>
    );

  //default component
  if (isloaded && !IsError) {
    return (
      <div>
        <h2 className="my-6 text-2xl text-left font-semibold text-gray-700 dark:text-gray-200">
          Profile
        </h2>

        <div
          className="relative flex items-center justify-between p-4 my-3 bg-secondary  shadow-md dark:bg-gray-800 rounded-lg bg-clip-border"
          id="Name_Plate"
        >
          <div className="flex flex-wrap ">
            <div className="relative inline-flex items-center justify-center text-base">
              <img
                className="w-10 shadow-sm"
                src="./Images/profile_picture.svg"
                alt={`${userData.FirstName} ${userData.LastName}`}
              />
            </div>
            <div className="h-full px-3">
              <h5 className="font-bold dark:text-white">
                {" "}
                {userData.FirstName} {userData.LastName}
              </h5>
              <p className="text-sm font-semibold leading-normal dark:text-gray-400">
                {UserRoleDisplay(userData.UserRole)}
              </p>
            </div>
          </div>
          <div className=" text-right sm:mt-0 flex items-center">
            <button
              onClick={() => (window.location.href = "/edituserdetails")}
              className="bg-primary active:bg-primary_1 uppercase text-white font-bold hover:shadow-md shadow border-0 text-xs px-4 py-2 rounded outline-none focus:outline-none "
              type="button"
            >
              Edit
            </button>
          </div>
        </div>

        {/* --------user details------------ */}

        {/* <section className="relative block" style={{height: '500px'}}>
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover"
           style={{backgroundImage: 'url("./Images/pattern.png")'}}
        >
          <span
            id="blackOverlay"
            className="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div
          className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style={{height: '70px'}}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section> */}
        <section className="relative ">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-md rounded-lg ">
            <div className="">
              <div className="">
                <dl>
                  <div className="bg-gray-50 dark:bg-slate-800 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">User ID</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                    {userData.UserID}
                    </dd>
                  </div>
                  <div className="bg-white dark:bg-slate-700 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Date of birth
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                    {userData.DOB}
                    </dd>
                  </div>
                  <div className="bg-gray-50 dark:bg-slate-800 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Mobile
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                    {userData.Mobile}
                    </dd>
                  </div>
                  <div className="bg-white dark:bg-slate-700 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Email
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 dark:text-gray-300">
                    {userData.Email}
                    </dd>
                  </div>
                </dl>
              </div>

              {/* <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div className="relative">
                  <img
                    alt="..."
                    src="./assets/img/team-2-800x800.jpg"
                    className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16"
                    style={{ maxwidth: "150px" }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                <div className="py-6 px-3 mt-32 sm:mt-0">
                  <button
                    className="bg-pink-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                    type="button"
                  >
                    Edit
                  </button>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4 lg:order-1">
                <div className="flex justify-center py-4 lg:pt-4 pt-8">
                  <div className="mr-4 p-3 text-center">
                    <span className="text-sm text-gray-500">asdsa</span>
                    <span className="text-md  block uppercase tracking-wide text-gray-700">
                      1234567890
                    </span>
                  </div>
                </div>
              </div>
            </div> */}



              {/* <div className="pt-6 px-3 text-right sm:mt-0">
                <button
                  onClick={() => (window.location.href = "/edituserdetails")}
                  className="bg-primary active:bg-primary_1 uppercase text-white font-bold hover:shadow-md shadow border-0 text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                  type="button"
                >
                  Edit
                </button>
              </div> */}
              {/* <div className="text-center py-8">
                <h3 className="text-lg md:text-xl font-semibold leading-normal  text-gray-800 mb-2">
                  {userData.FirstName} {userData.LastName}
                </h3>
                <div className="text-xs leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                  Bangalore, Karnataka
                </div>

                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-1/2 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className=" sm:p-3 text-center">
                        <span className="text-sm text-gray-500">User ID</span>
                        <span className="text-md  block uppercase tracking-wide text-gray-700">
                          {userData.UserID}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className=" sm:p-3 text-center">
                        <span className="text-sm text-gray-500">
                          Date of birth
                        </span>
                        <span className="text-md  block uppercase tracking-wide text-gray-700">
                          {userData.DOB}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className=" sm:p-3 text-center">
                        <span className="text-sm text-gray-500">Mobile</span>
                        <span className="text-md  block uppercase tracking-wide text-gray-700">
                          {userData.Mobile}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className=" sm:p-3 text-center">
                        <span className="text-sm text-gray-500">Email</span>
                        <span className="text-md  block  tracking-wide text-gray-700">
                          {userData.Email}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <div>
          <h4 className="mb-4 text-lg font-semibold text-gray-600 dark:text-gray-300">
            Overview
          </h4>
          <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
            {/* <!-- Card --> */}
            <div className="flex items-center p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
              <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100 dark:bg-orange-500">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                </svg>
              </div>
              <div>
                <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                  Matches
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                 <MatchesPlayed/>
                </p>
              </div>
            </div>
            {/* <!-- Card --> */}
            <div className="flex items-center p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
              <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div>
                <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                  Matches Won
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  4583
                </p>
              </div>
            </div>
            {/* <!-- Card --> */}
            <div className="flex items-center p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
              <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
                </svg>
              </div>
              <div>
                <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                  Points Scored
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  284152
                </p>
              </div>
            </div>
            {/* <!-- Card --> */}
            <div className="flex items-center p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
              <div className="p-3 mr-4 text-teal-500 bg-teal-100 rounded-full dark:text-teal-100 dark:bg-teal-500">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div>
                <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                  Average Points
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  <AveragePoints/>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex justify-center items-center h-screen text-xl">
      <div className="flex justify-center items-center">
        <div className="">
          <h2 className="text-2xl text-center">
            Oops! An Unexpected Error Occured.
          </h2>
          <h3 className="text-base text-center">Try reloading the page.</h3>
        </div>
      </div>
    </div>
  );
}

export default Profile;
