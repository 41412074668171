import React, { useState, useEffect } from "react";
import SearchBar from "../SearchBar/SearchBar";
import { Link } from "react-router-dom";

function SelectPlayersSingle() {
  const [PlayersDetails, setPlayersDetails] = useState({});

  const infoChangeHandler = (datalabel, datavalue) => {
    // setInfo({...info,[e.target.name] : e.target.value});
    // console.log(e);
    setPlayersDetails({ ...PlayersDetails, [datalabel]: datavalue });
    console.log(datalabel);
    console.log(datavalue);
  };
  const SubmitPlayers = () => {
    if (PlayersDetails.T1P1ID && PlayersDetails.T2P1ID ) {
      sessionStorage.setItem("team1p1", PlayersDetails.T1P1ID);
      sessionStorage.setItem("team2p1", PlayersDetails.T2P1ID);

      window.location.href = "/custompointssingle";
    } else {
      alert("Select all Players.");
    }
  };
  return (
    <div>
      {/* <div className="">
        <div className="text-2xl text-center p-4">Select Players</div>

        <div className="grid grid-cols-2 m-5">
          <div className=" justify-center items-center bg-slate-200 mx-2 rounded-3xl">
            <h2 className="text-3xl p-5">Team 1</h2>
            <label>Player 1</label>
            <SearchBar
              SessionLabel={"team1p1"}
              ArrayLabel={"T1P1ID"}
              inputDefaultValue=""
              handler={infoChangeHandler}
            />

         

            <br />
          </div>

          <div className=" justify-center items-center bg-slate-200 mx-2 rounded-3xl">
            <h2 className="text-3xl p-5">Team 2</h2>
            <label>Player 1</label>
            <SearchBar
              SessionLabel={"team2p1"}
              ArrayLabel={"T2P1ID"}
              inputDefaultValue=""
              handler={infoChangeHandler}
            />

          
            <br />
          </div>
        </div>

        <div
          className="text-3xl  flex justify-center items-center card2 bg-blue-700 hover:bg-blue-600 text-white m-8 rounded-3xl cursor-pointer"
          onClick={() => {
            sessionStorage.setItem("team1p1", PlayersDetails.T1P1ID);

            sessionStorage.setItem("team2p1", PlayersDetails.T2P1ID);

            window.location.href = "/custompointssingle";
          }}
        >
          <h2>Go</h2>
        </div>

        <div className="button-container text-center ">
          <Link to="/dashboard/matches">
            {" "}
            <button className="loginButton" type="submit">
              Cancel
            </button>
          </Link>
        </div>
      </div> */}


      <div>
    <div className="bg-gray-100">
      <div className="min-h-screen w-full p-6 flex justify-center items-center">
        <div className="w-full max-w-xl">
          <div className="bg-white border p-8 shadow rounded w-full mb-6">
            <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
              Select Players
            </h1>

            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 m-5 gap-2">
                <div className=" justify-center items-center bg-green-200 mx-2 rounded-md">
                  <h2 className="text-md font-normal p-5">Team I</h2>
                  <div className="block sm:flex md:block justify-evenly items-center">
                    <label className="ml-2">Player 1</label>
                    <SearchBar
              SessionLabel={"team1p1"}
              ArrayLabel={"T1P1ID"}
              inputDefaultValue=""
              handler={infoChangeHandler}
            />
                  </div>


                  <br/>
                </div>
                <div className=" justify-center items-center bg-green-200 mx-2 rounded-md">
                  <h2 className="text-md p-5 font-normal">Team II</h2>
                  <div className="block sm:flex md:block justify-evenly items-center">
                    <label className="ml-2">Player 1</label>
                    <SearchBar
              SessionLabel={"team2p1"}
              ArrayLabel={"T2P1ID"}
              inputDefaultValue=""
              handler={infoChangeHandler}
            />
                  </div>
                  <br />
                
                </div>
              </div>
            </div>

            <div className="flex justify-center gap-2 text-center ">
            <button
          onClick={() => {
SubmitPlayers();
          }}
                className=" bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
              >
                Next
              </button>


              <button
                onClick={() => {
                  window.location.href = "/dashboard/matches";
                }}
                className=" bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  );
}

export default SelectPlayersSingle;
