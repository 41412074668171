import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { userInfoActions } from "../../store/index";


const Counter = () => {
  const dispatch = useDispatch();
//   const counter = useSelector((state) => state.counter.counter);
//   const show = useSelector((state) => state.counter.showCounter);
  const Redux_FirstName = useSelector((state) => state.userinfo.FirstName);
  const Redux_LastName = useSelector((state) => state.userinfo.LastName);
  const Redux_UserID = useSelector((state) => state.userinfo.UserID);
  const Redux_UserType = useSelector((state) => state.userinfo.UserType);
  const Redux_UserRole = useSelector((state) => state.userinfo.UserRole);

//   const incrementHandler = () => {
//     dispatch(counterActions.increment());
//   };

//   const decrementHandler = () => {
//     dispatch(counterActions.decrement());
//   };

//   const increaseHandler = () => {
//     dispatch(counterActions.increase(10));
//   };

//   const toggleHandler = () => {
//     dispatch(counterActions.toggle());
//   };


  const PrintHandler = () => {
 //   dispatch(userInfoActions.printName());
 
  };

     const userInfoHandler = () => {
     dispatch(userInfoActions.updateUserInfo("this sholud be an object"));
   };


  return (
    <main>
      {/* {show && <div>counter will be here : {counter}</div>} */}
      {Redux_FirstName && <div>First Name is : {Redux_FirstName}</div>}
      {Redux_LastName && <div>Last Name is : {Redux_LastName}</div>}
      {Redux_UserID && <div>User ID is : {Redux_UserID}</div>}
      {Redux_UserRole && <div>Role Type is : {Redux_UserRole}</div>}
      {Redux_UserType && <div>Role Type is : {Redux_UserType}</div>}
      {/* <button onClick={incrementHandler}>increment</button>
      <button onClick={decrementHandler}>decrement</button>
      <button onClick={increaseHandler}>increase by 10</button>
      <button onClick={toggleHandler}>toggle</button> */}
      <button onClick={PrintHandler}>print data</button>
    </main>
  );
};

export default Counter;
