import React from 'react'
import { useState } from 'react';

function PointsScoredPosition() {
    const [t1Score, sett1Score] = useState(0);
    const [t2Score, sett2Score] = useState(0);
    const [fp, setFp] = useState(false);
    const [t1Clicked, sett1Clicked] = useState(false);
    const [hClicked, setHClicked] = useState(false);
    const [shotClicked, setShotClicked] = useState(true);
    const [lcpSelected, setLcpSelected] = useState(false);
  
    const setPosition = (e) => {
      //   "API request for getting the Point Scored Position"---------------------------------------------
    };
  return (
    <div
    className={` ${
      t1Clicked && !shotClicked
        ? "cursor-not-allowed  grid place-items-center text-white text-3xl font-bold font-mono"
        : " grid place-items-center text-white text-3xl font-bold font-mono  w-full "
    } `}
  >
    <div className="grid place-items-center   grid-cols-innerField   specialsize ">
      <div className="h-full w-full grid grid-rows-innerInnerField  ">
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        ></div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        ></div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        ></div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
      </div>
      <div className="h-full w-10 grid grid-rows-innerInnerField  ">
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
        <div
          onClick={() => setPosition("A_BL")}
          className={`${
            !fp
              ? "h-full  bg-red-300 cursor-not-allowed pointer-events-none border-l-2 border-t-2 border-white "
              : shotClicked
              ? "h-full  bg-red-300 cursor-pointer border-l-2 border-t-2 border-white "
              : "h-full  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-l-2 border-t-2 border-white "
          } `}
        />

        <div className="h-full ">
          <div
            className={`${
              !fp
                ? "h-full grid grid-rows-2   bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
                : shotClicked
                ? "h-full grid grid-rows-2  bg-red-300 cursor-pointer border-t-2 border-white "
                : "h-full   bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
            } `}
          >
            <p
              onClick={() => setPosition("A_LSLLL")}
              className={`${
                shotClicked
                  ? "h-full w-full border-2 border-white"
                  : "hidden"
              } `}
            ></p>
            <p
              onClick={() => setPosition("A_LSLLR")}
              className={`${
                shotClicked
                  ? "h-full w-full border-2 border-white"
                  : "hidden"
              } `}
            ></p>
          </div>
        </div>
        <div className="h-full ">
          <div
            className={`${
              !fp
                ? "h-full grid grid-rows-2  bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
                : shotClicked
                ? "h-full grid grid-rows-2  bg-red-300 cursor-pointer border-t-2 border-white "
                : "h-full   bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
            } `}
          >
            <p
              onClick={() => setPosition("A_LSLRL")}
              className={`${
                shotClicked
                  ? "h-full w-full border-2 border-white"
                  : "hidden"
              } `}
            ></p>
            <p
              onClick={() => setPosition("A_LSLRR")}
              className={`${
                shotClicked
                  ? "h-full w-full border-2 border-white"
                  : "hidden"
              } `}
            ></p>
          </div>
        </div>
        <div
          onClick={() => setPosition("A_BR")}
          className={`${
            !fp
              ? "h-full  bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
              : shotClicked
              ? "h-full  bg-red-300 cursor-pointer border-2 border-white "
              : "h-full  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
          } `}
        ></div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
      </div>
      <div className="h-full w-full grid grid-rows-innerInnerField ">
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
        <div
          className={`${
            !fp
              ? "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
              : shotClicked
              ? "h-full grid grid-cols-3  bg-red-300 cursor-pointer border-2 border-white "
              : "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
          } `}
        >
          <p
            onClick={() => setPosition("A_SLLB")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
          <p
            onClick={() => setPosition("A_SLLM")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
          <p
            onClick={() => setPosition("A_SLLF")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
        </div>
        {shotClicked ? (
          <div
            className={`${
              (fp && !lcpSelected) || shotClicked
                ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                : fp
                ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
            }`}
          >
            <div
              className={`${
                !shotClicked
                  ? "hidden"
                  : "relative pointer-events-auto h-full w-full grid grid-cols-2 grid-rows-2 bg-blue-700 "
              } `}
            >
              <p
                onMouseUp={() => setPosition("A_LSCBL")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("A_LSCFL")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("A_LSCBR")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("A_LSCFR")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
            </div>
            <p
              className={`${
                shotClicked
                  ? "hidden"
                  : "text=3xl flex justify-center items-center"
              } `}
            >
              {/* P1 */}

              {/* {placePlayers.AL} */}
            </p>
          </div>
        ) : (
          <div
            className={`${
              (fp && !lcpSelected) || shotClicked
                ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                : fp
                ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
            }`}
            onClick={() => {
              console.log(shotClicked);
              if (!shotClicked) {
              }
            }}
          >
            <p
              className={`${
                shotClicked
                  ? "hidden"
                  : "text=3xl flex justify-center items-center"
              } `}
            >
              {/* P1 */}

              {/* {placePlayers.AL} */}
            </p>
          </div>
        )}

        {shotClicked ? (
          <div
            className={`${
              (fp && !lcpSelected) || shotClicked
                ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                : fp
                ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
            }`}
          >
            <div
              className={`${
                !shotClicked
                  ? "hidden"
                  : "relative  h-full w-full grid grid-cols-2 grid-rows-2 bg-blue-700 "
              } `}
            >
              <p
                onMouseUp={() => setPosition("A_RSCBL")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("A_RSCFL")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("A_RSCBR")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("A_RSCFR")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
            </div>
            <p
              className={`${
                shotClicked
                  ? "hidden"
                  : "text=3xl flex justify-center items-center"
              } `}
            >
              {/* P2 */}

              {/* {placePlayers.AR} */}
            </p>
          </div>
        ) : (
          <div
            className={`${
              (fp && !lcpSelected) || shotClicked
                ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                : fp
                ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
            }`}
            onClick={() => {}}
          >
            <p
              className={`${
                shotClicked
                  ? "hidden"
                  : "text=3xl flex justify-center items-center"
              } `}
            >
              {/* P2 */}

              {/* {placePlayers.AR} */}
            </p>
          </div>
        )}

        <div
          className={`${
            !fp
              ? "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none border-b-2 border-white "
              : shotClicked
              ? "h-full grid grid-cols-3  bg-red-300 cursor-pointer border-b-2 border-white "
              : "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-b-2 border-white "
          } `}
        >
          <p
            onClick={() => setPosition("A_SLRB")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
          <p
            onClick={() => setPosition("A_SLRM")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
          <p
            onClick={() => setPosition("A_SLRF")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
        </div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
      </div>

      <div className="h-full w-full grid grid-rows-innerInnerField ">
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
        <div
          className={`${
            !fp
              ? "h-full grid grid-cols-2  bg-red-300 cursor-not-allowed pointer-events-none border-t-2   border-white "
              : shotClicked
              ? "h-full grid grid-cols-2  bg-red-300 cursor-pointer border-t-2   border-white "
              : "h-full grid grid-cols-2  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-t-2   border-white "
          } `}
        >
          <p
            onClick={() => setPosition("A_FL")}
            className="h-full w-full border-2 border-white"
          ></p>
          <p
            onClick={() => setPosition("B_FR")}
            className="h-full w-full border-2 border-white"
          ></p>
        </div>
        <div className="border-2 border-white h-full w-full ">
          <div
            className={`${
              shotClicked ? "hidden" : "h-full w-full grid grid-cols-fh"
            }`}
          >
            <div
              onClick={() => {}}
              className={` ${
                !fp
                  ? "cursor-not-allowed pointer-events-none h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                  : lcpSelected && !hClicked
                  ? "cursor-pointer h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                  : "h-full w-full cursor-not-allowed pointer-events-none opacity-50 flex justify-center items-center bg-yellow-400 transition-all"
              }   `}
            >
              FH
            </div>
            <div
              className={`${
                !fp
                  ? "h-full w-full bg-white"
                  : shotClicked
                  ? "h-full w-full bg-white"
                  : "h-full w-full bg-white cursor-not-allowed pointer-events-none opacity-50"
              } `}
            />
            <div
              onClick={() => {}}
              className={` ${
                !fp
                  ? "cursor-not-allowed pointer-events-none h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                  : lcpSelected && !hClicked
                  ? "cursor-pointer h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                  : "h-full w-full cursor-not-allowed pointer-events-none opacity-50 flex justify-center items-center bg-yellow-400 transition-all"
              }   `}
            >
              FH
            </div>
          </div>
          <div
            className={`${
              !shotClicked
                ? "hidden"
                : "relative h-full w-full grid grid-cols-fh  bg-yellow-400 cursor-pointer   "
            } `}
          >
            <div className="grid grid-row-2">
              <p
                onMouseUp={() => setPosition("A_SSLLL")}
                className="h-full w-full border-2 border-white hover:bg-yellow-300"
              ></p>
              <p
                onMouseUp={() => setPosition("B_SSLRR")}
                className="h-full w-full border-2 border-white hover:bg-yellow-300"
              ></p>
            </div>

            <div
              onMouseDown={() => setPosition("Net")}
              className="h-full w-full bg-white"
            />
            <div className="grid grid-row-2">
              <p
                onMouseUp={() => setPosition("A_SSLLR")}
                className="h-full w-full border-2 border-white hover:bg-yellow-300"
              ></p>
              <p
                onMouseUp={() => setPosition("B_SSLRL")}
                className="h-full w-full border-2 border-white hover:bg-yellow-300"
              ></p>
            </div>
          </div>
        </div>

        <div className="border-2 border-white h-full w-full ">
          <div
            className={`${
              shotClicked ? "hidden" : "h-full w-full grid grid-cols-fh"
            }`}
          >
            <div
              onClick={() => {}}
              className={` ${
                !fp
                  ? "cursor-not-allowed pointer-events-none h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                  : lcpSelected && !hClicked
                  ? "cursor-pointer h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                  : "h-full w-full cursor-not-allowed pointer-events-none opacity-50 flex justify-center items-center bg-yellow-400 transition-all"
              }   `}
            >
              BH
            </div>
            <div
              className={`${
                !fp
                  ? "h-full w-full bg-white"
                  : shotClicked
                  ? "h-full w-full bg-white"
                  : "h-full w-full bg-white cursor-not-allowed pointer-events-none opacity-50"
              } `}
            />
            <div
              onClick={() => {}}
              className={` ${
                !fp
                  ? "cursor-not-allowed pointer-events-none h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                  : lcpSelected && !hClicked
                  ? "cursor-pointer h-full w-full flex justify-center items-center bg-yellow-400 hover:bg-yellow-200 transition-all "
                  : "h-full w-full cursor-not-allowed pointer-events-none opacity-50 flex justify-center items-center bg-yellow-400 transition-all"
              }   `}
            >
              BH
            </div>
          </div>

          <div
            className={`${
              !shotClicked
                ? "hidden"
                : "relative h-full w-full grid grid-cols-fh bg-yellow-400 cursor-pointer   "
            } `}
          >
            <div className="grid grid-rows-2">
              <p
                onMouseUp={() => setPosition("A_SSLRL")}
                className="h-full w-full border-2 border-white hover:bg-yellow-300"
              ></p>
              <p
                onMouseUp={() => setPosition("B_SSLRL")}
                className="h-full w-full border-2 border-white hover:bg-yellow-300"
              ></p>
            </div>
            <div
              onMouseDown={() => setPosition("Net")}
              className="h-full w-full bg-white"
            />
            <div className="grid grid-row-2">
              <p
                onMouseUp={() => setPosition("A_SSLRR")}
                className="h-full w-full border-2 border-white hover:bg-yellow-300"
              ></p>
              <p
                onMouseUp={() => setPosition("B_SSLLL")}
                className="h-full w-full border-2 border-white hover:bg-yellow-300"
              ></p>
            </div>
          </div>
        </div>
        <div
          className={`${
            !fp
              ? "h-full grid grid-cols-2  bg-red-300 cursor-not-allowed pointer-events-none border-b-2 border-r-2 border-l-2  border-white "
              : shotClicked
              ? "h-full grid grid-cols-2  bg-red-300 cursor-pointer border-b-2 border-r-2 border-l-2  border-white "
              : "h-full grid grid-cols-2  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-b-2 border-r-2 border-l-2  border-white "
          } `}
        >
          <p
            onClick={() => setPosition("A_FR")}
            className="h-full w-full border-2 border-white"
          ></p>
          <p
            onClick={() => setPosition("B_FL")}
            className="h-full w-full border-2 border-white"
          ></p>
        </div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
      </div>
      <div className="h-full w-full grid grid-rows-innerInnerField ">
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
        <div
          className={`${
            !fp
              ? "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none border-t-2 border-white "
              : shotClicked
              ? "h-full grid grid-cols-3  bg-red-300 cursor-pointer border-t-2 border-white "
              : "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-t-2 border-white "
          } `}
        >
          <p
            onClick={() => setPosition("B_SLRF")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
          <p
            onClick={() => setPosition("B_SLRM")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
          <p
            onClick={() => setPosition("B_SLRB")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
        </div>
        {shotClicked ? (
          <div
            className={`${
              (fp && !lcpSelected) || shotClicked
                ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                : fp
                ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
            }`}
          >
            <div
              className={`${
                !shotClicked
                  ? "hidden"
                  : "relative  h-full w-full grid grid-cols-2 grid-rows-2 bg-blue-700"
              } `}
            >
              <p
                onMouseUp={() => setPosition("B_RSCFR")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("B_RSCBR")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("B_RSCFL")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("B_RSCBL")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
            </div>
            <p
              className={`${
                shotClicked
                  ? "hidden"
                  : "text=3xl flex justify-center items-center"
              } `}
            >
              {/* P3 */}

              {/* {placePlayers.BR} */}
            </p>
          </div>
        ) : (
          <div
            onClick={() => {}}
            className={`${
              (fp && !lcpSelected) || shotClicked
                ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                : fp
                ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
            }`}
          >
            <p
              className={`${
                shotClicked
                  ? "hidden"
                  : "text=3xl flex justify-center items-center"
              } `}
            >
              {/* P3 */}

              {/* {placePlayers.BR} */}
            </p>
          </div>
        )}

        {shotClicked ? (
          <div
            className={`${
              (fp && !lcpSelected) || shotClicked
                ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                : fp
                ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
            }`}
          >
            <div
              className={`${
                !shotClicked
                  ? "hidden"
                  : "relative  h-full w-full grid grid-cols-2 grid-rows-2 bg-blue-700"
              } `}
            >
              <p
                onMouseUp={() => setPosition("B_LSCFR")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("B_LSEBR")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("B_LSCFL")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
              <p
                onMouseUp={() => setPosition("B_LSCBL")}
                className="h-full w-full border-2 border-white hover:bg-blue-800 "
              ></p>
            </div>
            <p
              className={`${
                shotClicked
                  ? "hidden"
                  : "text=3xl flex justify-center items-center"
              } `}
            >
              {/* P4 */}

              {/* {placePlayers.BL} */}
            </p>
          </div>
        ) : (
          <div
            onClick={() => {
              setLcpSelected(true);
            }}
            className={`${
              (fp && !lcpSelected) || shotClicked
                ? "border-2 cursor-pointer border-white h-full w-full flex justify-center items-center bg-blue-600 hover:bg-blue-400 transition-all "
                : fp
                ? "border-2 cursor-not-allowed pointer-events-none opacity-50 bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
                : "border-2 cursor-not-allowed pointer-events-none bg-blue-600 border-white h-full w-full flex justify-center items-center transition-all "
            }`}
          >
            <p
              className={`${
                shotClicked
                  ? "hidden"
                  : "text=3xl flex justify-center items-center"
              } `}
            >
              {/* P4 */}

              {/* {placePlayers.BL} */}
            </p>
          </div>
        )}

        <div
          className={`${
            !fp
              ? "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none border-b-2 border-white "
              : shotClicked
              ? "h-full grid grid-cols-3  bg-red-300 cursor-pointer border-b-2 border-white "
              : "h-full grid grid-cols-3  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-b-2 border-white "
          } `}
        >
          <p
            onClick={() => setPosition("B_SLLF")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
          <p
            onClick={() => setPosition("B_SLLM")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
          <p
            onClick={() => setPosition("B_SLLB")}
            className={`${
              !shotClicked
                ? "hidden"
                : "h-full w-full border-2 border-white"
            } `}
          ></p>
        </div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
      </div>

      <div className="h-full w-10 grid grid-rows-innerInnerField ">
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
        <div
          onClick={() => setPosition("B_BR")}
          className={`${
            !fp
              ? "h-full  bg-red-300 cursor-not-allowed pointer-events-none border-t-4 border-x-2 border-white "
              : shotClicked
              ? "h-full  bg-red-300 cursor-pointer border-t-4 border-x-2 border-white "
              : "h-full  bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-t-4 border-x-2 border-white "
          } `}
        ></div>
        <div className="h-full ">
          <div
            className={`${
              !fp
                ? "h-full grid grid-rows-2  bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
                : shotClicked
                ? "h-full grid grid-rows-2  bg-red-300 cursor-pointer border-t-2 border-white "
                : "h-full   bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
            } `}
          >
            <p
              onClick={() => setPosition("B_LSLRR")}
              className={`${
                shotClicked
                  ? "h-full w-full border-2 border-white"
                  : "hidden"
              } `}
            ></p>
            <p
              onClick={() => setPosition("B_LSLRL")}
              className={`${
                shotClicked
                  ? "h-full w-full border-2 border-white"
                  : "hidden"
              } `}
            ></p>
          </div>
        </div>
        <div className="h-full ">
          <div
            className={`${
              !fp
                ? "h-full grid grid-rows-2  bg-red-300 cursor-not-allowed pointer-events-none border-2 border-white "
                : shotClicked
                ? "h-full grid grid-rows-2  bg-red-300 cursor-pointer border-t-2 border-white "
                : "h-full   bg-red-300 cursor-not-allowed pointer-events-none opacity-50 border-2 border-white "
            } `}
          >
            <p
              onClick={() => setPosition("B_LSLLR")}
              className={`${
                shotClicked
                  ? "h-full w-full border-2 border-white"
                  : "hidden"
              } `}
            ></p>
            <p
              onClick={() => setPosition("B_LSLLL")}
              className={`${
                shotClicked
                  ? "h-full w-full border-2 border-white"
                  : "hidden"
              } `}
            ></p>
          </div>
        </div>
        <div
          onClick={() => setPosition("B_BL")}
          className={`${
            !fp
              ? "h-full  bg-red-300 border-b-4 border-r-2 border-l-2 border-white cursor-not-allowed pointer-events-none"
              : shotClicked
              ? "h-full  bg-red-300 border-b-4 border-r-2 border-l-2 border-white "
              : "h-full  bg-red-300 border-b-4 border-r-2 border-l-2 border-white cursor-not-allowed pointer-events-none opacity-50 "
          } `}
        ></div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
      </div>
      <div className="h-full w-full grid grid-rows-innerInnerField  ">
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        ></div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        ></div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        ></div>
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
        <div
          onClick={() => setPosition("Out")}
          className={`${
            !fp
              ? "pointer-events-none h-full  bg-green-400"
              : shotClicked
              ? "h-full  bg-green-400 cursor-pointer"
              : "cursor-not-allowed pointer-events-none opacity-50 h-full  bg-green-400"
          } `}
        />
      </div>
    </div>
  </div>
  )
}

export default PointsScoredPosition