import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import MatchesDisplay from "../Matches/MatchesDisplay";


function CreatedGames() {
  // const for tabs-------------------------------------------------------
  const [tab1Class, settab1Class] = useState(" p-2 bg-secondary ");
  const [tab2Class, settab2Class] = useState("hidden");
  const [tab3Class, settab3Class] = useState("hidden");
  const [tab1, settab1] = useState(
    " p-2 px-9  border-b-4 bg-secondary border-primary "
  );
  const [tab2, settab2] = useState(" p-2 px-9 cursor-pointer");
  const [tab3, settab3] = useState(" p-2 px-9 cursor-pointer");

  const clickhandler = (e) => {
    console.log("clk hdlr works");
    if (e == "tab1") {
      settab1Class(" p-2  bg-secondary");
      settab2Class("hidden");
      settab3Class("hidden");
      settab1(" p-2 px-9  border-b-4	bg-secondary border-primary");
      settab2(" p-2 px-9 cursor-pointer");
      settab3(" p-2 px-9 cursor-pointer ");
    } else if (e == "tab2") {
      settab1Class("hidden");
      settab2Class(" p-3 bg-secondary");
      settab3Class("hidden");
      settab1(" p-2 px-9 cursor-pointer");
      settab2(" p-2 px-9 border-b-4	bg-secondary border-primary");
      settab3(" p-2 px-9 cursor-pointer");
    } else if (e == "tab3") {
      settab1Class("hidden");
      settab2Class("hidden");
      settab3Class(" p-3 bg-secondary");
      settab1(" p-2 px-9 cursor-pointer");
      settab2(" p-2 px-9 cursor-pointer");
      settab3(" p-2 px-9 border-b-4	bg-secondary border-primary");
    }
  };
  //end of const for tabs-------------------------------------------------

  const Emailref = useRef();
  const Refereeref = useRef();

  const [matchList, setmatchList] = useState([]);
  const [playedmatchList, setPlayedmatchList] = useState([]);
  const [liveMatchlist, setliveMatchlist] = useState([]);
  //   const [showScore, setShowScore] = useState([]);
  const [gameDetails, setgameDetails] = useState([]);
  const [matchdetails, setmatchdetails] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [copyDetails, setCopyDetails] = useState({});

  const [showMatcheMenu, setshowMatcheMenu] = useState(true);
  const [showMatcheMenuId, setshowMatcheMenuId] = useState(true);
  const matchMenuRef = useRef(null);


  useEffect(() => {
    //get Matches list
    fetch(process.env.REACT_APP_API + "match/getmatchesofmaster", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Master: sessionStorage.getItem("UserID"), //change this to userid of the user
      }),
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0].TeamTag+ 'thats it')
        setmatchList(data);
      });
    console.log("game list loaded");

    //get played matches list
    fetch(process.env.REACT_APP_API + "match/getplayedmatchesofmaster", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Master: sessionStorage.getItem("UserID"), //change this to userid of the user
      }),
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0].TeamTag+ 'thats it')
        setPlayedmatchList(data);
      });
    console.log("played game list loaded");

    //get live game list
    fetch(process.env.REACT_APP_API + "match/getlivematchesofmaster", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Master: sessionStorage.getItem("UserID"), //change this to userid of the user
      }),
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0].TeamTag+ 'thats it')
        setliveMatchlist(data);
      });
    console.log("live game list loaded");
  }, []);



  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="p-5 flex grow-0">
        <Link to="/gametype">
          {/* <button className="loginButton">Login</button> */}
          <div
            className={`${"bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg"}`}
          >
            Create A Match
          </div>
        </Link>
      </div>



     

      {/* code for tabs----------------------------------------------- */}

      <div>
        <div className="flex px-2 bg-secondary border-b-2">
          <div className="flex">
            <div className={` ${tab1}`} onClick={() => clickhandler("tab1")}>
              Upcoming
            </div>
            <div className={` ${tab2}`} onClick={() => clickhandler("tab2")}>
              Live
            </div>
            <div className={` ${tab3}`} onClick={() => clickhandler("tab3")}>
              Past
            </div>
          </div>
        </div>
        <div className={`${tab1Class}`}>
          <div>
            {/* tab 1 content------------------------------------------------------- */}
            <div className="">
              <h2 className="heading pt-3">Yet to be played</h2>
              <h3>{matchList.length > 0 ? "" : "No Upcoming Games"}</h3>

              <div className="grid lg:grid-cols-2 xl:grid-cols-3">
                {matchList
                  .sort((a, b) => b.MatchID - a.MatchID)
                  .map((e, i) => (
                    <MatchesDisplay matchList={e} />
                  ))}
              </div>
            </div>
            {/* end of tab 1 content------------------------------------------------------- */}
          </div>
        </div>
        <div className={`${tab2Class}`}>
          <div>
            {/* tab 2 content---------------------------------------------------------------------- */}

            <div className="homeLayout">
              <h2 className="heading  pt-3">Live Games</h2>
              <h2>{liveMatchlist.length > 0 ? "" : "No Live Games"}</h2>
              <div className="grid grid-cols-3">
                {liveMatchlist.map((e, i) => (
                  <div key={e.MatchID} className="scorecard mx-2">
                    <h6 className="date">{e.Date}</h6>
                    <h4 className="grid grid-flow-col-2 text-xl m-4"></h4>
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex   ">
                        <p>
                          {e.T1P1FirstName}{" "}
                          {e.GameType != "Single" ? "/ " + e.T1P2FirstName : ""}
                        </p>
                        <p className="ml-8 font-bold "> v/s </p>
                        <p className="ml-8">
                          {e.T2P1FirstName}{" "}
                          {e.GameType != "Single" ? "/ " + e.T2P2FirstName : ""}
                        </p>
                      </div>
                    </div>
                    <h4 className="grid grid-flow-col-2 text-xl m-4">
                      {e.MatchID}
                    </h4>
                    <p className="mt-4"> </p>
                    <button
                      className="px-7"
                      onClick={() => {
                        sessionStorage.setItem("MatchID", e.MatchID);
                        e.GameType === "Single"
                          ? (window.location.href = "/maininterfacesingle")
                          : (window.location.href = "/maininterface");
                      }}
                    >
                      Summary
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* end of tab 2 content--------------------------------------------------------------- */}
          </div>
        </div>
        <div className={`${tab3Class}`}>
          <div>
            {/* tab 3 content---------------------------------------------------------------------- */}

            <div className="homeLayout">
              <h2 className="heading pt-3">Played Games</h2>
              <h3>{playedmatchList.length > 0 ? "" : "No Past Games"}</h3>
              <div className="grid grid-cols-3">
                {playedmatchList.map((e, i) => (
                  <div key={e.MatchID} className="scorecard mx-2">
                    <h6 className="date">{e.Date}</h6>
                    <h4 className="grid grid-flow-col-2 text-xl m-4"></h4>
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex">
                        <p>
                          {e.T1P1FirstName}{" "}
                          {e.GameType != "Single" ? "/ " + e.T1P2FirstName : ""}
                        </p>
                        <p className="ml-8 font-bold "> v/s </p>
                        <p className="ml-8">
                          {e.T2P1FirstName}{" "}
                          {e.GameType != "Single" ? "/ " + e.T2P2FirstName : ""}
                        </p>
                      </div>
                    </div>
                    <h4 className="grid grid-flow-col-2 text-xl m-4">
                      {e.MatchID}
                    </h4>
                    <p className="mt-4"> </p>
                    <button
                      className="px-7"
                      onClick={() => {
                        sessionStorage.setItem("MatchID", e.MatchID);
                        e.GameType === "Single"
                          ? (window.location.href = "/maininterfacesingle")
                          : (window.location.href = "/maininterface");
                      }}
                    >
                      Summary
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* end of tab 3 content--------------------------------------------------------------- */}
          </div>
        </div>
      </div>

      {/* end of code for tabs----------------------------------------------- */}

    </div>
  );
}

export default CreatedGames;
