import React from "react";
import Android_Display from "./Android_Display";
import { useState } from "react";
import TextCarousel from "./TextCarousel";
import FeaturesContent from "./FeaturesContent";

function FeaturesDisplay() {
  const [indexNum, setindexNum] = useState(1);


  const setindex = (e) => {
    setindexNum(e);
  };

  

  return (
    <div>
      <div className="bg-secondary_1 md:py-10 p-8">
        <div className="container mx-auto">
          <div className="max-w-2xl mx-auto text-center mb-2 md:mb-12">
            <h2 className="font-black leading-tight text-xl md:text-3xl mb-4">
              Key Features:
            </h2>
            {/* <p className="text-sm md:text-base font-condensed max-w-lg mx-auto">
              Etiam non quam lacus suspendisse faucibus interdum posuere lorem
              ipsum. Massa eget egestas purus viverra accumsan in.
            </p> */}
          </div>

          <div className="flex flex-wrap justify-center items-center">
            <div className="mb-12 lg:mb-0 w-full lg:flex-1 lg:flex hidden justify-center items-center  ">
              <ul className="py-6 mb-6">
                <li
                  className={indexNum===1?"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white bg-white p-2":"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white p-2"}
                  onMouseOver={() => setindexNum(1)}
                >
                  <div className="pr-5 text-right">
                    <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2">
                    {FeaturesContent[0].content}
                    </h4>
                    <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
                    {FeaturesContent[0].Description}
                    </p>
                  </div>
                  <h4 className="font-thin text-4xl md:text-5xl text-Tertiary leading-tight mb-2">
                    01
                  </h4>
                </li>
                <li
                  className={indexNum===2?"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white bg-white p-2":"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white p-2"}
                  onMouseOver={() => setindexNum(2)}
                >
                  <div className="pr-5 text-right">
                    <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2">
                    {FeaturesContent[1].content}
                    </h4>
                    <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
                    {FeaturesContent[1].Description}
                    </p>
                  </div>
                  <h4 className="font-thin text-4xl md:text-5xl text-Tertiary leading-tight mb-2">
                    02
                  </h4>
                </li>
                <li
                  className={indexNum===3?"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white bg-white p-2":"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white p-2"}
                  onMouseOver={() => setindexNum(3)}
                >
                  <div className="pr-5 text-right">
                    <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2">
                    {FeaturesContent[2].content}
                    </h4>
                    <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
                    {FeaturesContent[2].Description}
                    </p>
                  </div>
                  <h4 className="font-thin text-4xl md:text-5xl text-Tertiary leading-tight mb-2">
                    03
                  </h4>
                </li>
                <li
                  className={indexNum===4?"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white bg-white p-2":"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white p-2"}
                  onMouseOver={() => setindexNum(4)}
                >
                  <div className="pr-5 text-right">
                    <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2">
                    {FeaturesContent[3].content}
                    </h4>
                    <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
                    {FeaturesContent[3].Description}
                    </p>
                  </div>
                  <h4 className="font-thin text-4xl md:text-5xl text-Tertiary leading-tight mb-2">
                    04
                  </h4>
                </li>
                <li
                  className={indexNum===5?"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white bg-white p-2":"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white p-2"}
                  onMouseOver={() => setindexNum(5)}
                >
                  <div className="pr-5 text-right">
                    <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2">
                    {FeaturesContent[4].content}
                    </h4>
                    <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
                    {FeaturesContent[4].Description}
                    </p>
                  </div>
                  <h4 className="font-thin text-4xl md:text-5xl text-Tertiary leading-tight mb-2">
                    05
                  </h4>
                </li>
              </ul>
            </div>

            {/* <div className="w-full lg:w-5/12 px-12 flex justify-center items-center">
              <div className=" rounded-lg ">
                <img
                  src=".\Images\Home_display.png"
                  className="w-full h-auto rounded"
                />
              </div>
            </div> */}
            <div className="">
              <div className="">
                <Android_Display img_Index={indexNum} setindex_fn={setindex} />
              </div>
              <div className="lg:hidden">
                <TextCarousel ftr_index={indexNum} setindex_fn={setindex} />
              </div>
            </div>
            <div className="mb-12 lg:mb-0 w-full lg:flex-1 lg:flex hidden justify-center items-center">
              <ul className="py-6 mb-6">
                <li
                  className={indexNum===6?"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white bg-white p-2":"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white p-2"}
                  onMouseOver={() => setindexNum(6)}
                >
                  <h4 className="font-thin text-4xl md:text-5xl text-Tertiary leading-tight mb-2">
                    06
                  </h4>
                  <div className="pl-5">
                    <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2">
                    {FeaturesContent[5].content}
                    </h4>
                    <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
                    {FeaturesContent[5].Description}
                    </p>
                  </div>
                </li>
                <li
                  className={indexNum===7?"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white bg-white p-2":"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white p-2"}
                  onMouseOver={() => setindexNum(7)}
                >
                  <h4 className="font-thin text-4xl md:text-5xl text-Tertiary leading-tight mb-2">
                    07
                  </h4>
                  <div className="pl-5">
                    <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2">
                    {FeaturesContent[6].content}

                    </h4>
                    <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
                    {FeaturesContent[6].Description}
                    </p>
                  </div>
                </li>
                <li
                  className={indexNum===8?"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white bg-white p-2":"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white p-2"}
                  onMouseOver={() => setindexNum(8)}
                >
                  <h4 className="font-thin text-4xl md:text-5xl text-Tertiary leading-tight mb-2">
                    08
                  </h4>
                  <div className="pl-5">
                    <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2">
                    {FeaturesContent[7].content}
                    </h4>
                    <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
                    {FeaturesContent[7].Description}
                    </p>
                  </div>
                </li>
                <li
                  className={indexNum===9?"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white bg-white p-2":"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white p-2"}
                  onMouseOver={() => setindexNum(9)}
                >
                  <h4 className="font-thin text-4xl md:text-5xl text-Tertiary leading-tight mb-2">
                    09
                  </h4>
                  <div className="pl-5">
                    <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2">
                    {FeaturesContent[8].content}
                    </h4>
                    <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
                    {FeaturesContent[8].Description}
                    </p>
                  </div>
                </li>
                <li
                  className={indexNum===10?"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white bg-white p-2":"flex items-center mb-6 hover:shadow-lg transition-shadow hover:bg-white p-2"}
                  onMouseOver={() => setindexNum(10)}
                >
                  <h4 className="font-thin text-4xl md:text-5xl text-Tertiary leading-tight mb-2">
                    10
                  </h4>
                  <div className="pl-5">
                    <h4 className="font-black text-base md:text-lg text-Tertiary_1 leading-tight mb-2">
                    {FeaturesContent[9].content}
                    </h4>
                    <p className="text-xs md:text-sm text-Tertiary leading-snug max-w-sm">
                    {FeaturesContent[9].Description}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturesDisplay;
