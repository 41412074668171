import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function ForgotPassword() {
  const UserID = useRef();

  const [BtnText, setBtnText] = useState("Submit");
  const [Errormsg, setErrormsg] = useState(false);

  const SubmitHandler = (e) => {
    setErrormsg(false);
    setBtnText("Validating...");
    e.preventDefault();
    fetch(process.env.REACT_APP_API + "users/logincheck", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        UserID: UserID.current.value,
      }),
    })
      .then((res) => res.json())
      .then(async (Result) => {
        if (Result.length === 0) {
          //alert("Check your Credentials");
          setErrormsg(true);
          setBtnText("Submit");
        } else if (Result[0].UserID) {
          await new Promise((resolve) => {
            sessionStorage.setItem("UserID", Result[0].UserID);
            resolve();
          });
          console.log(Result[0].UserID);
          window.location.href = "/login";
        }
      });
    // .then(()=> window.location.href = "/login")
  };
  return (
    <div>
       <div className="bg-gray-100">
        <div className="min-h-screen w-full p-6 flex justify-center items-center">
          <div className="w-full max-w-xs">
            <div className="bg-white border p-8 shadow rounded w-full mb-6">
              <h1 className="mb-6 text-lg text-gray-900 font-thin">
                Forgot Password
              </h1>

              <form onSubmit={SubmitHandler}>
                <fieldset className="mb-4">
                  <label className="block text-sm text-gray-900 mb-2">
                    User ID or Email address
                  </label>
                  <input
                    id="email"
                    type="text"
                    className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                    name="email"
                    required
                    autofocus
                    ref={UserID}
                  />
                </fieldset>

                <div>
                  {Errormsg ? (
                    <h6 className="text-red-600 pb-2 -mt-2 text-sm">
                      <span>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>{" "}
                      Invalid User ID or Email address
                    </h6>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="pt-1 pb-5 text-sm text-gray-darker font-thin">
                <label>
                  <input
                    className="mr-1"
                    type="checkbox"
                    name="remember"
                    id="remember"
                  />{" "}
                  Remember me
                </label>
              </div> */}

                <button
                  type="submit"
                  className="block w-full bg-blue-600 text-white rounded-sm py-3 text-sm tracking-wide"
                >
                  {BtnText}
                </button>
              </form>
            </div>

            <p className="text-center text-sm text-gray-600 font-thin">
              Don't have an account yet?{" "}
              <a
                href="/signup"
                className="text-blue-500 no-underline hover:underline"
              >
                Sign up
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
