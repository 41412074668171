import React, { useState } from 'react'
import Logger from './ClassForTest'

function ClassImportTest() {
    
    const base7 = new Logger();
    const base8 = new Logger();
    const base9 = new Logger();

    const [nums, setNums] = useState({});
   const [gamesDetails, setGamesDetails] = useState({})

   const changeHandler=(e)=>{
 // setNums({...nums, [e.target.name] : e.target.value})
 // setGamesDetails({...gamesDetails, [e.target.name]: e.target.value });
  console.log(e.target.value);
}
    
    
  return (
    <div>
      <div>

      </div>
      <div>
        <input
        type="number"
        name='snum'
        onChange={changeHandler}
        />
      </div>
      <button onClick={()=> base7.mathOperationSum(nums.fnum,nums.snum)} className="px-10"> Doit </button>{" "}

      <br />
      <br />
      <br />
        <div>
        <button onClick={()=> base7.mathOperationSum(10,7)}>Click me 10 7 sum</button>{" "}
        <button onClick={()=> base8.mathOperationSum(10,8)}>Click me 10 8 sum</button>{" "}
        <button onClick={()=> base9.mathOperationSum(10,9)}>Click me 10 9 sum</button>{" "}
        </div>
        <br/>
        <div>
        <button onClick={()=> base7.mathOperationSubstraction(10, 7)}>Click me 10 7 Sub</button>{" "}
        <button onClick={()=> base8.mathOperationSubstraction(10, 8)}>Click me 10 8 Sub</button>{" "}
        <button onClick={()=> base9.mathOperationSubstraction(10, 9)}>Click me 10 9 Sub</button>{" "}
        </div>
        <br/>
        <div>
        <button onClick={()=> base7.mathOperationMultiplication(10, 7)}>Click me 10 7 Mul</button>{" "}
        <button onClick={()=> base8.mathOperationMultiplication(10, 8)}>Click me 10 8 Mul</button>{" "}
        <button onClick={()=> base9.mathOperationMultiplication(10, 9)}>Click me 10 9 Mul</button>{" "}
        </div>
        <br/>
        <div>
        <button onClick={()=> base7.mathOperationDivision(10, 7)}>Click me 10 7 Div</button>{" "}
        <button onClick={()=> base8.mathOperationDivision(10, 8)}>Click me 10 8 Div</button>{" "}
        <button onClick={()=> base9.mathOperationDivision(10, 9)}>Click me 10 9 Div</button>{" "}
        </div>

        <br/><br/><br/><br/><br/><br/><br/><br/>
        <button onClick={sessionStorage.setItem('test',123)}></button>
        
    </div>
  )
}

export default ClassImportTest