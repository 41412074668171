import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function ViewTournament() {
  const Emailref = useRef();
  const Refereeref = useRef();

  const [matchList, setmatchList] = useState([]);
  const [playedmatchList, setPlayedmatchList] = useState([]);
  //   const [showScore, setShowScore] = useState([]);
  const [matchDetails, setmatchDetails] = useState([]);
  const [TournamentDetails, setTournamentDetails] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [copyDetails, setCopyDetails] = useState({});

  const copySubmitHandler = (e) => {
    e.preventDefault();
  };

  const copyChangeHandler = (e) => {
    // setuserData(userInfo)
    setCopyDetails({ ...copyDetails, [e.target.name]: e.target.value });
    // userData.length>0 && alert(userData[0].FirstName);
    // alert("length"+userData.length);
  };
  const changeHandler = (e) => {
    // setuserData(userInfo)
    setmatchDetails({ ...matchDetails, [e.target.name]: e.target.value });
    // userData.length>0 && alert(userData[0].FirstName);
    // alert("length"+userData.length);
  };

  useEffect(() => console.log(copyDetails), [copyDetails]);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(matchDetails);
    fetch(process.env.REACT_APP_API + "match/schedulematch", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(matchDetails),
    })
      .then((res) => res.json())
      .then((result) => alert(result))
      .catch((err) => console.error(err))
      .then(setshowEditModal(false));

    // alert(result)
    // window.location.href="/login"
  };

  const copyHandler = (e) => {
    e.preventDefault();
    console.log(matchDetails);
    fetch(process.env.REACT_APP_API + "match/postmultiplematches", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(copyDetails) ,
      body: JSON.stringify({
        ...copyDetails,
        Master: sessionStorage.getItem("UserID"),
        CreatedBy: sessionStorage.getItem("UserID"),
        ModifiedBy: sessionStorage.getItem("UserID"),
        TournamentID: TournamentDetails.TournamentID,
        GameType: matchDetails.GameType,
      }),
    })
      .then((res) => res.json())
      .then((result) => alert(result))
      .catch((err) => console.error(err))
      .then(() => {
        setshowcopyModal(false);
        getMatches();
      });
  };

  // useEffect(() => {
  // if (sessionStorage.getItem("score")) {
  //   setShowScore(JSON.parse(sessionStorage.getItem("score")));
  // }

  //get game details
  // fetch(process.env.REACT_APP_API + "game/" + (sessionStorage.getItem("gameID"))) // change the value of userid
  //   .then((Response) => Response.json())
  //   .then((data) => {
  //     setmatchDetails(data[0]);
  //     // alert(data[0])
  //   });
  // }, []);

  const getMatches = () => {
    //get game list
    fetch(process.env.REACT_APP_API + "match/getmatchesoftournament", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        TournamentID: sessionStorage.getItem("TournamentID"), //change this to userid of the user
      }),
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0].TeamTag+ 'thats it')
        setmatchList(data);
      });
    alert("match list loaded");

    //get game list
    fetch(process.env.REACT_APP_API + "match/getplayedmatchesoftournament", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        TournamentID: sessionStorage.getItem("TournamentID"), //change this to userid of the user
      }),
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0].TeamTag+ 'thats it')
        setPlayedmatchList(data);
      });
    alert("played game list loaded");
  };

  useEffect(() => {
    //get Matches
    getMatches();

    //get tournament details
    fetch(process.env.REACT_APP_API + "tournament/gettournamentdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        TournamentID: sessionStorage.getItem("TournamentID"), //change this to userid of the user
      }),
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0].TeamTag+ 'thats it')
        setTournamentDetails(data[0]);
      });
    alert("Tournament details loaded");
  }, []);

  const getgameforedit = (e) => {
    fetch(process.env.REACT_APP_API + "match/" + e, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0]+ 'thats it')
        setmatchDetails(data[0]);
      })
      .then(setshowEditModal(true));

    console.log(matchDetails);
  };

  const [showcopyModal, setshowcopyModal] = useState(false);

  const getgameforcopy = (e) => {
    fetch(process.env.REACT_APP_API + "match/" + e, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0]+ 'thats it')
        setmatchDetails(data[0]);
      })
      .then(setshowcopyModal(true));

    console.log(matchDetails);
  };

  const [userList, setuserList] = useState([]);
  const getgameforreferee = (e) => {
    fetch(process.env.REACT_APP_API + "users/readuserslist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((Response) => Response.json())
      .then((data) => {
        // alert(data[0]+ 'thats it')
        setuserList(data);
      })
      .then(setshowrefereeModal(true));

    console.log(matchDetails);
  };

  const saveReferee = (e) => {
    //e.preventDefault();
    console.log("referee" + Refereeref.current.value);
    fetch(process.env.REACT_APP_API + "match/updatematchreferee", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Referee: Refereeref.current.value,
        MatchID: currentMatchId,
      }),
    })
      .then((res) => res.json())
      .then((result) => alert(result))
      .catch((err) => console.error(err))
      .then(setshowrefereeModal(false));
  };

  //    const [t1, sett1] = useState(0);
  //    const [t2, sett2]= useState(0);

  //   const [gameWinner, setgameWinner] = useState("abcd");

  //   useEffect(() => {
  //     sett1(showScore.filter((e) => e.TeamTag === "A").length);
  //     sett2(showScore.filter((e) => e.TeamTag === "B").length);

  //     if (matchDetails.Winner === null) {
  //       alert("winner in db doesnt exist");

  //       if (t1 > t2) {
  //         setgameWinner("Team A");
  //       } else {
  //         setgameWinner("Team B");
  //       }
  //     } else {
  //       alert("winner in db exists and value is " + matchDetails.Winner);
  //       //if the session storage have the value of winner, get it from sesion storage, else get it from the database. This is to rectify the error caused by the delay of posting and reading from DB
  //       if (sessionStorage.getItem("Winner").length>0){
  //         setgameWinner(sessionStorage.getItem("Winner"));
  //       }
  //       else{
  //         setgameWinner(matchDetails.Winner);
  //       };

  //     }

  //     console.log(matchDetails);
  //   },[showScore]);

  const [currentMatchId, setcurrentMatchId] = React.useState(0);
  const [showEditModal, setshowEditModal] = React.useState(false);
  const [showrefereeModal, setshowrefereeModal] = React.useState(false);
  const [refereeId, setRefereeId] = React.useState();

  return (
    <div className="bg-gray-50 min-h-screen">
      {/*datalist for dropdown list----------------------------------------------------------------- */}

      <datalist
        onSelect={(e) => console.log(e.target)}
        id="PlayerName"
        ref={Emailref}
      >
        {userList.map((e) => (
          <option
            className="text-3xl"
            key={e.Email}
            value={e.UserID}
            name={e.Email}
          >
            {e.FirstName} {e.LastName}, Email : {e.Email}
          </option>
        ))}
      </datalist>

      {/* end of datalist for dropdown list-------------------------------------------------------------------- */}

      {showEditModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Schedule Game</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setshowEditModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    {currentMatchId} is equel to
                    {matchDetails.MatchID}
                  </p>

                  <form onSubmit={submitHandler}>
                    <div className="input-container">
                      <label>Match ID</label>
                      <input
                        type="text"
                        placeholder="Enter Game ID"
                        onChange={changeHandler}
                        name="MatchID"
                        value={matchDetails.MatchID}
                        required
                        disabled
                      />
                    </div>
                    {/* <div className="input-container">
                        <label>Golden Point</label>
                        <input
                          type="text"
                          placeholder="Enter Golden Point"
                          onChange={changeHandler}
                          name="Goldenpoint"
                          value={matchDetails.Goldenpoint}
                          required
                        />
                      </div> */}
                    {/* <div className="input-container">
                        <label>Win Point</label>
                        <input
                          type="text"
                          placeholder="Enter Win Point"
                          onChange={changeHandler}
                          name="Winpoint"
                          value={matchDetails.Winpoint}
                          required
                        />
                      </div> */}

                    <div className="input-container">
                      <label>Date</label>
                      {matchDetails.Date}
                      <label>Change Date</label>
                      <input
                        type="datetime-local"
                        placeholder="Enter Date of Match"
                        onChange={changeHandler}
                        name="Date"
                        defaultvalue={matchDetails.Date}
                      />
                    </div>

                    <div className="button-container py-4">
                      <button
                        className="loginButton mr-3"
                        onClick={() => setshowEditModal(false)}
                      >
                        Close
                      </button>

                      <button
                        className="loginButton"
                        type="submit"
                        //onClick={() => setshowEditModal(false)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                {/*footer*/}
                {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setshowEditModal(false)}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setshowEditModal(false)}
                    >
                      Save Changes
                    </button>
                  </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showrefereeModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Set Referee</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setshowrefereeModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {/* <p className="my-4 text-slate-500 text-lg leading-relaxed">
                      {currentMatchId} is {matchDetails.GameID}
                    </p> */}

                  <h2 className="text-3xl p-5">{currentMatchId}</h2>
                  <label>Referee </label>

                  {/* <input
                className="p-4 rounded-lg m-3"
                type="text"
                placeholder="Enter Name"
                name="uname"
                required
                list="PlayerName"
                ref={Refereeref}
              /> */}
                  <select name="uname" ref={Refereeref}>
                    {userList.map((e, i) => (
                      <option className="text-lg" key={i} value={e.UserID}>
                        {e.FirstName} {e.LastName}, Email : {e.Email}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="button-container py-4">
                  <button
                    className="loginButton mr-3"
                    onClick={() => setshowrefereeModal(false)}
                  >
                    Close
                  </button>

                  <button
                    className="loginButton"
                    // onClick={() => setshowEditModal(false)}
                    onClick={() => saveReferee(currentMatchId)}
                  >
                    Submit
                  </button>
                </div>

                {/*footer*/}
                {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setshowrefereeModal(false)}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setshowrefereeModal(false)}
                    >
                      Save Changes
                    </button>
                  </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showcopyModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Copy Game</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setshowcopyModal(false)}
                  >
                    <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}

                {/* <div className="text-xl">
                          Select &nbsp; 
                            <input
                              type="checkbox"
                              id="topping"  // change name and id accordingly
                              name="topping"   // change name and id accordingly
                            //  value={e.GameID}
                            />
                            
                          </div> */}

                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-xl leading-relaxed">
                    {matchDetails.MatchID}
                  </p>

                  <form onSubmit={copyHandler}>
                    <div>
                      <div>Team A</div>
                      <label>{matchDetails.T1P1FirstName}</label>
                      <input
                        type="checkbox"
                        value={matchDetails.T1P1}
                        name="t1p1"
                        onChange={copyChangeHandler}
                      />{" "}
                      <label>{matchDetails.T1P2FirstName}</label>
                      <input
                        type="checkbox"
                        value={matchDetails.T1P2}
                        name="t1p2"
                        onChange={copyChangeHandler}
                      />
                      <div>Team B</div>
                      <label>{matchDetails.T2P1FirstName}</label>
                      <input
                        type="checkbox"
                        value={matchDetails.T2P1}
                        name="t2p1"
                        onChange={copyChangeHandler}
                      />{" "}
                      <label>{matchDetails.T2P2FirstName}</label>
                      <input
                        type="checkbox"
                        value={matchDetails.T2P2}
                        name="t2p2"
                        onChange={copyChangeHandler}
                      />
                    </div>
                    {/* <div className="input-container">
                        <label>Golden Point</label>
                        <input
                          type="checkbox"
                          placeholder="Enter Golden Point"
                          onChange={copyChangeHandler}
                          name="Goldenpoint"
                          value={matchDetails.Goldenpoint}
                          required
                        />
                         <label>{matchDetails.Goldenpoint}</label>
                      </div> */}
                    {/* <div className="input-container">
                        <label>Win Point</label>
                        <input
                          type="checkbox"
                          placeholder="Enter Win Point"
                          onChange={copyChangeHandler}
                          name="Winpoint"
                          value={matchDetails.Winpoint}
                          required
                        />
                             <label>{matchDetails.Winpoint}</label>
                      </div> */}

                    <div className="input-container">
                      <p>Date</p>
                      <input
                        type="checkbox"
                        placeholder="Enter Win Point"
                        onChange={copyChangeHandler}
                        name="date"
                        value={matchDetails.Date}
                        required
                      />
                      <label>{matchDetails.Date}</label>
                    </div>
                    <div className="input-container">
                      <input
                        type="number"
                        placeholder="No. of copies"
                        onChange={copyChangeHandler}
                        name="CopyCount"
                        min="1"
                        required
                      />
                    </div>

                    <div className="button-container py-4">
                      <button
                        className="loginButton mr-3"
                        onClick={() => setshowcopyModal(false)}
                      >
                        Close
                      </button>

                      <button
                        className="loginButton"
                        type="submit"
                        //onClick={() => setshowEditModal(false)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                {/*footer*/}
                {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setshowEditModal(false)}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setshowEditModal(false)}
                    >
                      Save Changes
                    </button>
                  </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* {sessionStorage.getItem("score") && showScore && (   // comment it */}
      <div>
        <div className="p-4">
          <div className="bg-white p-4 rounded-md">
          <div className=" text-right sm:mt-0 flex items-center">
            <button
              onClick={() => (window.location.href = "/dashboard/tournaments")}
              className="bg-primary active:bg-primary_1  text-white hover:shadow-md shadow border-0 text-xs px-4 py-2 rounded outline-none focus:outline-none "
              type="button"
            >
             <FontAwesomeIcon icon={faArrowLeft} />{" Back to Tournaments"}
            </button>
          </div>
            <div>
              <h2 className="mb-4 text-xl font-bold text-gray-700">
                {TournamentDetails.TournamentName}
              </h2>
              <h2 className="mb-4 text-lg font-bold text-gray-700">
                Tournament ID : {TournamentDetails.TournamentID}
              </h2>
              <h2 className="mb-4 text-base text-gray-700">
                Description : {TournamentDetails.Description}
              </h2>
              <div>
                <h2 className="text-xl font-normal text-gray-700"></h2>

                <div className="homeLayout">
                  <h2 className="heading pt-3">Yet to be played</h2>
                  <div className="grid grid-cols-3">
                    {matchList.map((e, i) => (
                      <div key={e.MatchID} className="scorecard mx-2">
                        <h6 className="date">{e.Date}</h6>
                        <h4 className="grid grid-flow-col-2 text-xl m-4"></h4>
                        <div className="flex flex-col justify-center items-center">
                          <div className="flex   ">
                            <p>
                              {e.T1P1FirstName}{" "}
                              {e.GameType != "Single"
                                ? "/ " + e.T1P2FirstName
                                : ""}
                            </p>
                            <p className="ml-8 font-bold "> v/s </p>
                            <p className="ml-8">
                              {e.T2P1FirstName}{" "}
                              {e.GameType != "Single"
                                ? "/ " + e.T2P2FirstName
                                : ""}
                            </p>
                          </div>
                        </div>
                        <h4 className="grid grid-flow-col-2 text-xl m-4">
                          {e.MatchID}
                        </h4>
                        <p className="mt-4"> </p>
                        <button
                          className="px-7 mx-2"
                          onClick={() => {
                            sessionStorage.setItem("MatchID", e.MatchID);
                            e.GameType == "Single"
                              ? (window.location.href = "/updateplayerssingle")
                              : (window.location.href = "/updateplayers");
                          }}
                        >
                          Start
                        </button>

                        <button
                          className="px-7 mx-2"
                          onClick={() => {
                            // sessionStorage.setItem("gameID",e.GameID);
                            // window.location.href="/assignreferee";

                            setcurrentMatchId(e.MatchID);
                            getgameforreferee(e.MatchID);
                            // setshowrefereeModal(true);
                          }}
                        >
                          Assign Referee
                        </button>

                        <button
                          className="px-7 mx-2"
                          onClick={() => {
                            // sessionStorage.setItem("gameID",e.GameID);
                            // window.location.href="/assignreferee";

                            setcurrentMatchId(e.MatchID);
                            getgameforedit(e.MatchID);
                            // setshowEditModal(true);
                          }}
                        >
                          Schedule
                        </button>

                        <button
                          className="px-7 mx-2"
                          onClick={() => {
                            // sessionStorage.setItem("gameID",e.GameID);
                            // window.location.href="/assignreferee";

                            setcurrentMatchId(e.MatchID);
                            getgameforcopy(e.MatchID);
                            // setshowEditModal(true);
                          }}
                        >
                          Copy
                        </button>

                        {/* <button
                            className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => setshowEditModal(true)}
                          >
                            Open regular modal
                          </button> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="bg-white p-4 rounded-md">
            <div>
              <h2 className="mb-4 text-xl font-bold text-gray-700"></h2>
              <div>
                <h2 className="text-xl font-normal text-gray-700"></h2>

                <div className="homeLayout">
                  <h2 className="heading pt-3">Played Games</h2>
                  <div className="grid grid-cols-3">
                    {playedmatchList.map((e, i) => (
                      <div key={e.MatchID} className="scorecard mx-2">
                        <h6 className="date">{e.Date}</h6>
                        <h4 className="grid grid-flow-col-2 text-xl m-4"></h4>
                        <div className="flex flex-col justify-center items-center">
                          <div className="flex   ">
                            <p>
                              {e.T1P1FirstName}{" "}
                              {e.GameType != "Single"
                                ? "/ " + e.T1P2FirstName
                                : ""}
                            </p>
                            <p className="ml-8 font-bold "> v/s </p>
                            <p className="ml-8">
                              {e.T2P1FirstName}{" "}
                              {e.GameType != "Single"
                                ? "/ " + e.T2P2FirstName
                                : ""}
                            </p>
                          </div>
                        </div>
                        <h4 className="grid grid-flow-col-2 text-xl m-4">
                          {e.MatchID}
                        </h4>
                        <p className="mt-4"> </p>
                        <button
                          className="px-7"
                          onClick={() => {
                            sessionStorage.setItem("MatchID", e.MatchID);
                            e.GameType == "Single"
                              ? (window.location.href = "/maininterfacesingle")
                              : (window.location.href = "/maininterface");
                          }}
                        >
                          Summary
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*   )} */}
    </div>
  );
}

export default ViewTournament;
