import { useRef } from "react";
import React from "react";

function Accout() {
  const UserID = useRef();
  const Password = useRef();

  const CurrentPassword = useRef();
  const NewPassword = useRef();
  const ConfirmPassword = useRef();

  const SubmitHandler = (e) => {
    console.log("submit handler works");
  };

  return (
    <div>
      <h2 className="my-6 text-2xl text-left font-semibold text-gray-700 dark:text-gray-200">
        Account
      </h2>
      {/* <div className="bg-gray-100">
      <div className=" p-6 flex ">
        <div className="w-full max-w-xs">
          <div className="bg-white border p-8 shadow rounded w-full mb-6">
            <h1 className="mb-6 text-lg text-gray-900 font-thin">
              Change Password
            </h1>

            <form onSubmit={SubmitHandler}>

              <fieldset className="mb-4">
                <div className="w-full flex justify-between items-center">
                  <label
                    for="password"
                    className="block text-sm text-gray-900 mb-2"
                  >
                    Password
                  </label>
          
                </div>
                <input
                  id="password"
                  type="password"
                  className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                  name="password"
                  required
                  ref={Password}
                />
              </fieldset>



              <button
                type="submit"
                className="block w-full bg-blue-600 text-white rounded-sm py-3 text-sm tracking-wide"
              >
                Sign in
              </button>
            </form>
          </div>


        </div>
      </div>
    </div> */}

      <div>
        <div className="">
          <main>
            <h1 className=""></h1>
            <div className="w-full px-4 py-6 space-y-6 bg-white rounded-md dark:bg-gray_dark_2">
              <p className="text-sm font-medium  text-gray-500 dark:text-gray-400">
                Reset password
              </p>
              <form action="#" className="space-y-6 max-w-sm">
                <input
                  className="w-full px-4 py-2 border rounded-md dark:text-slate-300 dark:bg-slate-900 dark:bg-darker dark:border-gray-700 focus:outline-none  dark:focus:ring-primary-darker"
                  type="password"
                  name="Current_Password"
                  placeholder="Current Password"
                  required
                />
                <input
                  className="w-full px-4 py-2 border rounded-md dark:text-slate-300 dark:bg-slate-900 dark:bg-darker dark:border-gray-700 focus:outline-none  dark:focus:ring-primary-darker"
                  type="text"
                  name="New_Password"
                  placeholder="New Password"
                  required
                />
                <input
                  className="w-full px-4 py-2 border rounded-md dark:text-slate-300 dark:bg-slate-900 dark:bg-darker dark:border-gray-700 focus:outline-none  dark:focus:ring-primary-darker"
                  type="password"
                  name="password_confirmation"
                  placeholder="Confirm Password"
                  required
                />
                <div className="flex">
                  <div
                    onClick={SubmitHandler}
                    className={`${"bg-primary text-secondary py-2 px-4 text-xs md:text-sm rounded-full hover:shadow-lg cursor-pointer"}`}
                  >
                    Reset Password
                  </div>
                </div>
                <div>
                <h3 className="text-lg dark:text-gray-300">Password requirements</h3>
                <h4 className="text-sm dark:text-gray-400">Please follow this guide for a strong password:</h4>
                <ul className="text-sm mt-4 dark:text-gray-400">
                  <li> <span>Min 8 characters</span></li>
                  <li> <span>Max 20 characters</span></li>
                  <li> <span>MIn 1 number</span></li>
                  <li> <span>Min 1 uppercase letter</span></li>
                  <li> <span>Min 1 lowercase letter</span></li>
                  <li> <span>Change it often</span></li>
                </ul>
                </div>

              </form>
            </div>
          </main>
        </div>

      </div>
    </div>
  );
}

export default Accout;
