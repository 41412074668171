import React from "react";
import DatePicker from "react-datepicker";
import { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";

function ScheduleSingle() {
  const [startDate, setStartDate] = useState(new Date());

  //   const changeHandler = (e) => {
  //     console.log(e.target.value)
  //     setStartDate(e.target.value)
  //     // setuserData(userInfo)
  //    // setgameDetails({...gameDetails, [e.target.name]: e.target.value });
  //    // userData.length>0 && alert(userData[0].FirstName);
  //    // alert("length"+userData.length);
  //  };

  const SaveGameDetails = (Sdate) => {
    fetch(process.env.REACT_APP_API + "game", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        TournamentID: "",
        GroupID: "",
        Round: "",
        SetNo: "",
        TossWinner: "", //(sessionStorage.getItem("tosswinner")),
        FirstServer: "", // (sessionStorage.getItem("server")),
        FirstReceiver: "", // (sessionStorage.getItem("receiver")),
        Date: Sdate,
        T1P1: sessionStorage.getItem("team1p1"),
        T1P2: "",
        T2P1: sessionStorage.getItem("team2p1"),
        T2P2: "",
        Shuttles: "",
        Winpoint: sessionStorage.getItem("WinPoint"),
        Goldenpoint: sessionStorage.getItem("GoldenPoint"),
        Master: sessionStorage.getItem("UserID"),
        NoOfSets: sessionStorage.getItem("NoOfSets"),
        GameType: sessionStorage.getItem("GameType"),
        CreatedBy: sessionStorage.getItem("UserID"),
      }),
    })
      .then((res) => res.json())
      .then(
        (Result) => {
          sessionStorage.setItem("MatchID", Result[0].Column1);
          //  sessionStorage.setItem("gameID",Result[0].Column1);
          window.location.href = "/dashboard/matches";
        },
        (error) => {
          alert("Failed to create match, please try again.");
        }
      );
  };

  // const schedule = () => {
  // //   alert(startDate);
  // //   alert(sessionStorage.getItem("gameID"));
  //   fetch(process.env.REACT_APP_API + "match/schedulematch", {
  //     method: "PUT",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       MatchID: sessionStorage.getItem("MatchID"),
  //       Date: startDate
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then(
  //       (Result) => {
  //         alert(Result);
  //       },
  //       (error) => {
  //         alert("Failed");
  //       }
  //     )
  //     .then(()=>{window.location.href = "/createdgames";});
  // };

  //   const skipschedule = (date) => {
  //     // setStartDate(date)
  //     fetch(process.env.REACT_APP_API + "game/schedulegame", {
  //       method: "PUT",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         GameID: sessionStorage.getItem("gameID"),
  //         Date: null,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then(
  //         (Result) => {
  //           alert(Result);
  //         },
  //         (error) => {
  //           alert("Failed");
  //         }
  //       );
  //   };

  return (
    <>
      {/* <div>
        <div className="">
          <div className="text-2xl text-center">Schedule the game</div>
          <div className="grid grid-cols-1 m-5">
            <div className=" justify-center items-center bg-slate-200 m-2 rounded-3xl ">
              <h3 className="text-2xl text-center">Select Date</h3>

              <div className="text-center mt-5">
                

                <div className="input-container">
                  <label>Select Date</label>
                  <input
                    type="datetime-local"
                    placeholder="Enter Date of Match"
                   // onChange={changeHandler}
                    name="Date"
                    defaultValue={startDate}
                  />
                </div>

                <div
                  className="text-3xl  flex justify-center items-center card2 bg-blue-700 hover:bg-blue-600 text-white m-8 rounded-3xl cursor-pointer"
                  onClick={() => {
                    SaveGameDetails();
                    // window.location.href = "/userdash";
                  }}
                >
                  <h2>Schedule</h2>
                </div>

                <div
                  className="text-3xl  flex justify-center items-center card2 bg-blue-700 hover:bg-blue-600 text-white m-8 rounded-3xl cursor-pointer"
                  onClick={() => {
                    //  skipschedule();
                    window.location.href = "/dashboard/matches";
                  }}
                >
                  <h2>Skip</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="button-container text-center "></div>
        </div>
      </div>




 




*/}

      <div className="bg-gray-100">
        <div className="min-h-screen w-full p-6 flex justify-center items-center">
          <div className="w-full max-w-md">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                SaveGameDetails(startDate);
              }}
            >
              <div className="bg-white border p-8 shadow rounded w-full mb-6">
                <h1 className="mb-6 text-lg text-gray-900 font-thin text-center">
                  Schedule the game
                </h1>

                <div>
                  <fieldset className="mb-4">
                    <div className="w-full flex justify-between items-center">
                      <label
                        htmlFor="Golden Point"
                        className="block text-sm text-gray-900 mb-2"
                      >
                        Select Date
                      </label>
                    </div>
                    {/* <input
                          id="Golden Point"
                          className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                          type="datetime-local"
                          placeholder="Enter Date of Match"
                          name="Date"
                          defaultValue={startDate}
                          required
                          onChange={changeHandler}
                        /> */}

                    <DatePicker
                      className="block w-full rounded-sm border bg-white py-2 px-3 text-sm"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="dd/MM/yyyy hh:mm aa"
                      minDate={new Date()}
                      showTimeInput
                      timeInputLabel="Time:"
                      // showIcon
                    />
                  </fieldset>
                </div>

                <div className="flex flex-wrap items-center text-center gap-x-2">
                  <button
                    type="submit"
                    className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                  >
                    Schedule
                  </button>
                  <button
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      SaveGameDetails("");
                    }}
                    className="inline-block bg-primary text-secondary py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                  >
                    Skip
                  </button>
                  <button
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      window.location.href = "/custompointssingle";
                    }}
                    className="inline-block bg-secondary text-primary border-2 py-2 px-5 my-2 text-base rounded-full hover:shadow-lg"
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScheduleSingle;
