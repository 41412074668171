import React, { useEffect, useState } from 'react'

function MatchesPlayed() {


    const [PlayedgameCount,setPlayedgameCount]=useState(0);

      //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
   const [isloadedcount, setisloadedcount] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedcount) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedcount]); //add all the induvidual loading variables here

    useEffect(()=>{

      try {

        fetch(process.env.REACT_APP_API + "game/getnumberofmatches", {
          method: "POST",
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body:JSON.stringify({
            
            "UID": (sessionStorage.getItem("UserID"))  //change this to userid of the user
          })
        })
          .then((Response) => Response.json())
          .then((data) => {
           // alert(data[0].TeamTag+ 'thats it')
           setPlayedgameCount(data[0].Column1);
           setisloadedcount(true);
        });
        
      } catch (error) {
        setIsError(true);
      }



    },[]);

  //loading message
  if (!isloaded)
    return (
          <div className="spinner-border animate-spin inline-block w-5 h-5 border-t-2 rounded-t-full"></div>
    );
  //error message
  if (IsError)
    return (
          <div className="">
            <h2>Error...</h2>
          </div>
    );
  //default component
  if (isloaded && !IsError) 
    return(
    <div>
        <p className="">{PlayedgameCount}</p>
    </div>
  )
}

export default MatchesPlayed