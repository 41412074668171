import React from "react";
import { useState, useEffect } from "react";

function ShotsPercentage() {
  const [ClearLobCount, setClearLobCount] = useState(0);
  const [DropShotCount, setDropShotCount] = useState(0);
  const [SmashCount, setSmashCount] = useState(0);
  const [ServeCount, setServeCount] = useState(0);
  const [NetShotCount, setNetShotCount] = useState(0);
  const [NetKillCount, setNetKillCount] = useState(0);
  const [NetLiftCount, setNetLiftCount] = useState(0);
  const [DriveCount, setDriveCount] = useState(0);

  //const for checking the whole content loaded
  const [isloaded, setisloaded] = useState(false);

  //const for checking individual contents loaded
  const [isloadedcounts, setisloadedcounts] = useState(false);

  //const for checking any error caught
  const [IsError, setIsError] = useState(false);

  //after successfully loading each content setting isloaded true
  useEffect(() => {
    if (isloadedcounts) {
      //add all the induvidual loading variables here
      setisloaded(true);
    }
  }, [isloadedcounts]); //add all the induvidual loading variables here

  // let totalshots=0;
  let totalshots =
    ClearLobCount +
    DropShotCount +
    SmashCount +
    ServeCount +
    NetShotCount +
    NetKillCount +
    NetLiftCount +
    DriveCount;

  useEffect(() => {
    try {
      fetch(process.env.REACT_APP_API + "scoreboard/getshotcount", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UID: sessionStorage.getItem("UserID"), //change this to userid of the user
        }),
      })
        .then((Response) => Response.json())
        .then((data) => {
          // alert(data[0].TeamTag+ 'thats it')
          setClearLobCount(data[0].ClearLobCount);
          setDropShotCount(data[0].DropShotCount);
          setSmashCount(data[0].SmashCount);
          setServeCount(data[0].ServeCount);
          setNetShotCount(data[0].NetShotCount);
          setNetKillCount(data[0].NetKillCount);
          setNetLiftCount(data[0].NetLiftCount);
          setDriveCount(data[0].DriveCount);

          setisloadedcounts(true);
          //console.log(data);
        })
        .then
        // totalshots= (ClearLobCount+DropShotCount+SmashCount+ServeCount+NetShotCount+NetKillCount+NetLiftCount+DriveCount)
        ();
    } catch (error) {
      setIsError(true);
    }
  }, []);

  const labels = [
    {
      text: "Clear / Lob",
      count: ClearLobCount,
    },
    {
      text: "Drop Shot",
      count: DropShotCount,
    },
    {
      text: "Smash",
      count: SmashCount,
    },
    {
      text: "Serve",
      count: ServeCount,
    },
    {
      text: "Net Shot",
      count: NetShotCount,
    },
    {
      text: "Net Kill ",
      count: NetKillCount,
    },
    {
      text: "Net Lift",
      count: NetLiftCount,
    },
    {
      text: "Drive",
      count: DriveCount,
    },
  ];

  //loading message
  if (!isloaded)
    return (
      <div className="flex justify-center items-center">
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-20 h-20 border-t-2 rounded-t-full"></div>
        </div>
      </div>
    );
  //error message
  if (IsError)
    return (
      <div className="">
        <h2>Error...</h2>
      </div>
    );
  //default component
  if (isloaded && !IsError)
    return (
      <div>
        <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
          {labels.map((e) => (
            <>
              <div
                key={e.text}
                onClick={() => {
                  //
                }}
                class="flex w-full h-24 overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800"
              >
                <div class="flex items-center justify-center w-24 bg-primary">
                  <h5 className="text-secondary font-semibold">
                    {" "}
                    {Number((e.count / totalshots) * 100).toFixed(2)} %
                  </h5>
                  {/* <svg
                class="w-6 h-6 text-white fill-current"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
              </svg> */}
                </div>

                <div class="px-4 py-2 -mx-3 flex items-center justify-center">
                  <div class="mx-3 ">
                    <h5 class="font-semibold text-gray-600 dark:text-gray-400">
                      {e.text}
                    </h5>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    );
}

export default ShotsPercentage;
